import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import InputField from "@components/FormInput/InputField";
import { Controller } from "react-hook-form";
import { TwoColumnGrid } from "@components/Accesbility";
import InputFieldWithSelect from "@advertiser/components/FormInput/InputFieldWithSelect";

function NewDeliverablesModal({
  type,
  isModalOpen,
  handleClose,
  handleAdd,
  idx,
  trigger,
  control,
  errors,
}) {
  const [minKM, setMinKM] = useState("K");
  const [maxKM, setMaxKM] = useState("K");

  const [fieldName, setFieldName] = useState(
    type === "instagram" ? "min" : "min"
  );

  const [rangeError, setRangeError] = useState({});

  const [contentRequirementError, setContentRequirementError] = useState(false);
  const [compensationRequirementError, setCompensationRequirementError] =
    useState(false);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const parseNumericString = (s) => {
    s = s.trim().toLowerCase();
    if (s.endsWith("k")) {
      return parseInt(parseFloat(s) * 1000);
    } else if (s.endsWith("m")) {
      return parseInt(parseFloat(s) * 1000000);
    } else {
      return parseInt(s);
    }
  };

  const validateRange = (minVal, maxVal, ranges) => {
    if (isNaN(minVal)) {
      setRangeError({
        ...rangeError,
        min: { message: "Please Enter a Valid Number" },
      });
      return false;
    }

    if (isNaN(maxVal)) {
      setRangeError({
        ...rangeError,
        max: { message: "Please Enter a Valid Number" },
      });
      return false;
    }

    if (minVal >= maxVal) {
      setRangeError({
        ...rangeError,
        all: {
          message: "Minimum Followers should be less than Maximum Followers",
        },
      });
      return false;
    }

    for (const { min, max } of ranges) {
      if (min <= maxVal && max >= minVal - 1) {
        setRangeError({
          ...rangeError,
          all: { message: "This range is overlapping with a previous range" },
        });
        return false; // Overlap detected, return false
      }
    }

    return true; // No overlap and all ranges are valid, return true
  };

  const handleAddDeliverable = async () => {
    const result = await trigger(
      `${fieldName}.${idx}.min`,
      `${fieldName}.${idx}.max`
    );

    if (!result) return;

    const formValues = control._formValues[fieldName][idx];

    const story_num = formValues["story_num"];
    const story_link_num = formValues["story_link_num"];
    const image_num = formValues["image_num"];
    const video_num = formValues["video_num"];

    const colab_price = formValues["colab_price"];
    const prod_value = formValues["prod_value"];
    const disc = formValues["disc"];

    if (type === "instagram") {
      const contentRequirementError =
        !story_num && !story_link_num && !image_num && !video_num;

      setContentRequirementError(contentRequirementError);
    }

    const compensationRequirementError = !colab_price && !prod_value && !disc;

    setCompensationRequirementError(compensationRequirementError);

    if (contentRequirementError || compensationRequirementError) {
      return;
    }

    const minValue = parseNumericString(formValues["min"] + minKM);
    const maxValue = parseNumericString(formValues["max"] + maxKM);

    setRangeError({ min: null, max: null, all: null });

    const canAdd = validateRange(
      minValue,
      maxValue,
      control._formValues[fieldName]
    );

    if (!canAdd) {
      return;
    }

    control._formValues[fieldName][idx]["min"] = minValue;
    control._formValues[fieldName][idx]["max"] = maxValue;

    handleAdd();
  };

  useEffect(() => {
    setContentRequirementError(false);
    setCompensationRequirementError(false);
    setRangeError({ min: null, max: null, all: null });

    setFieldName(
      type === "instagram"
        ? "instaDeliverableFields"
        : "tiktokDeliverableFields"
    );
  }, [type]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Deliverables Modal"
    >
      <div className="max-h-[80vh]">
        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
          <h3 className="text-3xl font-semibold">Add Deliverables</h3>
          <button
            type="button"
            onClick={handleClose}
            className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-12 h-12 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="p-4">
          <p className="block my-8 text-2xl font-large font-bold text-gray-900">
            Followers
          </p>

          <TwoColumnGrid className="mt-4">
            <div className="flex">
              <Controller
                control={control}
                name={`${fieldName}.${idx}.min`}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter minimum followers for this range",
                  },
                  min: {
                    value: 0,
                    message: "Minimum followers Should be greater than 0",
                  },
                  max: {
                    value: 999,
                    message: "Minimum followers Should be less than 999",
                  },
                }}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  return (
                    <InputFieldWithSelect
                      label="Minimum"
                      type="number"
                      error={
                        errors["deliverables"]?.length
                          ? errors["deliverables"][idx]["min"]
                          : rangeError.min
                          ? rangeError.min
                          : null
                      }
                      onChange={(value) => {
                        onChange(value);
                        setRangeError({ ...rangeError, min: null });
                      }}
                      onSelectChange={(value) => {
                        setMinKM(value);
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name={`${fieldName}.${idx}.max`}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter maximum followers for this range",
                  },
                  min: {
                    value: 0,
                    message: "Maximum followers Should be greater than 0",
                  },
                  max: {
                    value: 999,
                    message: "Maximum followers Should be less than 999",
                  },
                }}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  return (
                    <InputFieldWithSelect
                      label="Maximum"
                      type="number"
                      error={
                        errors["deliverables"]
                          ? errors["deliverables"][idx]["max"]
                          : rangeError.max
                          ? rangeError.max
                          : null
                      }
                      onChange={(value) => {
                        onChange(value);
                        setRangeError({ ...rangeError, max: null });
                      }}
                      onSelectChange={(value) => {
                        setMaxKM(value);
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          </TwoColumnGrid>

          {rangeError.all && (
            <p className="block mt-2 text-lg text-center font-medium text-red-500">
              {rangeError.all.message}
            </p>
          )}

          <p className="block my-8 text-2xl font-large font-bold text-gray-900">
            Content Requirements
          </p>

          {type === "instagram" && (
            <>
              <TwoColumnGrid>
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.story_num`}
                  render={({ field }) => (
                    <InputField
                      label="# of Stories"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.story_link_num`}
                  render={({ field }) => (
                    <InputField
                      label="# of Stories with link"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
              </TwoColumnGrid>

              <TwoColumnGrid>
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.image_num`}
                  render={({ field }) => (
                    <InputField
                      label="# of Image Posts"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.video_num`}
                  render={({ field }) => (
                    <InputField
                      label="# of Video Posts"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
              </TwoColumnGrid>

              <TwoColumnGrid>
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.bio_link_num`}
                  render={({ field }) => (
                    <InputField
                      label="Link in Bio (0-30 days)"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
                <></>
              </TwoColumnGrid>
            </>
          )}

          {type === "tiktok" && (
            <>
              <TwoColumnGrid>
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.tiktok_story_num`}
                  render={({ field }) => (
                    <InputField
                      label="# of TikTok Videos"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.tiktok_video_num`}
                  render={({ field }) => (
                    <InputField
                      label="# of Tiktok Stories"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
              </TwoColumnGrid>

              <TwoColumnGrid>
                <Controller
                  control={control}
                  name={`${fieldName}.${idx}.bio_link_num`}
                  render={({ field }) => (
                    <InputField
                      label="Link in Bio (0-30 days)"
                      type="number"
                      error={errors[field.name]}
                      {...field}
                    />
                  )}
                />
                <></>
              </TwoColumnGrid>
            </>
          )}

          {contentRequirementError && (
            <p className="block mb-2 text-lg font-medium text-red-500">
              Please add at least one content requirement
            </p>
          )}

          <p className="block my-8 text-2xl font-large font-bold text-gray-900">
            Compensation
          </p>

          <TwoColumnGrid>
            <Controller
              control={control}
              name={`${fieldName}.${idx}.colab_price`}
              render={({ field }) => (
                <InputField
                  label="Collaboration Price"
                  type="number"
                  error={errors[field.name]}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name={`${fieldName}.${idx}.prod_value`}
              render={({ field }) => (
                <InputField
                  label="Product value"
                  type="number"
                  error={errors[field.name]}
                  {...field}
                />
              )}
            />
          </TwoColumnGrid>

          <TwoColumnGrid>
            <Controller
              control={control}
              name={`${fieldName}.${idx}.disc`}
              render={({ field }) => (
                <InputField
                  label="Discount"
                  type="number"
                  error={errors[field.name]}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name={`${fieldName}.${idx}.prod_link`}
              render={({ field }) => (
                <InputField
                  label="Product Link"
                  error={errors[field.name]}
                  {...field}
                />
              )}
            />
          </TwoColumnGrid>

          {compensationRequirementError && (
            <p className="block mb-2 text-lg font-medium text-red-500">
              Please add at least one compensation
            </p>
          )}

          <div className="w-full my-12">
            <button
              onClick={handleAddDeliverable}
              class="w-full h-12 px-6 text-indigo-100 transition-colors duration-150 bg-purple-800 rounded-lg focus:shadow-outline hover:bg-purple-900"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewDeliverablesModal;
