import { Children } from "react";

export const TabItems = ({ active, children }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <div>
      {arrayChildren.map((child, index) => {
        return (
          <div
            className={`p-4 rounded-lg  ${active === index ? "" : "hidden"}`}
            id="instagram"
            role="tabpanel"
            aria-labelledby="instagram-tab"
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

export const TabItem = ({ children }) => {
  return <div>{children}</div>;
};
