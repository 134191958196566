import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import forgotPassword from "@api/auth/forgot-password";

export default function useForgotPassword(
  options
){
  return useMutation({
    mutationFn: forgotPassword,
    mutationKey: REACT_QUERY_KEYS.FORGOT_PASS,
    ...options
  }
  );
};