import { ADV_ROUTES } from "@constants/routes";
import useAppSettings from "@hooks/useAppSetting";
import useNavigator from "@hooks/useNavigator";
import httpErrorHandler from "@middleware/http-error-handler";
import useCurrentAdvertiser from "@requests/advertisers/useCurrentAdvertiser";
import { setAdvertiser } from "@store/slices/advertiserSlice";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { H1 } from "ui-neumorphism";
import APPS from "@constants/app-names";

export default function MainValidatePage({}) {
  const [navigator] = useNavigator();
  const dispatch = useDispatch();
  const [dark] = useAppSettings();
  const { isLoading, error } = useCurrentAdvertiser({
    onSuccess({ currentAdvertiser }) {
      if (currentAdvertiser) {
        dispatch(setAdvertiser(currentAdvertiser));
        navigator(ADV_ROUTES.DASHBOARD.path, APPS["advertiser"]);
      } else {
        navigator(ADV_ROUTES.SIGN_UP.path, APPS["advertiser"]);
      }
    },
    onError(err) {
      httpErrorHandler(err, dispatch, navigator);
    },
  });

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {error ? (
        <H1 dark={dark}>Something went wrong .. Please try again later!</H1>
      ) : (
        <ClipLoader color="#004EAD" size={150} />
      )}
    </div>
  );
}
