const PROD_APPS = {
  advertiser: "pub",
  instagram: "ig",
  main: "",
  admin: "admin",
  linkedin: "li",
  tiktok: "tk",
};

const STAGING_APPS = {
  advertiser: "pub-beta",
  instagram: "ig-beta",
  main: "beta",
  admin: "admin-beta",
  linkedin: "li-beta",
  tiktok: "tk-beta",
};

export const DEFAULT_DOMAIN = "influcracy.local";

const isLocalOrProduction = process.env.REACT_APP_PLATFORM !== "staging";

const APPS = isLocalOrProduction ? PROD_APPS : STAGING_APPS;

export const CURRENT_PROTOCOL =
  (process.env.REACT_APP_PLATFORM ?? "local") === "local" ? "http" : "https";

export default APPS;
