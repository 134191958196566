import React from "react";

const HelperCard = ({ children }) => {
  return (
    <div className="flex">
      <div className="mt-2 sm:mt-4 mx-auto w-full sm:max-w-3xl p-6 bg-white sm:rounded-2xl sm:shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        {children}
      </div>
    </div>
  );
};

export default HelperCard;
