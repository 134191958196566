import React, { useEffect, useState } from "react";
import "./styles.css";
import { Alert } from "ui-neumorphism";

export default function Toast({ type, description, id, onClose = (id) => {} }) {
  const [isVisible, setIsVisible] = useState(true);
  const [timeoutRef, setTimeoutRef] = useState(null);
  const handleCloseClick = () => {
    clearTimeout(timeoutRef);
    setIsVisible(false);
    setTimeout(() => {
      onClose(id);
    }, 600);
  };
  useEffect(() => {
    setTimeoutRef(
      setTimeout(
        () => handleCloseClick(),
        process.env.REACT_APP_TOAST_TIMEOUT || 5000
      )
    );
    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  return (
    <div className={`mx-auto my-2 ${isVisible ? "slide-down" : "fade-out"}`}>
      <Alert
        key={id}
        type={type === "toastError" ? "error" : type}
        closable
        onClose={() => {
          handleCloseClick(id);
        }}
        icon={
          <img
            src={require(`@assets/${type}.png`)}
            className="h-7 w-7 object-contain"
          />
        }
      >
        {description}
      </Alert>
    </div>
  );
}
