import {useQuery } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import getAllCampaigns from "@api/campaigns/get-allcampaigns";

export default function useGetAllCampaigns(
  options
){
  return useQuery(
    {
      queryFn: getAllCampaigns,
      queryKey: REACT_QUERY_KEYS.AD_CAMPAIGNS,
      ...options,
    }
  );
};