import { useMutation, useQuery } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import checkCurrentAdvertiser from "@api/advertisers/current-advertiser";

export default function useCurrentAdvertiser(
  options
){
  return useQuery(
    {
      queryFn: checkCurrentAdvertiser,
      queryKey: REACT_QUERY_KEYS.CHECK_ADV,
      ...options,
    }
  );
};