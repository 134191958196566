import LOCAL_STORAGE_KEYS from "@constants/local-storage-keys";
import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
  name: "",
  scopes: [],
  uid: {
    email: "",
    last_login: "",
    uid: "",
  },
  adid: "",
};
export const adminSlice = createSlice({
  name: "admin",
  initialState:
    JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.ADMIN_CHECK)) ??
    INIT_STATE,
  reducers: {
    setAdmin: (_, { payload }) => {
      if (!payload) {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ADMIN_CHECK);
      } else {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.ADMIN_CHECK,
          JSON.stringify(payload)
        );
      }
      return { ...payload };
    },
  },
});

export const { setAdmin } = adminSlice.actions;

export default adminSlice.reducer;
