import { Outlet } from "react-router-dom";
import Background from "@components/Background";
import NotificationCenter from "@components/NotificationCenter";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import COOKIES_KEYS from "@constants/cookie-keys";
import { useDispatch } from "react-redux";
import { setToken } from "@store/slices/rootSlice";
import { MAIN_ROUTES } from "@constants/routes";
import useAppSettings from "@hooks/useAppSetting";
import APPS from "@constants/app-names";
import Toolbar from "@components/Toolbar";
import { Flowbite } from "flowbite-react";

export default function Main({ routes = MAIN_ROUTES }) {
  const [cookies] = useCookies([COOKIES_KEYS.TOKEN]);
  const [_, __, currentApp] = useAppSettings();
  const dispatch = useDispatch();
  // Initializing the token from the cookies
  useEffect(() => {
    if (cookies[COOKIES_KEYS.TOKEN]) {
      dispatch(setToken(cookies[COOKIES_KEYS.TOKEN]));
    }
  }, [cookies, dispatch]);

  return (
    <div className="hidden-scrollbar overflow-x-hidden relative">
      <Background />
      <NotificationCenter />
      {/* {(currentApp !== APPS.main) && (
        <Toolbar routes={routes} />
      )} */}
      <Outlet />
    </div>
  );
}
