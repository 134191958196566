import useAppSettings from "@hooks/useAppSetting";
export default function Background() {
  const [dark] = useAppSettings();
  return (
    <div
      style={{
        background: !dark ? 'var(--light-bg)' : 'var(--dark-bg)',
        minHeight: "100vh",
        minWidth: "100vw",
        width: "100%",
        hieght: "100%"
      }}
      className="flex-1 min-w-screen min-h-screen absolute inset-0 -z-50"
    />
  );
}
