import React, { useEffect, useState } from "react";
import useNavigator from "@hooks/useNavigator";
import useDiscoverEmail from "@requests/auth/useDiscoverEmail";
import { useDispatch } from "react-redux";
import { addToast, resetRootState } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import useLogIn from "@requests/auth/useLogIn";
import useRegisterEmail from "@requests/auth/useRegisterEmail";
import { useCookies } from "react-cookie";
import useForgotPassword from "@requests/auth/useForgotPassword";
import APPS, { DEFAULT_DOMAIN } from "@constants/app-names";
import COOKIES_KEYS from "@constants/cookie-keys";
import { useSearchParams } from "react-router-dom";
import QUERY_KEYS from "@constants/query-keys";
import { MAIN_ROUTES, SHARED_ROUTES } from "@constants/routes";
import Loader from "@components/Loader";
import AuthCard from "@components/Flowbite/AuthCard";
import BackButton from "@components/Flowbite/BackButton";
import FormInput from "@components/Flowbite/FormInput";
import ActionButton from "@components/Flowbite/ActionButton";
import {
  getFacebookURL,
  getLinkedinURL,
  getMicrosoftURL,
} from "@utils/helperSSO";
import { getGoogleUrl } from "@utils/googleSSO";
import FlowBiteHeader from "@components/Flowbite/Header";

const CommonLogin = ({ persona }) => {
  const [goto] = useNavigator();

  const [error, setError] = useState({
    email: "",
    name: "",
    loginPwd: "",
    newPwd: "",
    cnfPwd: "",
  });

  const [componentState, setComponentState] = useState(1);
  const [isRegistrationFlow, setIsRegistrationFlow] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get(QUERY_KEYS.APP) || searchParams.get(QUERY_KEYS.PATH)) {
      dispatch(
        addToast(
          TOASTS.error("Session has expired! Please login again to continue!")
        )
      );
    }
  }, [dispatch, searchParams]);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    register: "",
    loginPwd: "",
  });

  const headerText = persona === "brand" ? "Brand Login" : "Influencer Login";

  const [_, setCookie] = useCookies([COOKIES_KEYS.TOKEN]);

  const handleRedirect = () => {
    if (searchParams.get(QUERY_KEYS.APP) && searchParams.get(QUERY_KEYS.PATH)) {
      goto(
        searchParams.get(QUERY_KEYS.PATH),
        APPS[searchParams.get(QUERY_KEYS.APP)]
      );
    } else if (persona === "brand") {
      goto(SHARED_ROUTES.MAIN.path, APPS.advertiser);
    } else {
      goto(MAIN_ROUTES.SOCIAL_MEDIA_ACCS.path, APPS.main);
    }
  };

  const [goTo] = useNavigator();

  useEffect(() => {
    dispatch(resetRootState());
  });

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function validatePassword(p) {
    return (
      p.length >= 8 &&
      /[A-Z]/.test(p) &&
      /[a-z]/.test(p) &&
      /[^a-zA-Z0-9]/.test(p)
    );
  }

  const { isLoading: isDiscoveryEmailLoading, mutate: DiscoverEmailMutate } =
    useDiscoverEmail({
      onSuccess({ data }) {
        if (data.exists) {
          setComponentState(2);
        } else {
          setComponentState(3);
        }
      },
      onError(err) {
        console.log(err);
        if (
          err.response.status &&
          err.response.status === 400 &&
          err.response.data.message
        ) {
          dispatch(addToast(TOASTS.error(err.response.data.message)));
        } else {
          dispatch(
            addToast(
              TOASTS.error(
                "Something went wrong at our end. Please check back later"
              )
            )
          );
        }
      },
    });

  const { isLoading: isLogInLoading, mutate: LogInMutate } = useLogIn({
    onSuccess(data) {
      if (data && data.userData && data.userData.token) {
        setCookie(COOKIES_KEYS.TOKEN, data.userData.token, {
          path: "/",
          domain: process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN,
        });
      }
      handleRedirect();
    },
    onError(err) {
      console.log(err);
      if (err.response.status && err.response.data.message) {
        setError({
          ...error,
          loginPwd: err.response.data.message,
        });
      } else {
        dispatch(
          addToast(
            TOASTS.error(
              "Something went wrong at our end. Please check back later"
            )
          )
        );
      }
    },
  });

  const { isLoading: isRegisterEmailLoading, mutate: RegisterEmailMutate } =
    useRegisterEmail({
      onSuccess() {
        setComponentState(5);
      },
      onError(err) {
        console.log(err);
        if (
          err.response.status &&
          err.response.status === 400 &&
          err.response.data.message
        ) {
          dispatch(addToast(TOASTS.error(err.response.data.message)));
        } else {
          dispatch(
            addToast(
              TOASTS.error(
                "Something went wrong at our end. Please check back later"
              )
            )
          );
        }
      },
    });

  const { isLoading: isForgotPasswordLoading, mutate: ForgotPasswordMutate } =
    useForgotPassword({
      onSuccess() {
        setComponentState(5);
      },
      onError(err) {
        console.log(err);
        if (
          err.response.status &&
          err.response.status === 400 &&
          err.response.data.message
        ) {
          dispatch(addToast(TOASTS.error(err.response.data.message)));
        } else {
          dispatch(
            addToast(
              TOASTS.error(
                "Something went wrong at our end. Please check back later"
              )
            )
          );
        }
      },
    });

  const continueEmail = async (event) => {
    event.preventDefault();

    if (formData.email && validateEmail(formData.email)) {
      DiscoverEmailMutate(formData.email);
    } else {
      dispatch(addToast(TOASTS.error("Please enter a valid email address!")));
    }
  };

  const continueLogin = async (event) => {
    event.preventDefault();

    setError({
      ...error,
      loginPwd: "",
    });

    LogInMutate({ email: formData.email, password: formData.loginPwd });
  };

  const continueRegister = async (event) => {
    event.preventDefault();

    setError({
      ...error,
      register: "",
    });

    if (
      !formData.name ||
      formData.name === "" ||
      !formData.name.includes(" ")
    ) {
      setError({
        ...error,
        register: "Please enter your full name!",
      });
      return;
    }

    if (formData.newPwd && !validatePassword(formData.newPwd)) {
      setError({
        ...error,
        register:
          "Please enter an 8 character password that contains an uppercase, lowercase and a symbol!",
      });
      return;
    }

    if (formData.newPwd !== formData.cnfPwd) {
      dispatch(
        addToast(TOASTS.error("Password and confirm password doesn't match!"))
      );
      setError({
        ...error,
        register: "Password and confirm password doesn't match!",
      });
      return;
    }

    setIsRegistrationFlow(true);

    RegisterEmailMutate({
      name: formData.name,
      email: formData.email,
      password: formData.newPwd,
      source: persona,
    });
  };

  const continueForgotPass = async (event) => {
    event.preventDefault();

    if (formData.newPwd && !validatePassword(formData.newPwd)) {
      dispatch(
        addToast(
          TOASTS.error(
            "Please enter an 8 character password that contains an uppercase, lowercase and a symbol!"
          )
        )
      );
      return;
    }

    if (formData.newPwd !== formData.cnfPwd) {
      dispatch(
        addToast(TOASTS.error("Password and confirm password doesn't match!"))
      );
      return;
    }

    ForgotPasswordMutate({
      email: formData.email,
      password: formData.newPwd,
    });
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;

    setFormData({
      ...formData,
      email: email,
    });
  };

  const handleLoginPwdChange = (event) => {
    const loginPwd = event.target.value;

    setFormData({
      ...formData,
      loginPwd: loginPwd,
    });
  };

  const handleNewPwdChange = (event) => {
    const newPwd = event.target.value;

    setFormData({
      ...formData,
      newPwd: newPwd,
    });
  };

  const handleCnfPwdChange = (event) => {
    const cnfPwd = event.target.value;

    setFormData({
      ...formData,
      cnfPwd: cnfPwd,
    });
  };

  const handleNameChange = (event) => {
    const name = event.target.value;

    setFormData({
      ...formData,
      name: name,
    });
  };

  const googleLogin = (e) => {
    e.preventDefault();
    const google_url = getGoogleUrl(persona);
    window.location.href = google_url;
  };

  const linkedinLogin = (e) => {
    e.preventDefault();
    const google_url = getLinkedinURL(persona);
    window.location.href = google_url;
  };

  const facebookLogin = (e) => {
    e.preventDefault();
    const google_url = getFacebookURL(persona);
    window.location.href = google_url;
  };

  const microsoftLogin = (e) => {
    e.preventDefault();
    const google_url = getMicrosoftURL(persona);
    window.location.href = google_url;
  };

  useEffect(() => {
    const mutationLoadings = [
      isDiscoveryEmailLoading,
      isLogInLoading,
      isRegisterEmailLoading,
      isForgotPasswordLoading,
    ];

    const isMutationLoading = mutationLoadings.some((loading) => loading);

    setIsLoading(isMutationLoading);
  }, [
    isDiscoveryEmailLoading,
    isForgotPasswordLoading,
    isLogInLoading,
    isRegisterEmailLoading,
  ]);

  return (
    <>
      <FlowBiteHeader showLogoutBtn={false} toggle={false} />

      {isLoading && <Loader />}

      {/* <section className="login"> */}
      {componentState === 1 && (
        <AuthCard>
          {persona === "influencer" ? (
            <h2 className="leading-[45px] text-5xl font-bold tracking-normal text-gray-900 dark:text-white">
              Your Path to Digital Stardom!
            </h2>
          ) : persona === "brand" ? (
            <h2 className="leading-[45px] text-5xl font-bold tracking-normal text-gray-900 dark:text-white">
              Your Destination for Social Advertising!
            </h2>
          ) : null}
          <form action="" onSubmit={continueEmail}>
            <FormInput
              type="email"
              requiredaa
              label="Enter Your Email"
              placeholder="Email"
              value={formData.email}
              onChange={handleEmailChange}
            />

            <ActionButton type="submit">Continue</ActionButton>

            <div class="my-10 text-gray-800 center text-center">Or</div>

            <div className="flex flex-col gap-4 justify-center">
              <div class="flex gap-8 justify-center items-center">
                <button
                  onClick={googleLogin}
                  type="button"
                  class="flex items-center space-x-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-200 font-large rounded-lg text-2xl px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <svg
                    class="h-6 w-6 fill-current text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                  >
                    <g id="Google ">
                      <g id="Vector">
                        <path
                          d="M46 24.9602C46 23.4346 45.8763 21.9007 45.6124 20.3998H24.4432V29.0423H36.5658C36.0628 31.8297 34.4464 34.2954 32.0797 35.8623V41.47H39.312C43.559 37.5611 46 31.7884 46 24.9602Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M24.4432 46.888C30.4963 46.888 35.6009 44.9006 39.3202 41.47L32.0879 35.8622C30.0757 37.2312 27.478 38.0064 24.4515 38.0064C18.5964 38.0064 13.6319 34.0562 11.8506 28.7454H4.3874V34.5263C8.19735 42.105 15.9574 46.888 24.4432 46.888Z"
                          fill="#34A853"
                        />
                        <path
                          d="M11.8424 28.7454C10.9023 25.958 10.9023 22.9398 11.8424 20.1524V14.3715H4.38742C1.20422 20.7132 1.2042 28.1846 4.3874 34.5263L11.8424 28.7454Z"
                          fill="#FBBC04"
                        />
                        <path
                          d="M24.4432 10.8831C27.6429 10.8337 30.7354 12.0377 33.0527 14.2478L39.4604 7.84013C35.403 4.03019 30.018 1.93554 24.4432 2.00151C15.9574 2.00151 8.19737 6.78459 4.38742 14.3715L11.8424 20.1524C13.6154 14.8333 18.5881 10.8831 24.4432 10.8831Z"
                          fill="#EA4335"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Continue with Google</span>
                </button>
              </div>

              <div class="flex gap-8 justify-center items-center">
                <button
                  onClick={facebookLogin}
                  type="button"
                  class="flex items-center space-x-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-200 font-large rounded-lg text-2xl px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <svg
                    class="h-8 w-8 fill-current text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#039be5"
                      d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                    />
                    <path
                      fill="#fff"
                      d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                    />
                  </svg>
                  <span>Continue with Facebook</span>
                </button>
              </div>

              <div class="flex gap-8 justify-center items-center">
                <button
                  onClick={linkedinLogin}
                  type="button"
                  class="flex items-center space-x-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-200 font-large rounded-lg text-2xl px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    className="h-8 w-8 fill-current text-red-500"
                  >
                    <path
                      fill="#0078d4"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5	V37z"
                    />
                    <path
                      d="M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364	c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274	L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479	C18,16.523,16.521,18,14.485,18H18v19H11z"
                      opacity=".05"
                    />
                    <path
                      d="M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677	c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638	c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001	c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z"
                      opacity=".07"
                    />
                    <path
                      fill="#fff"
                      d="M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12	c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698	c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19	c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z"
                    />
                  </svg>
                  <span>Continue with Linkedin</span>
                </button>
              </div>
              {/* 
              <div class="flex gap-8 justify-center items-center">
                <button
                  onClick={microsoftLogin}
                  type="button"
                  class="flex items-center space-x-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-200 font-large rounded-lg text-2xl px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    className="h-8 w-8 fill-current text-red-500"
                  >
                    <path
                      fill="#ff5722"
                      d="M6 6H22V22H6z"
                      transform="rotate(-180 14 14)"
                    />
                    <path
                      fill="#4caf50"
                      d="M26 6H42V22H26z"
                      transform="rotate(-180 34 14)"
                    />
                    <path
                      fill="#ffc107"
                      d="M26 26H42V42H26z"
                      transform="rotate(-180 34 34)"
                    />
                    <path
                      fill="#03a9f4"
                      d="M6 26H22V42H6z"
                      transform="rotate(-180 14 34)"
                    />
                  </svg>
                  <span>Continue with Microsoft</span>
                </button>
              </div> */}
            </div>
          </form>
        </AuthCard>
      )}

      {componentState === 2 && (
        <AuthCard>
          <BackButton
            onClick={() => {
              setComponentState(1);
            }}
          />
          <h5 className="leading-[55px] text-5xl font-bold tracking-normal text-gray-900 dark:text-white">
            Let's Get in!
          </h5>

          {error.loginPwd && (
            <span className="bg-red-500 text-white rounded p-4">
              {error.loginPwd}
            </span>
          )}

          <form action="" onSubmit={continueLogin}>
            <FormInput
              type="password"
              label="Enter your password"
              required
              placeholder="*********"
              min="8"
              max="16"
              value={formData.loginPwd}
              onChange={handleLoginPwdChange}
            />

            <ActionButton type="submit">Log In</ActionButton>

            <div
              onClick={() => {
                setComponentState(4);
              }}
              className="h-[41px] px-5 py-2.5 bg-white rounded justify-center items-center gap-2 flex"
            >
              <div className="text-violet-700 text-md cursor-pointer font-medium leading-[21px]">
                Forgot Password
              </div>
            </div>
          </form>
        </AuthCard>
      )}

      {componentState === 3 && (
        <AuthCard>
          <BackButton
            onClick={() => {
              setComponentState(1);
            }}
          />

          <h5 className="leading-[55px] text-5xl font-bold tracking-normal text-gray-900 dark:text-white">
            Setup a new one
          </h5>

          {error.register && (
            <div className="bg-red-500 text-white rounded p-4">
              <span>{error.register}</span>
            </div>
          )}

          <form action="" onSubmit={continueRegister}>
            <FormInput
              label="Full Name"
              type="text"
              required
              placeholder="John Doe"
              min="3"
              value={formData.name}
              onChange={handleNameChange}
            />

            <FormInput
              label="New Password"
              type="password"
              required
              placeholder="*********"
              min="8"
              max="16"
              value={formData.newPwd}
              onChange={handleNewPwdChange}
            />

            <FormInput
              label="Confirm Password"
              type="password"
              required
              placeholder="*********"
              min="8"
              max="16"
              value={formData.cnfPwd}
              onChange={handleCnfPwdChange}
            />

            <ActionButton type="submit">Sign Up</ActionButton>
          </form>
        </AuthCard>
      )}

      {componentState === 4 && (
        <AuthCard>
          <BackButton
            onClick={() => {
              setComponentState(1);
            }}
          />

          <h5 className="leading-[55px]   text-5xl font-bold tracking-normal text-gray-900 dark:text-white">
            Let's Reset.
          </h5>
          <form action="" onSubmit={continueForgotPass}>
            <FormInput
              label="New Password"
              type="password"
              required
              placeholder="*********"
              min="8"
              max="16"
              value={formData.newPwd}
              onChange={handleNewPwdChange}
            />

            <FormInput
              label="Confirm Password"
              type="password"
              required
              placeholder="*********"
              min="8"
              max="16"
              value={formData.cnfPwd}
              onChange={handleCnfPwdChange}
            />

            <ActionButton type="submit">Confirm</ActionButton>
          </form>
        </AuthCard>
      )}

      {componentState === 5 && (
        <AuthCard>
          <div className="m-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="green"
              className="w-48 h-48 m-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>
          </div>
          <div className="popup-title m-8">Email Sent</div>
          <div className="popup-content">
            A {isRegistrationFlow ? "email verification " : "password reset "}
            link has been sent to your email. Please check your inbox.
          </div>
          <br />
          <br />
          <div className="popup-close ">
            <button
              className="close-btn text-blue"
              onClick={() => {
                setComponentState(1);
              }}
            >
              Close
            </button>
          </div>
        </AuthCard>
      )}
    </>
  );
};

export default CommonLogin;
