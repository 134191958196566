import { Button, ProgressCircular, Typography } from "ui-neumorphism";
import linkedInlogo from "@assets/linkedin-logo.png";
import {
  FONT_WEIGHTS,
  TEXT_SIZES,
  getTextClassName,
} from "@utils/tailwind.constants";
import useAppSetting from "@hooks/useAppSetting";
export default function ExtendedButton({
  handleClick,
  className = "",
  isLoading = false,
  img,
  text = "No text passed!",
}) {
  const [dark, isMobile] = useAppSetting();

  return isLoading ? (
    <ProgressCircular dark={dark} indeterminate />
  ) : (
    <button type="button">
      <Button
        rounded
        size={isMobile ? "medium" : "large"}
        onClick={
          handleClick
            ? () => {
                handleClick();
              }
            : undefined
        }
        className={`flex gap-1 p-5 ${className}`}
        dark={dark}
      >
        {img && (
          <img
            src={img || linkedInlogo}
            alt=""
            className="w-8 h-8 object-contain"
          />
        )}
        <Typography type="body-1" dark={dark} className="ml-2">
          {text}
        </Typography>
      </Button>
    </button>
  );
}
