import { Children } from "react";

export const Tabs = ({
  active,
  onChange,
  children,
  tabStyle = "classic",
  align = "center",
}) => {
  const arrayChildren = Children.toArray(children);

  const listChildren = [];
  const otherChildren = [];

  arrayChildren.map((child, index) => {
    if (child.props.type && child.props.type === "filter") {
      otherChildren.push(child);
    } else {
      listChildren.push(child);
    }
  });

  return (
    <>
      {tabStyle === "classic" && (
        <div className="border-gray-200 dark:border-gray-700">
          <ul
            className="mt-6 gap-6 flex flex-wrap -mb-px ml-8 text-md font-large text-center text-white-500 dark:text-white-400"
            id="tabExample"
            role="tablist"
          >
            {listChildren.map((child, index) => {
              return (
                <li className="mr-2" role="presentation">
                  <button
                    className={`py-2.5 px-12 mr-2 w-full mb-2 rounded-lg text-md font-large focus:outline-none border  focus:z-10 focus:ring-4 focus:ring-purple-200 ${
                      active === index
                        ? "text-white hover:text-purple-800 hover:bg-purple-100 border-purple-200 bg-purple-800"
                        : "hover:bg-purple-100 hover:text-purple-700 bg-white"
                    }`}
                    type="button"
                    role="tab"
                    aria-selected={active === index}
                    onClick={() => onChange(index)}
                  >
                    {child}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {tabStyle === "modern" && (
        <div className="mx-auto text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 py-4 px-6">
          <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-gray-200 dark:border-gray-700 dark:text-gray-400">
            {listChildren.map((child, index) => {
              return (
                <li className={`mr-2 `} role="presentation">
                  <button
                    className={`inline-block p-4 hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300 
                      ${
                        active === index
                          ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                          : ""
                      }`}
                    type="button"
                    role="tab"
                    aria-selected={active === index}
                    onClick={() => onChange(index)}
                  >
                    {child}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {tabStyle === "flowbite" && (
        <div class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul
            class={`flex flex-wrap ${
              align === "center" ? "justify-center" : "justify-start"
            } -mb-px`}
          >
            {listChildren.map((child, index) => {
              return (
                <li className="mr-2">
                  <button
                    href="#"
                    className={
                      active === index
                        ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                        : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    }
                    type="button"
                    role="tab"
                    aria-selected={active === index}
                    onClick={() => onChange(index)}
                  >
                    {child}
                  </button>
                </li>
              );
            })}
          </ul>

          <div className="ml-auto">
            {otherChildren.map((child, index) => {
              return (
                <button
                  href="#"
                  className={
                    active === index
                      ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  }
                  type="button"
                  role="tab"
                  aria-selected={active === index}
                  onClick={() => onChange(index)}
                >
                  {child}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export const Tab = ({ children }) => {
  return <>{children}</>;
};
