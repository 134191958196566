import InfluencerHome from '@components/InfluencerHome/InfluencerHome'
import InfluencerSection from '@components/InfluencerSection/InfluencerSection'
import Contact from '@main/fragments/Contact/Contact'
import Footer from '@main/fragments/Footer/Footer'
import Header from '@main/fragments/Header/Header'
import React from 'react'
import { Helmet } from 'react-helmet'

const Influencer = () => {
  return (
    <>
     <Helmet>
        <title>Monetize Your Influence: Join Influcracy for Lucrative Opportunities</title>
        <meta
          name="description"
          content="Transform your social media presence into profitable assets with Influcracy. Gain fair compensation, strengthen your reputation, and engage with brands aligned with your values. Sign in and explore."
        />
        <meta name="keywords" content="Influcracy.com, influencer marketing services, brand collaborations, marketing campaigns, digital advertising" />
      </Helmet>
    <Header/>
    <InfluencerHome/>
    <InfluencerSection/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default Influencer