import ConfirmationDialog from "@components/ConfirmationDialog";
import APPS, { DEFAULT_DOMAIN } from "@constants/app-names";
import COOKIES_KEYS from "@constants/cookie-keys";
import useNavigator from "@hooks/useNavigator";
import { persistor } from "@store/configureStore";
import { resetAdvState } from "@store/slices/advertiserSlice";
import { resetIGState } from "@store/slices/igSlice";
import { resetRootState } from "@store/slices/rootSlice";
import getCurrentApp from "@utils/get-current-app";
import { Button } from "flowbite-react";
import React, { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";

const FlowBiteHeader = ({
  toggle,
  showProfileCompletionBtn,
  profileCompletionPC,
  showLogoutBtn = true,
}) => {
  const borderColor = "#88E619";

  const [profileBtnOnFocus, setProfileBtnOnFocus] = useState(false);

  const [buttonStyle, setButtonStyle] = useState({
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "transparent",
    borderImage: `linear-gradient(45deg, ${borderColor} ${profileCompletionPC}%, transparent ${profileCompletionPC}%) 1`,
    borderImageSlice: 1,
    transition: "border 0.3s ease-in-out",
  });

  const [navigate] = useNavigator();
  const [_, setCookie] = useCookies([COOKIES_KEYS.TOKEN]);

  const dispatch = useDispatch();

  const [isLogoutOpen, setIsLogoutOpen] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setIsLogoutOpen(false);
  }, []);

  const logoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const logout = useCallback(() => {
    setCookie(COOKIES_KEYS.TOKEN, null, {
      path: "/",
      domain: process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN,
    });

    dispatch(resetRootState());
    dispatch(resetIGState());
    dispatch(resetAdvState());

    persistor.purge();

    const currentSchema = window.location.protocol;

    const main_sub_domain = APPS["main"];

    let targetUrl;

    if (main_sub_domain != "") {
      targetUrl = `${currentSchema}//${main_sub_domain}.${
        process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN
      }`;
    } else {
      targetUrl = `${currentSchema}//${
        process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN
      }`;
    }

    window.location.href = targetUrl;
  }, []);

  const completeProfile = useCallback(() => {
    navigate("/complete-profile");
  }, [navigate]);

  const handleMouseEnter = () => {
    setProfileBtnOnFocus(true);
  };

  const handleMouseLeave = () => {
    setProfileBtnOnFocus(false);
  };

  return (
    <>
      <nav class=" border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
        <div class="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          <a href="" class="flex items-center" onClick={logoClick}>
            <span class="self-center text-purple-800 text-4xl font-bold whitespace-nowrap dark:text-white">
              Influcracy
            </span>
          </a>

          <div className="flex gap-4 items-center">
            {showProfileCompletionBtn && (
              <button
                style={buttonStyle}
                onClick={completeProfile}
                type="button"
                class="bg-purple-700 text-white flex items-center px-8 py-3.5 text-base font-medium focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {profileBtnOnFocus
                  ? `Complete Profile`
                  : `${profileCompletionPC}%`}
              </button>
            )}

            {/* <button className="font-bold border border-gray-800 rounded-full p-4">
              <FaUser />
            </button> */}

            {showLogoutBtn && (
              <button
                onClick={() => {
                  setIsLogoutOpen(true);
                }}
                type="button"
                class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-gray-800 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                Logout
              </button>
            )}
          </div>

          <div class="hidden flex items-center space-x-2">
            {toggle && (
              <button
                type="button"
                class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-gray-800 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="wallet-outline">
                      <g id="Vector">
                        <path
                          d="M11.4 5.46406V4.07543C11.4 3.75209 11.2736 3.44199 11.0485 3.21335C10.8235 2.98471 10.5183 2.85627 10.2 2.85627H9.669L7.6128 0.230186C7.51366 0.103597 7.36909 0.0222047 7.21088 0.00391364C7.05268 -0.0143774 6.8938 0.0319314 6.7692 0.132653C6.6446 0.233374 6.56449 0.380257 6.54648 0.540989C6.52848 0.701721 6.57406 0.863137 6.6732 0.989725L8.1348 2.85627H7.269L5.2128 0.230186C5.16238 0.166302 5.09988 0.113324 5.02898 0.0743771C4.95808 0.0354304 4.88022 0.011304 4.8 0.00342109C4.63892 -0.0113299 4.47865 0.0392957 4.3542 0.144235L1.1904 2.85627C0.873807 2.85884 0.571043 2.98842 0.348071 3.21678C0.1251 3.44514 -1.01309e-05 3.75377 6.1529e-10 4.07543V10.7808C6.15291e-10 11.1042 0.126428 11.4143 0.351472 11.6429C0.576515 11.8716 0.88174 12 1.2 12H10.2C10.5183 12 10.8235 11.8716 11.0485 11.6429C11.2736 11.4143 11.4 11.1042 11.4 10.7808V9.39221C11.5818 9.28605 11.733 9.13319 11.8383 8.94895C11.9436 8.7647 11.9994 8.55556 12 8.34251V6.51376C11.9994 6.30071 11.9436 6.09156 11.8383 5.90732C11.733 5.72308 11.5818 5.57021 11.4 5.46406ZM10.8 6.51376V8.34251H7.8C7.64087 8.34251 7.48826 8.27828 7.37574 8.16396C7.26321 8.04965 7.2 7.8946 7.2 7.73293V7.12334C7.2 6.96167 7.26321 6.80662 7.37574 6.6923C7.48826 6.57798 7.64087 6.51376 7.8 6.51376H10.8ZM4.6578 1.48044L5.7354 2.85627H3.0528L4.6578 1.48044ZM1.2 10.7808V4.07543H10.2V5.2946H7.8C7.32261 5.2946 6.86477 5.48727 6.52721 5.83022C6.18964 6.17318 6 6.63833 6 7.12334V7.73293C6 8.21794 6.18964 8.68309 6.52721 9.02604C6.86477 9.369 7.32261 9.56167 7.8 9.56167H10.2V10.7808H1.2Z"
                          fill="#1F2A37"
                        />
                        <path
                          d="M8.7 8.03772C9.03137 8.03772 9.3 7.7648 9.3 7.42813C9.3 7.09147 9.03137 6.81855 8.7 6.81855C8.36863 6.81855 8.1 7.09147 8.1 7.42813C8.1 7.7648 8.36863 8.03772 8.7 8.03772Z"
                          fill="#1F2A37"
                        />
                      </g>
                    </g>
                  </svg>
                </svg>
                <span className="ml-1">Wallet</span>
              </button>
            )}

            {toggle && (
              <button
                type="button"
                class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-gray-800 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="user-outline">
                      <g id="Vector">
                        <path
                          d="M6 5.68421C5.38384 5.68421 4.78151 5.51752 4.26919 5.20523C3.75686 4.89294 3.35756 4.44906 3.12176 3.92973C2.88596 3.41041 2.82427 2.83895 2.94448 2.28764C3.06468 1.73632 3.3614 1.22991 3.79709 0.832434C4.23279 0.434959 4.78789 0.164275 5.39222 0.0546115C5.99654 -0.0550517 6.62294 0.00123143 7.19221 0.216344C7.76147 0.431456 8.24803 0.795735 8.59035 1.26312C8.93267 1.7305 9.11538 2.27999 9.11538 2.84211C9.11447 3.59562 8.78595 4.31804 8.2019 4.85085C7.61785 5.38367 6.82597 5.68337 6 5.68421ZM6 1.26316C5.65769 1.26316 5.32306 1.35576 5.03844 1.52926C4.75381 1.70276 4.53198 1.94935 4.40098 2.23787C4.26998 2.52638 4.2357 2.84386 4.30249 3.15014C4.36927 3.45643 4.53411 3.73777 4.77616 3.95859C5.01821 4.17941 5.32661 4.32979 5.66234 4.39071C5.99808 4.45164 6.34608 4.42037 6.66234 4.30086C6.97859 4.18136 7.2489 3.97898 7.43908 3.71932C7.62926 3.45967 7.73077 3.15439 7.73077 2.84211C7.73077 2.42334 7.54842 2.02173 7.22384 1.72562C6.89926 1.42951 6.45903 1.26316 6 1.26316Z"
                          fill="#1F2A37"
                        />
                        <path
                          d="M9.80769 12H2.19231C2.0087 12 1.83261 11.9335 1.70277 11.815C1.57294 11.6966 1.5 11.5359 1.5 11.3684V9.47368C1.5011 8.63647 1.86615 7.83383 2.51508 7.24182C3.164 6.64982 4.04382 6.31679 4.96154 6.31579H7.03846C7.95618 6.31679 8.836 6.64982 9.48493 7.24182C10.1339 7.83383 10.4989 8.63647 10.5 9.47368V11.3684C10.5 11.5359 10.4271 11.6966 10.2972 11.815C10.1674 11.9335 9.9913 12 9.80769 12ZM2.88462 10.7368H9.11538V9.47368C9.11538 8.97117 8.89657 8.48923 8.50707 8.1339C8.11757 7.77857 7.5893 7.57895 7.03846 7.57895H4.96154C4.4107 7.57895 3.88243 7.77857 3.49293 8.1339C3.10343 8.48923 2.88462 8.97117 2.88462 9.47368V10.7368Z"
                          fill="#1F2A37"
                        />
                      </g>
                    </g>
                  </svg>
                </svg>
                <span className="ml-1">Profile</span>
              </button>
            )}
          </div>
        </div>

        <ConfirmationDialog
          isOpen={isLogoutOpen}
          onClose={handleCloseDialog}
          onConfirm={logout}
          message="Are you sure you want to proceed abra ka dabra?"
        />
      </nav>
    </>
  );
};

export default FlowBiteHeader;
