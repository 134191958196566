import FlowBiteHeader from "@components/Flowbite/Header";
import InterestedUsers from "@components/InsterestedUserinCampaign";
import Loader from "@components/Loader";
import apiClient from "@utils/apiClient";
import { getPage } from "@utils/tailwind.constants";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CampaignApplications = () => {
  const { campaign_id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] = useState({});

  useEffect(() => {
    setIsLoading(true);

    apiClient
      .get(`/api/v1/adv/campaigns/${campaign_id}/`)
      .then((res) => {
        setCampaign(res.data.campaign);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <FlowBiteHeader />

      <div className={`${getPage("", false, false, true)}`}>
        {isLoading && <Loader />}

        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t mb-8">
          <h3 className="text-3xl font-semibold">{campaign.title}</h3>
        </div>

        {!isLoading && <InterestedUsers campaign={campaign} />}
      </div>
    </>
  );
};

export default CampaignApplications;
