import { nanoid } from "@reduxjs/toolkit";

const TOASTS = {
  success: (message) => {
    return { type: "success", message, id: nanoid() };
  },
  warning: (message) => {
    return { type: "warning", message, id: nanoid() };
  },
  error: (message) => {
    return { type: "toastError", message, id: nanoid() };
  },
};

export default TOASTS