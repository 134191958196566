import React from "react";

const ActionButton = ({ type, children}) => {
  return (
    <button
      type={type}
      class="h-[4.5rem] mt-2 mb-2 focus:outline-none text-white w-full bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
    >{children}</button>
  );
};

export default ActionButton;
