import React, { useState, useEffect } from "react";

const SelectField = ({
  id,
  label,
  children,
  onSelect,
  defaultValue,
  className,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  useEffect(() => {
    setSelectedValue(defaultValue || "");
  }, [defaultValue]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onSelect(newValue);
  };

  return (
    <div className={`cursor-pointer ${className}`}>
      {label && label !== "" && (
        <label
          htmlFor={id}
          className="block mb-2 text-2xl font-medium text-gray-900"
        >
          {label}
        </label>
      )}

      <select
        id={id}
        className="border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 "
        value={selectedValue}
        onChange={handleSelectChange}
      >
        {children}
      </select>
    </div>
  );
};

export default SelectField;
