import React from "react";

export const TwoColumnGrid = ({ className, children }) => {
  return (
    <div className={`grid grid-cols-4 gap-4 ${className}`}>
      <div className="col-span-2">{children[0]}</div>
      <div className="col-span-2">{children[1] ? children[1] : <></>}</div>
    </div>
  );
};
