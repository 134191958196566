import LiNav from "@components/LiNav";
import React from "react";

// import LiHeader from "../../../components/LiHeader/LIHeader";

const LiDashboard = ()=>{
    return (
        
          <>
          {/* <LiHeader/> */}
          <LiNav/>
          
          
          </>
          
        
      );
}

export default LiDashboard;
