import { Button, Label, Textarea } from "flowbite-react";
import React, { useState } from "react";

const LabeledTextArea = ({ labelText, placeholder, buttonText, onSubmit, onCancel }) => {
  const [comment, setComment] = useState("");

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(comment);
  };

  return (
    <div className="w-full border border-gray-400 rounded-lg" id="textarea">
      <div className="m-2 block">
        <Label className="text-xl font-semibold" htmlFor="comment">
          {labelText}
        </Label>
      </div>
      <Textarea
        id="comment"
        placeholder={placeholder}
        required
        rows={4}
        className="rounded-none"
        value={comment}
        onChange={handleCommentChange}
      />
      <div className="flex flex-row m-4">
        <div className="flex flex-row ml-auto">
          <Button
            className="w-full mr-2"
            color="purple"
            size="lg"
            onClick={onCancel}
            outline
          >
            Cancel
          </Button>
          
          <Button
            className="w-full"
            color="purple"
            size="lg"
            onClick={handleSubmit}
            outline
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LabeledTextArea;
