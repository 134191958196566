const csrfState = Math.random().toString(36).substring(2);

export const getInstaAuthUrl = () => {
  const rootUrl = `https://api.instagram.com/oauth/authorize`;

  const options = {
    client_id: process.env.REACT_APP_IG_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_IG_REDIRECT_URI,
    scope: "user_profile",
    response_type: "code",
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getTikTokAuthUrl = () => {
  const rootUrl = `https://www.tiktok.com/v2/auth/authorize/`;

  const options = {
    client_key: process.env.REACT_APP_TK_CLIENT_KEY,
    redirect_uri: process.env.REACT_APP_TK_REDIRECT_URI,
    response_type: "code",
    scope: "user.info.basic,user.info.profile",
    state: csrfState,
  };

  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

export const getLinkedinURL = (from) => {
  const rootUrl = `https://www.linkedin.com/oauth/v2/authorization`;

  const options = {
    client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
    response_type: "code",
    scope: "r_liteprofile r_emailaddress",
    state: csrfState,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getFacebookURL = (from) => {
  const rootUrl = `https://www.facebook.com/v2.8/dialog/oauth`;

  const options = {
    client_id: process.env.REACT_APP_FB_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_FB_REDIRECT_URI,
    state: csrfState,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getMicrosoftURL = (from) => {
  const rootUrl = `https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize`;

  const options = {
    client_id: "da69021a-5ef3-4769-9835-71ea0914cb12",
    redirect_uri: "https://influcracy.local:80/microsoft",
    response_mode: "query",
    scope: "https://graph.microsoft.com/mail.read",
    state: csrfState,
  };

  const qs = new URLSearchParams(options);

  console.log(`${rootUrl}?${qs.toString()}`);

  return `${rootUrl}?${qs.toString()}`;
};
