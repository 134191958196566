import axios from "axios";
import store from "@store/configureStore"; // your store that holds the access token
import { addToast, setToken } from "@store/slices/rootSlice";
import { setAdmin } from "@store/slices/adminSlice";
import TOASTS from "@constants/toasts";

export const guestApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:8000",
});

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:8000",
});

export const UN_AUTH_ERR_MESSAGE = "No access token found!";

apiClient.interceptors.request.use(async (config) => {
  const accessToken = store.getState().root.accessToken;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    return Promise.reject(new Error(UN_AUTH_ERR_MESSAGE));
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      store.dispatch(setToken(null));
      store.dispatch(setAdmin(null));
      store.dispatch(
        addToast(TOASTS.error("Session expired! Please login again!"))
      );
    }
    return Promise.reject(error);
  }
);

export default apiClient;
