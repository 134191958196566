export default function SelectInput({
  label,
  type = "text",
  className,
  onSelectChange,
  error,
  ...field
}) {
  return (
    <div className="flex flex-col">
      <div>
        <label
          htmlFor={field.name}
          className="block mb-2 text-lg font-medium text-gray-900"
        >
          {label}
        </label>
      </div>
      <div class="grid grid-cols-10">
        <div class="col-span-7">
          <input
            id={field.name}
            type={type}
            className={`bg-white text-center  border-gray-900 text-gray-900 text-xl rounded-l-xl focus:border focus:ring-purple-500 focus:border-purple-500 block w-full p-4 ${className} ${
              error ? "border border-red-500" : "border-1"
            }`}
            {...field}
          />
        </div>
        <div class="col-span-3">
          <select
            id="km"
            onChange={(e) => {
              onSelectChange(e.target.value);
            }}
            className={`bg-purple-100 text-gray-900 text-xl border-gray-900 rounded-r-xl focus:border focus:ring-purple-500 focus:border-purple-500 block w-full p-4 ${className} ${
              error ? "border border-red-500" : "border-1"
            }`}
          >
            <option value="K" selected>
              K
            </option>
            <option value="M">M</option>
          </select>
        </div>
      </div>
      {error && (
        <p className="block mb-2 text-lg font-medium text-red-500">
          {error.message}
        </p>
      )}
    </div>
  );
}
