import adminRouter from "@admin/AdminRoutes";
import advertiserRouter from "@advertiser/AdvertiserRoutes";
import APPS from "@constants/app-names";
import instagramRouter from "@instagram/InstagramRoutes";
import linkedinRouter from "@linkedin/LinkedinRoutes";
import landingRouter from "@main/MainRoutes";
import getCurrentApp from "@utils/get-current-app";
import tiktokRouter from "modules/tiktok/TikTokRoutes";

export default function getCurrentRouter() {
  const currentApp = getCurrentApp();
  switch (APPS[currentApp]) {
    case APPS.admin:
      return adminRouter;
    case APPS.advertiser:
      return advertiserRouter;
    case APPS.instagram:
      return instagramRouter;
    case APPS.linkedin:
      return linkedinRouter;
    case APPS.tiktok:
      return tiktokRouter;
    default:
      return landingRouter;
  }
}
