import PreviousPosts from "@components/PreviousPosts";
import PromoteForm from "@components/PromoteForm";
import React from "react";
import { Card } from "ui-neumorphism";


const Promote = () => {
  return (
    <>
      <Card
        rounded
        style={{
          marginTop: "10rem",
          padding: "18px",
          display: "flex",
          justifyContent: "center",
          width: "80vw",
        }}
      >
        <PromoteForm/>
      </Card>
      <PreviousPosts/>
    </>
  );
};

export default Promote;
