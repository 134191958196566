import { createSlice } from "@reduxjs/toolkit";
const INIT_STATE = {
  accessToken: null,
  darkMode: false,
  toasts: [],
  currentUser: {
    email: "",
  },
};
export const rootSlice = createSlice({
  name: "root",
  initialState: INIT_STATE,
  reducers: {
    resetRootState: () => INIT_STATE,
    setToken: (state, { payload }) => {
      state.accessToken = payload;
      if (payload === null) {
        state = { ...INIT_STATE, darkMode: state.darkMode };
      }
    },
    addToast: (state, action) => {
      state.toasts.push(action.payload);
    },
    closeToast: (state, action) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
  },
});

export const {
  resetRootState,
  toggleDarkMode,
  addToast,
  closeToast,
  setToken,
  setCurrentUser,
} = rootSlice.actions;

export default rootSlice.reducer;
