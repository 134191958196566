import React, { useCallback } from "react";
import "./InfluencerHome.css";
import useNavigator from "@hooks/useNavigator";
const InfluencerHome = () => {
  const [navigate] = useNavigator();

  const influencerSigninClick = useCallback(() => {
    navigate("/influencerSignin");
  }, [navigate]);

  return (
    <section class="mt-[6rem] bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
        <h1 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white"> Monetize your social media presence.</h1>
        <p class="mb-6 text-dark text-gray-500 md:text-2xl dark:text-gray-400"> We help influencers leverage their social media, turning their passion and influence into lucrative opportunities. With our industry expertise and network of brands, you can transform your social media presence into a profitable asset, earning fair compensation for your authentic content and engaging with brands that align with your values.</p>
        <div class="flex items-center flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <button onClick={influencerSigninClick} type="button" class="w-[22rem] h-[4rem] p-4 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-large rounded-full text-xl px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Sign In</button>
        </div>
    </div>
</section>
  );
};

export default InfluencerHome;
