import React, { useCallback } from "react";
import fb from "@assets/facebook.svg";
import Li from "@assets/linkedin.svg";
import twi from "@assets/twitter.svg";
import insta from "@assets/instagram.svg";
import "./Footer.css";
import { MAIN_ROUTES } from "@constants/routes";
import useNavigator from "@hooks/useNavigator";

const Footer = () => {
  const [goTo] = useNavigator();

  const termsClick = useCallback(() => {
    goTo(MAIN_ROUTES.TOU.path);
  }, [goTo]);

  const privacyClick = useCallback(() => {
    goTo(MAIN_ROUTES.PRIVACY.path);
  }, [goTo]);

  return (
    <footer class="footer bg-white">
      <div class="wrapper">
        <div class="footer_middle">
          <div class="footer_middle_left">
            <h5>Follow Us</h5>
            <div class="footer_middle_left_list">
              <div class="footer_icons">
                <a href="" target="_blank">
                  <img src={fb} alt="facebook" />
                </a>
              </div>
              <div class="footer_icons">
                <a href="https://www.linkedin.com/company/79673783" target="_blank">
                  <img src={Li} alt="linkedIn" />
                </a>
              </div>
              <div class="footer_icons">
                <a href="https://twitter.com/influcracy" target="_blank">
                  <img src={twi} alt="twitter" />
                </a>
              </div>
              <div class="footer_icons">
                <a href="https://instagram.com/influcracy" target="_blank">
                  <img src={insta} alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <p>©2023 Influcracy Inc.</p>
          <ul class="footer_bottom_list">
            <li>
              <a
                onClick={termsClick}
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a
                onClick={privacyClick}
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
