import MultiSelectText from "@components/MultiSelectText";
import React, { useState } from "react";
import { Button } from "ui-neumorphism";

const CreateForm = () => {
  const [state, setState] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const generateClick = () => {
    setState(2);
  };

  const regenerateClick = () => {
    // Implement your logic to regenerate the post
  };

  const publishClick = () => {
    // Implement your logic to publish the post
  };

  return (
    <>
      {state === 1 && (
        <form
          className="post"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>What would you like to post about</div>
          <MultiSelectText
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
          <Button rounded onClick={generateClick}>
            Generate my post $0.01
          </Button>
        </form>
      )}

      {state === 2 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>What would you like to post about</div>
          <MultiSelectText
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
          <div
            className="populate-content-with"
            style={{
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            Populate content with writing effect... ADD OPEN_AI LOGIC HERE
          </div>
          <div
            className="generate__buttons"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Button rounded onClick={regenerateClick}>
              ReGenerate my post $0.01
            </Button>
            <Button rounded onClick={publishClick}>
              Publish it on my feed(free)
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default CreateForm;
