import axios from "axios";
import TOASTS from "@constants/toasts";
import { addToast } from "@store/slices/rootSlice";
import { UN_AUTH_ERR_MESSAGE } from "@utils/apiClient";
import getCurrentApp from "@utils/get-current-app";
import { MAIN_ROUTES } from "@constants/routes";
import APPS from "@constants/app-names";
import QUERY_KEYS from "@constants/query-keys";

export const extractErrors = (error) => {
  let errorMessages = {};
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response;

    if (response) {
      response.data.errors.forEach((error) => {
        errorMessages[error.field] = error.message;
      });
    }
  }
  return errorMessages;
};

export default function httpErrorHandler(error, dispatch, navigator) {
  if (error === null)
    throw new Error("Unrecoverable error!! Error is unknown!");
  if (error?.message === UN_AUTH_ERR_MESSAGE) {
    const redirect = window.location.pathname;
    const app = getCurrentApp();

    if (navigator) {
      navigator(
        APPS[app] === APPS.advertiser
          ? MAIN_ROUTES.BRAND_SIGN_IN.path
          : MAIN_ROUTES.INFLUENCER_SIGN_IN.path,
        APPS.main,
        { [QUERY_KEYS.APP]: app, [QUERY_KEYS.PATH]: redirect }
      );
    } else {
      dispatch(
        addToast(
          TOASTS.error(
            "Session expired! Please login again! Can't automatically redirect to login page!"
          )
        )
      );
    }
    return;
  }
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response;
    console.log("Response: ", response);

    const request = error?.request;

    if (response) {
      if (response.status === 404) {
        //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
        dispatch(
          addToast(TOASTS.error("Cannot get your data due to service is down!"))
        );
        console.error(response.data);
      } else {
        //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
        dispatch(addToast(TOASTS.error(response.data.errors[0].message)));
        console.error(response.data.errors);
      }
    } else if (request) {
      if (error.code === "ERR_NETWORK") {
        dispatch(
          addToast(
            TOASTS.error(
              "Network error! Please check your internet connection and try again."
            )
          )
        );
        console.error(error.message);
      } else {
        dispatch(
          addToast(
            TOASTS.error("We are doing some mantainance! We will be back soon!")
          )
        );
        console.error(
          "Request was sent but no response we recieved. Request: ",
          request
        );
      }
    } else {
      //Something happened in setting up the request and triggered an Error
      dispatch(
        addToast(TOASTS.error("Something went wrong! Please try again later!"))
      );
      console.log(error.message);
    }
  } else {
    console.log("entered!");
    //Something happened in setting up the request and triggered an Error
    dispatch(
      addToast(TOASTS.error("Something went wrong! Please try again later!"))
    );
    console.log(error.message);
  }
}
