import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import useSignUpAdvertiser from "@requests/advertisers/useSignUpAdvertiser";
import useNavigator from "@hooks/useNavigator";
import { ADV_ROUTES } from "@constants/routes";

const FlowBiteLogin = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [industry, setIndustry] = useState("");
  const [designation, setDesignation] = useState("");

  const dispatch = useDispatch();
  const [goTo] = useNavigator();
  
  const signUp = useSignUpAdvertiser({
    onSuccess: (data) => {
      dispatch(addToast(TOASTS.success("You have successfully signed up!")));
      goTo(ADV_ROUTES.DASHBOARD.path);
    },
    onError: (error) => {
      // Handle error here
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const newData = {
      firstName,
      lastName,
      industry,
      designation,
      name: `${firstName} ${lastName}`,
    };
    signUp.mutate(newData);
  };

  return (
    <div className="flex justify-center items-center h-[110vh]">
      <div className="m-4 w-[45rem] p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 className="leading-[35px] mb-10 text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
          Tell us about yourself.
        </h5>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            required
            placeholder="Your First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-[15px] rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />


          <input
              type="text"
              required
              placeholder="Your Last Name"
              value={lastName}
            onChange={(e) => setLastName(e.target.value)}
              aria-describedby="helper-text-explanation"
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-[15px] rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></input>

            <input
              type="text"
              required
              placeholder="Your Industry"
              value={industry}
            onChange={(e) => setIndustry(e.target.value)}
              aria-describedby="helper-text-explanation"
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-[15px] rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></input>

            <input
              type="text"
              required
              placeholder="Your Designation"
              value={designation}
            onChange={(e) => setDesignation(e.target.value)}
              aria-describedby="helper-text-explanation"
              class="mb-8 bg-gray-50 border border-gray-300 text-gray-900 text-[15px] rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></input>
          
          <button
            type="submit"
            className="mt-2 mb-2 focus:outline-none text-white w-full bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default FlowBiteLogin;











