import Main from "@main/pages/Main";
import ErrorPage from "@main/pages/ErrorPage";
import { INSTAGRAM_ROUTES, SHARED_ROUTES } from "@constants/routes";
import { createBrowserRouter } from "react-router-dom";
import SettingsPage from "@main/pages/Settings";
import Dashboard from "./pages/Dashboard";
import IGConnect from "./pages/Connect";
import ValidateIG from "./pages/ValidateIG";
import ProfilePage from "./pages/Profile";

const instagramRouter = createBrowserRouter([
  {
    path: SHARED_ROUTES.MAIN.path,
    element: <Main routes={INSTAGRAM_ROUTES} />,
    errorElement: (
      <ErrorPage
        message="Nothing here! You may want to go to home page"
        buttonText="Sign up instead!"
        link="/"
      />
    ),
    children: [
      {
        path: SHARED_ROUTES.MAIN.path,
        element: <ValidateIG />,
      },
      {
        path: INSTAGRAM_ROUTES.DASHBOARD.path,
        element: <Dashboard />,
      },
      {
        path: INSTAGRAM_ROUTES.COMPLETE_PROFILE.path,
        element: <ProfilePage />,
      },
      {
        path: INSTAGRAM_ROUTES.CONNECT.path,
        element: <IGConnect />,
      },
      {
        path: SHARED_ROUTES.SETTINGS.path,
        element: <SettingsPage />,
      },
    ],
  },
]);

// if you want to add a page to this module please add to the upper array in such a way:

// {
//   path: ADMIN_ROUTES.SETTINGS.path,
//   element: <SettingsPage />,
// },

export default instagramRouter;
