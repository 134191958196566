//Typography
export const TITLE = "text-3xl font-bold";
export const SUBTITLE = "text-2xl font-medium";
export const PARAGRAPH = "text-base font-light";
export const BUTTON_TXT = `${PARAGRAPH} font-semibold`;
export const FONT_SEMIBOLD = "font-semibold";
export const FONT_BOLD = "font-bold";
export const FONT_NORMAL = "font-normal";
export const FONT_LIGHT = "font-light";

export const getLoadingText = (width, height, color = "black") =>
  `${width} ${height} rounded-md animate-pulse bg-${color}`;

export const TEXT_SIZES = {
  BASE: "base",
  SMALL: "sm",
  X_SMALL: "xs",
  MEDIUM: "md",
  LARGE: "lg",
  X_LARGE: "xl",
  X_LARGE_2: "2xl",
  X_LARGE_3: "3xl",
  X_LARGE_4: "4xl",
  X_LARGE_5: "5xl",
};

export const FONT_WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold",
  SEMI_BOLD: "semibold",
  EXTRA_BOLD: "extrabold",
  THIN: "thin",
  LIGHT: "light",
};

export const getTextClassName = ({
  isLoading = false,
  loadingColor = "black",
  color = "black",
  width = "w-full",
  height = "h-10",
  textSize = {
    desktop: "base",
    mobile: "base",
  },
  fontWeight = {
    desktop: "normal",
    mobile: "normal",
  },
}) =>
  isLoading
    ? getLoadingText(width, height, loadingColor)
    : `text-${textSize.mobile ?? textSize.desktop} font-${
        fontWeight.mobile ?? fontWeight.desktop
      } md:text-${textSize.desktop} text-${color} md:font-${
        fontWeight.desktop
      }`;

//Colors
export const DARK_COLOR = "black";
export const DARK_SECONDARY_COLOR = "slate-900";
export const DARK_TETARY_COLOR = "slate-700";
export const LIGHT_COLOR = "white";
export const LIGHT_SECONDARY_COLOR = "gray-100";
export const LIGHT_TETARY_COLOR = "gray-300";
export const LINKEDIN_COLOR = "linkedin";
export const ACCENT_COLOR = "accent";

//RAW COLORS
export const COLORS_RAW = {
  DARK_COLOR: "#000",
  DARK_SECONDARY_COLOR: "#0f172a",
  DARK_TETARY_COLOR: "#334155",
  LIGHT_COLOR: "#fff",
  LIGHT_SECONDARY_COLOR: "#f3f4f6",
  LIGHT_TETARY_COLOR: "#d1d5db",
  LINKEDIN_COLOR: "#0077B5",
  ACCENT_COLOR: "#00BFA6",
};
export const STATUS_COLORS = {
  warning: "#E4C988",
  success: "#609EA2",
  toastError: "#850000",
  error: "#FF3333",
};

//Elements
export const getFlex = (isCol, isHorCenter, isVerCenter, isNoPadding = false) =>
  `flex gap-4 ${isNoPadding ? "" : "p-5"} ${isCol && "flex-col"} ${
    isHorCenter && "items-center"
  } ${isVerCenter && "justify-center"} `;
export const getContainer = (isSmall) =>
  isSmall ? "w-96 md:w-1/2 lg:w-3/5 " : "container ";
export const BUTTON =
  "w-4/5 py-3 px-5 rounded-md md:w-72 self-center flex items-center justify-start gap-2 hover:cursor-pointer hover:shadow-xl transition-all transition disabled:bg-gray-200 disabled:opacity-60";
export const BUTTON_LOADING =
  "md:w-40 h-12 rounded-md animate-pulse self-stretch md:self-center";

export const getPage = (_, isHorCenter, isVerCenter, isScrollable = false) =>
  `bg-purple-50 ${getFlex(true, isHorCenter, isVerCenter)} ${
    isScrollable ? "overflow-y-auto" : "overflow-hidden"
  } md:p-10 min-w-screen min-h-screen bg-transparent overflow-x-hidden`;

export const getFragment = (_, isHorCenter, isVerCenter) =>
  `${getFlex(
    true,
    isHorCenter,
    isVerCenter,
    true
  )} min-w-screen min-h-screen bg-transparent overflow-x-hidden h-max`;

export const getCard = (color, isSmall, isHorCenter, isVerCenter) =>
  `${isSmall ? "w-96 md:w-1/2 lg:w-3/5" : "container"} rounded-md ${getFlex(
    true,
    isHorCenter,
    isVerCenter
  )} bg-${color} `;
