import React from "react";

const InputField = React.forwardRef(
  ({ label, type = "text", rootClass, className, error, ...field }, ref) => {
    return (
      <div className={`${rootClass}`}>
        <label
          htmlFor={field.name}
          className="block mb-2 text-2xl font-medium text-gray-900"
        >
          {label}
        </label>
        <input
          id={field.name}
          type={type}
          className={`bg-white text-gray-900 text-xl rounded-xl focus:border focus:ring-purple-500 focus:border-purple-500 block w-full p-4 ${className} ${
            error ? "border border-red-500" : "border-2 border-gray-300"
          }`}
          {...field}
        />
        {error && (
          <p className="block mb-2 text-lg font-medium text-red-500">
            {error.message}
          </p>
        )}
      </div>
    );
  }
);

export default InputField;
