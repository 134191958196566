import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
// import { verifyAccount } from "@api/auth/auth";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import errorIcon from "@assets/ic-error.png";
import useNavigator from "@hooks/useNavigator";
import COOKIES_KEYS from "@constants/cookie-keys";
import APPS, { DEFAULT_DOMAIN } from "@constants/app-names";
import QUERY_KEYS from "@constants/query-keys";
import { useCookies } from "react-cookie";
import { MAIN_ROUTES, SHARED_ROUTES } from "@constants/routes";
import useVerifyGoogle from "@requests/auth/useVerifyGoogle";
import useVerifyLinkedin from "@requests/auth/useVerifyLinkedin";

const LinkedIn = () => {
  const location = useLocation();
  const [goto] = useNavigator();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);

  const [code, setCode] = useState(searchParams.get("code"));
  const [persona, setPersona] = useState(searchParams.get("state"));
  const [_, setCookie] = useCookies([COOKIES_KEYS.TOKEN]);

  const [isLoading, setIsLoading] = useState(true);
  const [activationErrMsg, setActivationErrMsg] = useState("");
  const [isActivationSuccess, setIsActivationSuccess] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [remainingTime, setRemainingTime] = useState(3);

  const startTimer = useCallback(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleRedirect = () => {
    if (searchParams.get(QUERY_KEYS.APP) && searchParams.get(QUERY_KEYS.PATH)) {
      goto(
        searchParams.get(QUERY_KEYS.PATH),
        APPS[searchParams.get(QUERY_KEYS.APP)]
      );
    } else if (persona === "brand") {
      goto(SHARED_ROUTES.MAIN.path, APPS.advertiser);
    } else {
      goto(MAIN_ROUTES.SOCIAL_MEDIA_ACCS.path, APPS.main);
    }
  };
  const handleFailureRedirect = () => {
    if (persona === "brand") {
      goto(MAIN_ROUTES.BRAND_SIGN_IN.path);
    } else {
      goto(MAIN_ROUTES.INFLUENCER_SIGN_IN.path);
    }
  };

  const continueVerification = useVerifyLinkedin({
    onSuccess(data) {
      setIsLoading(false);
      setIsRedirecting(true);
      setIsActivationSuccess(true);
      if (data && data.userData && data.userData.token) {
        setCookie(COOKIES_KEYS.TOKEN, data.userData.token, {
          path: "/",
          domain: process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN,
        });
      }
      startTimer();
    },
    onError(err) {
      startTimer();
      setIsLoading(false);
      setIsRedirecting(true);
      setIsActivationSuccess(false);

      if (
        err.response.status &&
        err.response.status === 400 &&
        err.response.data.message
      ) {
        setActivationErrMsg(err.response.data.message);
        dispatch(addToast(TOASTS.error(err.response.data.message)));
      } else {
        setActivationErrMsg(err.response.data.message);
        dispatch(
          addToast(
            TOASTS.error(
              "Something went wrong at our end. Please check back later"
            )
          )
        );
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    setIsRedirecting(false);
    continueVerification.mutate({ code: code, source: persona });
  }, []);

  if (remainingTime === 0) {
    if (isActivationSuccess) {
      handleRedirect();
    } else {
      handleFailureRedirect();
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-center items-center h-screen">
        {isLoading && <ClipLoader color="#004EAD" size={150} />}

        {!isLoading && !isActivationSuccess && (
          <img
            src={errorIcon}
            alt="Error"
            className="h-60 w-60 rounded-full object-cover"
          />
        )}

        <br />

        {isLoading && !isRedirecting && <p>Verifying your account...</p>}

        {isRedirecting && isActivationSuccess && (
          <>
            <div className="m-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="green"
                className="w-48 h-48 m-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
            </div>

            <p className="text-xl">Verification successful..</p>

            <p className="text-xl">
              Redirecting you to the dashboard in {remainingTime} seconds...
            </p>
          </>
        )}

        {isRedirecting && !isActivationSuccess && (
          <>
            <p className="text-xl">{activationErrMsg}</p>

            <p className="text-xl">
              Redirecting you to the login page in {remainingTime} seconds...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default LinkedIn;
