import React, { useCallback } from "react";
import "./Home.css";
import banner from "@assets/mobile-app.jpg";
import useNavigator from "@hooks/useNavigator";
import useAppSetting from "@hooks/useAppSetting";
import useMediaQuery from "@hooks/useMediaQuery";
import { MAIN_ROUTES } from "@constants/routes";

const Home = () => {
  const [goTo] = useNavigator();
  const [dark, isMobile] = useAppSetting();
  // const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const influencerSigninClick = useCallback(() => {
    goTo(MAIN_ROUTES.INFLUENCER_SIGN_IN.path);
  }, [goTo]);

  const brandSigninClick = useCallback(() => {
    goTo(MAIN_ROUTES.BRAND_SIGN_IN.path);
  }, [goTo]);

  return (
    <section class="bg-white dark:bg-gray-900">
    <div class="grid max-w-screen-xl px-4 pt-32 mx-auto lg:gap-12 xl:gap-0 lg:pt-20 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7 xl:col-span-8">
            <h1 class="max-w-2xl mb-4 text-6xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Amplify Influence. <br/> Multiply Your Reach</h1>
            <div class="flex flex-col justify-center">
            <p class="max-w-2xl mb-6 font-dark text-gray-500 lg:mb-8 md:text-2xl lg:text-2xl dark:text-gray-400">Where authenticity meets collaboration, sparking digital growth. Your journey to impactful connections starts here.</p>
            <div class="flex flex-wrap gap-4 justify-center">
            <button onClick={influencerSigninClick} type="button" class="w-[22rem] h-[4rem] p-4 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-large rounded-full text-xl px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Get Started as an Influencer</button>
            <button onClick={brandSigninClick} type="button" class="w-[22rem] h-[4rem] p-4 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-large rounded-full text-xl px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Get Started as Brand</button>
            </div>
            </div>
        </div>
        <div class="hidden lg:mt-0 lg:col-span-5 xl:col-span-4 lg:flex">
            <img src={banner} alt="phone illustration"></img>
        </div>                
    </div>
</section>
  );
};

export default Home;
