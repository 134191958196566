import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { useState } from "react";
import { Button, Label, Textarea } from "flowbite-react";

const PubCompletionModal = ({
  isOpen,
  application,
  onClose,
  onRefetch,
  onAdvAction,
}) => {
  const [showResubmissionNote, setShowResubmissionNote] = useState(false);
  const [note, setNote] = useState("");

  const files = application.statusMessage?.file_keys;
  const links = application.statusMessage?.links;

  const handleClose = () => {
    onClose();
  };

  const handleOpenNotesOnAskResubmission = () => {
    setShowResubmissionNote(true);
  };

  const handleAskResubmission = () => {
    onAdvAction(application.id, "APPROVE", note);
    onClose();
  };

  const handleAcceptSubmittion = () => {
    onAdvAction(application.id, "COMPLETED_APPROVED");
    onClose();
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
    content: {
      zIndex: 100,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Campaign Completion"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="w-[70vw] sm:w-[50vw]  max-h-[70vh]">
        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t mb-8">
          <h3 className="text-3xl font-semibold">Fill Completion details</h3>
          <button
            type="button"
            onClick={handleClose}
            className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-12 h-12 ml-auto inline-flex justify-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
          >
            <IoClose className="w-8 h-8" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="w-full flex flex-col">
          {links?.length > 0 && (
            <div>
              <div className="text-2xl font-semibold py-4">Links</div>
              {links?.map((link, index) => (
                <ol>
                  <li key={index} className="w-full flex">
                    <div className="grow">
                      {index + 1}.<a href={link}> {link}</a>
                    </div>
                  </li>
                </ol>
              ))}
            </div>
          )}

          {files?.length > 0 && (
            <div className="py-4">
              <div className="text-2xl font-semibold">Media</div>
              <div className="flex flex-wrap gap-8 mt-8">
                {files.map((file, index) => (
                  <div key={index} className="relative">
                    <img src={file} alt="sample" className="h-72" />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="w-full">
            <div className={showResubmissionNote ? "hidden" : "ml-auto"}>
              <div className="flex flex-row gap-4 justify-end">
                <Button
                  color="purple"
                  size="lg"
                  onClick={handleOpenNotesOnAskResubmission}
                  outline={true}
                >
                  <span className="text-lg font-semibold">
                    Ask for resubmission
                  </span>
                </Button>

                <Button
                  color="purple"
                  size="lg"
                  onClick={handleAcceptSubmittion}
                  outline={true}
                >
                  <span className="text-lg font-semibold">
                    Accept submission
                  </span>
                </Button>
              </div>
            </div>

            <div className={!showResubmissionNote ? "hidden" : "w-full"}>
              <div
                className="w-full border border-gray-400 rounded-lg"
                id="textarea"
              >
                <div className="m-2 block">
                  <Label className="text-xl font-semibold" htmlFor="comment">
                    Add a note
                  </Label>
                </div>
                <Textarea
                  id="comment"
                  placeholder="Please let the influencer know the reason for the resubmission"
                  required
                  rows={4}
                  className="rounded-none"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <div className="flex flex-row m-4">
                  <div className="flex flex-row ml-auto">
                    <Button
                      className="w-full mr-2"
                      color="purple"
                      size="lg"
                      onClick={() => setShowResubmissionNote(false)}
                      outline
                    >
                      Cancel
                    </Button>

                    <Button
                      className="w-full"
                      color="purple"
                      size="lg"
                      onClick={handleAskResubmission}
                      outline
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PubCompletionModal;
