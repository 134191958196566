import React, { useState } from "react";
import { Button, Card, TextField } from "ui-neumorphism";
import Select from "react-select";
import designation from "@utils/lists/designation";
import industries from "@utils/lists/industries";


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "white" : "",
    borderRadius: "28px",
    border: state.isFocused ? "2px solid #D8D8D8" : "1px solid #D8D8D8",
    boxShadow: state.isFocused ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
    transition: "border-color 0.3s ease",
    margin: "10px",
    width: "27 0px",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#E5E5E5",
    borderRadius: "8px",
    color: "#333333",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#333333",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333333",
    ":hover": {
      backgroundColor: "#E5E5E5",
      color: "#333333",
    },
  }),
};

const PromoteForm = () => {
  const [state, setState] = useState(1);
  return (
    <>
      {state === 1 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="post"
        >
          <div>Linkedin Post URL</div>
          <TextField
            label="Paste Link Here.."
            // value={linkedinPostUrl}
            // onChange={handleChange}
            className="my-3"
            rounded
          />

          <div>Targeted Designations</div>
          <Select
            isMulti
            name="designation"
            placeholder="Select upto 5 Designations"
            options={designation}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles} 
          />

          <div>Targeted Industries</div>
          <Select
            isMulti
            name="designation"
            placeholder="Select upto 5 Industries"
            options={industries}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles} 
          />

          <div>Budget(USD)</div>
          <TextField label="Enter Budget Here.." rounded className="my-3" />

          <div>You will get minimum xxxx engagements.</div>

          <div
            className="form__btns"
            style={{
              display: "flex",
              padding: "10px",
              justifyContent: "center",
            }}
          >
            <Button type="submit" style={{ marginRight: "10px" }}>
              Back
            </Button>
            <Button type="submit">Promote</Button>
          </div>
        </form>
      )}
    </>
  );
};

export default PromoteForm;
