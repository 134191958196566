import { useDispatch } from "react-redux";
import { setToken } from "@store/slices/rootSlice";
import { setAdmin } from "@store/slices/adminSlice";
import { MAIN_ROUTES } from "@constants/routes";
import useNavigator from "./useNavigator";
import APPS from "@constants/app-names";

export default function () {
  const dispatch = useDispatch();
  const [goTo] = useNavigator();
  const signOut = () => {
    dispatch(setToken(null));
    dispatch(setAdmin(null));
    goTo(MAIN_ROUTES.LANDING.path, APPS.main);
  };
  return signOut;
}
