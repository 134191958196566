import React from 'react'
import strength from "@assets/strength.png"
import grow from "@assets/grow.png"
import trust from "@assets/trust.png"
import brandImg from "@assets/BrandImg.jpg"

const BrandSection = () => {
  return (
    <section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white">Why Brands turns to Influcracy</h2>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-10 h-10 text-primary-600 lg:w-10 lg:h-10 dark:text-primary-300" src={strength} alt="" />
              </div>
              <h3 class="mb-2 text-3xl font-bold dark:text-white">Strategic Influencer Collaborations</h3>
              <p class="text-gray-500 dark:text-gray-400">Ignite brand success through powerful influencer partnerships. Influcracy offers amplified reach, connecting your brand with authentic voices for impactful campaigns.
</p>
          </div>
          <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-10 h-10 text-primary-600 lg:w-10 lg:h-10 dark:text-primary-300" src={grow} alt="" />
              </div>
              <h3 class="mb-2 text-3xl font-bold dark:text-white">Unlock Brand Potential</h3>
              <p class="text-gray-500 dark:text-gray-400">Discover new horizons, achieve impactful results. Partner with us to tap into your brand's full potential, navigating the dynamic landscape of influencer marketing with expert guidance.
</p>
          </div>
          <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-10 h-10 text-primary-600 lg:w-10 lg:h-10 dark:text-primary-300" src={trust} alt="" />
              </div>
              <h3 class="mb-2 text-3xl font-bold dark:text-white">Nurturing Trust and Impact</h3>
              <p class="text-gray-500 dark:text-gray-400">Build trust, establish authority. Leverage structured workflows and custom permissions to safeguard your brand while maximizing influence. Influcracy: Where brand growth meets authenticity.</p>
          </div>
          
      </div>
  </div>
</section>
  )
}

export default BrandSection