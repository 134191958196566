import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import registerEmail from "@api/auth/register-email";

export default function useRegisterEmail(
  options
){
  return useMutation({
    mutationFn: registerEmail,
    mutationKey: REACT_QUERY_KEYS.REGISTER_EMAIL,
    ...options
  }
  );
};