import React, { useCallback } from "react";
import "./Services.css";
import influencer from "@assets/influPic.jpg";
import brand from "@assets/brand.svg";
import useNavigator from "@hooks/useNavigator";

const Services = () => {
  const [navigate] = useNavigator();

  const influencerClick = useCallback(() => {
    navigate("/influencer");
  }, [navigate]);

  const brandClick = useCallback(() => {
    navigate("/brand");
  }, [navigate]);

  return (
    <>
    <section class="bg-white dark:bg-gray-900">
    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img class="w-full  dark:hidden" src={influencer} alt="dashboard image"></img>
        {/* <img class="w-full hidden dark:block" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup-dark.svg" alt="dashboard image"></img> */}
        <div class="mt-4 md:mt-0">
            <h1 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white">Elevate Your Influence: Unleash Exclusive Collaborations.</h1>
            <div class="flex flex-col items-center">
            <p class="mb-6 text-dark text-gray-500 md:text-2xl dark:text-gray-400">Influcracy empowers influencers with access to exclusive brand collaborations, expanded reach, and expert support. Join our platform to elevate your influencer career, unlock exciting opportunities, and maximize your potential in the ever-evolving world of influencer marketing.</p>
            <button onClick={influencerClick} type="button" class="w-[22rem] h-[4rem] p-4 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-large rounded-full text-xl px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Explore More</button>
            </div>
        </div>
    </div>
</section>


<section class="bg-white dark:bg-gray-900">
    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img class="w-full md:w-auto dark:hidden" src={brand} alt="dashboard image"></img>
        <div class="md:order-first mt-4 md:mt-0 md:ml-8">
            <h1 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white">Ignite Success Through Strategic Influencer Partnerships.</h1>
            <div class="flex flex-col items-center">
            <p class="mb-6 text-dark text-gray-500 md:text-2xl dark:text-gray-400">Influcracy empowers brands with strategic influencer collaborations, amplified reach, and expert guidance. Partner with us to unlock your brand's full potential, discover new opportunities, and achieve impactful results in the dynamic landscape of influencer marketing.</p>
            <button onClick={brandClick} type="button" class="w-[22rem] h-[4rem] p-4 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-large rounded-full text-xl px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Explore More</button>
            </div>
        </div>
    </div>
</section>

</>
  );
};

export default Services;
