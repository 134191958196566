import InfluencerCampaignCard from "@components/CampaignCardInfluencer";
import Loader from "@components/Loader";
import apiClient from "@utils/apiClient";
import React, { useCallback, useEffect, useState } from "react";

const TKMyCampaigns = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = useCallback(() => {
    setIsLoading(true);

    apiClient
      .get(`/api/v1/tk/campaigns?filter_by=MY_CAMPAIGNS`)
      .then(({ data }) => {
        setCampaigns(data.campaigns);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      {campaigns && campaigns.length
        ? campaigns.map((campaign) => (
            <InfluencerCampaignCard
              key={campaign.id}
              campaign={campaign}
              onRefetch={fetchCampaigns}
              type="tiktok"
            />
          ))
        : null}

      {campaigns && !campaigns.length && <div>No new campaigns found</div>}
    </>
  );
};

export default TKMyCampaigns;
