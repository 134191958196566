import BrandHome from '@main/fragments/BrandHome/BrandHome'
import BrandSection from '@main/fragments/BrandSection/BrandSection'
import Contact from '@main/fragments/Contact/Contact'
import Footer from '@main/fragments/Footer/Footer'
import Header from '@main/fragments/Header/Header'
import React from 'react'
import { Helmet } from 'react-helmet'

const Brand = () => {
  return (
    <>
    <Helmet>
        <title>Drive Impactful Campaigns with Influcracy's Influencer Network</title>
        <meta
          name="description"
          content="Leverage Influcracy's diverse network of influential voices to strengthen your brand's reputation, grow your audience, and build trust. Sign in and partner with us for successful influencer collaborations."
        />
        <meta name="keywords" content="Influcracy.com, influencer marketing services, brand collaborations, marketing campaigns, digital advertising" />
      </Helmet>
    <Header/>
    <BrandHome/>
    <BrandSection/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default Brand