import CampaignsForIg from "@components/CampaignCardInfluencer";
import Loader from "@components/Loader";
import apiClient from "@utils/apiClient";
import React, { useCallback, useEffect, useState } from "react";

const NewCampaignsIgInfluencers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = useCallback(() => {
    setIsLoading(true);

    apiClient
      .get(`/api/v1/ig/campaigns`)
      .then(({ data }) => {
        setCampaigns(data.campaigns);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      {campaigns &&
        campaigns.map((campaign) => (
          <CampaignsForIg
            key={campaign.id}
            campaign={campaign}
            onRefetch={() => {
              fetchCampaigns();
            }}
          />
        ))}

      {campaigns && !campaigns.length && <div>No new campaigns found</div>}
    </>
  );
};

export default NewCampaignsIgInfluencers;
