export const INSTAGRAM_ROUTES = {
  DASHBOARD: {
    path: "/dashboard",
  },
  CONNECT: {
    path: "/connect",
  },
  COMPLETE_PROFILE: {
    path: "/complete-profile",
  },
};

export const LINKEDIN_ROUTES = {
  DASHBOARD: {
    path: "/dashboard",
  },
};
export const ADV_ROUTES = {
  DASHBOARD: {
    path: "/dashboard",
  },
  CREATE_CAMPAIGN: {
    path: "/campaign/create",
  },
  APPLICATIONS: {
    path: "/campaign/:campaign_id/applications",
  },
  SIGN_UP: {
    path: "/signup",
  },
};

export const ADMIN_ROUTES = {
  DASHBOARD: {
    path: "/dashboard",
  },
  CREATE_CAMPAIGN: {
    path: "/campaigns/create",
  },
  APPLICATIONS: {
    path: "/campaign/:campaign_id/applications",
  },
  MAIN: {
    path: "/",
  },
};

export const MAIN_ROUTES = {
  INFLUENCER_SIGN_IN: {
    path: "/influencerSignin",
  },
  BRAND_SIGN_IN: {
    path: "/brandSignin",
  },
  USER_VERIFICATION: {
    path: "/verify",
  },
  GOOGLE_LOGIN: {
    path: "/googleLogin",
  },
  LINKEDIN: {
    path: "/linkedin",
  },
  FACEBOOK: {
    path: "/facebook",
  },
  SOCIAL_MEDIA_ACCS: {
    path: "/social-connect",
  },
  LANDING: {
    path: "/landing",
  },
  INFLUENCER: {
    path: "/influencer",
  },
  BRAND: {
    path: "/brand",
  },
  ABOUT: {
    path: "/about",
  },
  TOU: {
    path: "/terms-of-use",
  },
  PRIVACY: {
    path: "/privacy-policy",
  },
  // GOOGLE_LOGIN: {
  //   path: "/googleLogin",
  // },
};

export const SHARED_ROUTES = {
  MAIN: {
    path: "/",
  },
  SETTINGS: {
    path: "/settings",
  },
  DASHBOARD: {
    path: "/dashboard",
  },
};
