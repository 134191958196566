import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import errorIcon from "@assets/ic-error.png";
import useNavigator from "@hooks/useNavigator";
import { SHARED_ROUTES } from "@constants/routes";
import apiClient from "@utils/apiClient";

const TKConnect = () => {
  const location = useLocation();
  const [goto] = useNavigator();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);

  const [code, setCode] = useState(searchParams.get("code"));

  const [isLoading, setIsLoading] = useState(true);
  const [activationErrMsg, setActivationErrMsg] = useState("");
  const [isActivationSuccess, setIsActivationSuccess] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [remainingTime, setRemainingTime] = useState(3);

  const startTimer = useCallback(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleRedirect = () => {
    goto(SHARED_ROUTES.MAIN.path);
  };

  const saveInfo = useCallback((code) => {
    apiClient
      .post("/api/v1/tk/me", {
        code: code,
      })
      .then((response) => {
        setIsActivationSuccess(true);
      })
      .catch((err) => {
        setIsActivationSuccess(false);

        if (
          err.response.status &&
          err.response.status === 400 &&
          err.response.data.message
        ) {
          setActivationErrMsg(err.response.data.message);
          dispatch(addToast(TOASTS.error(err.response.data.message)));
        } else {
          setActivationErrMsg(err.response.data.message);
          dispatch(
            addToast(
              TOASTS.error(
                "Something went wrong at our end. Please check back later"
              )
            )
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsRedirecting(true);
        startTimer();
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsRedirecting(false);

    saveInfo(code);
  }, []);

  if (remainingTime === 0) {
    handleRedirect();
  }

  return (
    <div>
      <div className="flex flex-col justify-center items-center h-screen">
        {isLoading && <ClipLoader color="#004EAD" size={150} />}

        {!isLoading && !isActivationSuccess && (
          <img
            src={errorIcon}
            alt="Error"
            className="h-60 w-60 rounded-full object-cover"
          />
        )}

        <br />

        {isLoading && !isRedirecting && <p>Connecting your tiktok...</p>}

        {isRedirecting && isActivationSuccess && (
          <>
            <div className="m-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="green"
                className="w-48 h-48 m-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
            </div>

            <p className="text-xl">Verification successful..</p>

            <p className="text-xl">
              Redirecting you to the dashboard in {remainingTime} seconds...
            </p>
          </>
        )}

        {isRedirecting && !isActivationSuccess && (
          <>
            <p className="text-xl">{activationErrMsg}</p>

            <p className="text-xl">
              Redirecting you to the login page in {remainingTime} seconds...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default TKConnect;
