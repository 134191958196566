import FlowBiteHeader from "@components/Flowbite/Header";
import SelectField from "@components/FormInput/SelectField";
import Loader from "@components/Loader";
import useNavigator from "@hooks/useNavigator";
import { getPage } from "@utils/tailwind.constants";
import { useState } from "react";
import regions from "@utils/countries.json";
import contact_extensions_json from "@utils/contact-extensions.json";
import { Dropdown, Item, ItemHead } from "@components/FormInput/Dropdown";
import { Button } from "flowbite-react";
import { DropdownWithInputField } from "@components/FormInput/DropdownWithInputField";
// import DatePicker from "@components/DatePicker";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { SelectFieldWithCheckbox } from "@components/FormInput/SelectFieldWithCheckbox";
import apiClient from "@utils/apiClient";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import { useDispatch, useSelector } from "react-redux";

// react page sample bootstrap
const ProfilePage = () => {
  const [navigate] = useNavigator();
  const dispatch = useDispatch();

  const igData = useSelector((state) => state.igData);

  const currentDate = new Date();

  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [dob, setDOB] = useState(
    currentDate.setFullYear(currentDate.getFullYear() - 18)
  );
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [otherGender, setOtherGender] = useState("");
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [secondaryCategories, setSecondaryCategory] = useState([]);
  const [contact, setContact] = useState("");
  const [contactExtension, setContactExtension] = useState("+1");
  const [errors, setErrors] = useState({});

  const contract_extensions = contact_extensions_json.codes;

  const skipInfo = () => {
    navigate("/");
  };

  const validateContactNumber = (contact) => {
    if (contact.length === 0) {
      return false;
    } else if (contact.length !== 10) {
      return false;
    }

    return true;
  };

  const saveInfo = async () => {
    setIsLoading(true);

    if (gender === "") {
      setErrors({
        ...errors,
        gender: "Please select a gender",
      });
      setIsLoading(false);
      return;
    }

    if (gender === "other" && otherGender === "") {
      setErrors({
        ...errors,
        gender: "Please specify your gender",
      });
      setIsLoading(false);
      return;
    }

    if (!validateContactNumber(contact)) {
      setErrors({
        ...errors,
        contact: "Please enter a valid contact number",
      });

      setIsLoading(false);
      return;
    }

    const payload = {
      name,
      location,
      gender: otherGender === "" ? gender : otherGender,
      primaryCategory,
      secondaryCategories,
      contact,
      contactExtension,
    };

    await apiClient
      .post("/api/v1/ig/me/info", payload)
      .then(({ data }) => {
        dispatch(addToast(TOASTS.success("Profile info updated successfully")));
        navigate("/");
      })
      .catch((err) => {
        dispatch(addToast(TOASTS.error("Profile info updation failed")));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const content_categories = [
    "Fashion and Style",
    "Beauty and Makeup",
    "Fitness and Health",
    "Travel and Adventure",
    "Food and Cooking",
    "Lifestyle",
    "Parenting and Family",
    "Photography and Art",
    "Technology and Gadgets",
    "Gaming",
    "Pets and Animals",
    "DIY and Crafts",
    "Home and Interior Design",
    "Books and Literature",
    "Music and Entertainment",
    "Comedy and Memes",
    "Business and Entrepreneurship",
    "Motivation and Self-Improvement",
    "Environmental and Sustainability",
    "Social Justice and Activism",
    "Science and Education",
    "Cars and Automotive",
    "Fitness Equipment and Supplements",
    "Fandom and Fan Culture",
    "History and Heritage",
    "Outdoor and Adventure Sports",
    "Relationships and Dating",
    "Mental Health and Wellness",
    "Hobbies and Interests",
    "Niche Products and Reviews",
    "Eco-Friendly Living",
    "Travel Photography",
  ];

  const handleCountriesChange = (id) => {
    const isAlreadyExist = secondaryCategories.includes(id);

    if (isAlreadyExist) {
      setSecondaryCategory(secondaryCategories.filter((cat) => cat !== id));
    } else {
      setSecondaryCategory([...secondaryCategories, id]);
    }
  };

  return (
    <>
      <FlowBiteHeader />
      <div
        className={`${getPage("", false, false, true)} max-w-6xl mx-auto mb-4`}
      >
        {isLoading && <Loader />}

        <p className="text-4xl font-semibold mx-auto mb-8">
          Complete your profile
        </p>

        <div className="w-full flex gap-8 justify-around">
          <div className="w-[40%]">
            <p className="mr-4 font-semibold text-right">Full Name:</p>
          </div>

          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your full name"
            className={`w-[60%] bg-white border border-gray-300 text-gray-900 text-xl rounded-lg focus:border focus:ring-purple-500 focus:border-purple-500 block`}
          />
        </div>

        <div className="w-full flex gap-8 justify-around">
          <div className="w-[40%]">
            <p className="mr-4 font-semibold text-right">Date of Birth:</p>
          </div>

          <div className="w-[60%]">
            <DatePicker
              selected={dob}
              onChange={(d) => {
                setDOB(d);
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              maxDate={new Date("01-01-2013")}
              customInput={
                <input
                  type="text"
                  className="block w-full p-2 text-lg text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  id={dob}
                  placeholder={dob}
                />
              }
            />
          </div>
        </div>

        <div className="w-full flex gap-8 justify-around">
          <div className="w-[40%]">
            <p className="mr-4 font-semibold text-right">Gender:</p>
          </div>

          <div className="w-[60%]">
            <div className="grid grid-cols-10 gap-4 items-center">
              <SelectField
                className={"col-span-4"}
                onSelect={(d) => {
                  setGender(d);
                  setErrors({
                    ...errors,
                    gender: "",
                  });
                }}
              >
                <option value="" disabled selected>
                  Select your gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </SelectField>

              {gender === "other" && (
                <input
                  type="text"
                  value={otherGender}
                  onChange={(e) => {
                    setOtherGender(e.target.value);
                    setErrors({
                      ...errors,
                      gender: "",
                    });
                  }}
                  placeholder=""
                  className={`col-span-6 bg-white border border-gray-300 text-gray-900 text-xl rounded-lg focus:border focus:ring-purple-500 focus:border-purple-500 block`}
                />
              )}
            </div>

            {errors.gender && (
              <p className="text-lg text-red-500 text-left">{errors.gender}</p>
            )}
          </div>
        </div>

        <div className="flex  gap-8 justify-around">
          <div className="w-[40%]">
            <p className="mr-4 font-semibold text-right">Location:</p>
          </div>
          <div className="w-[60%]">
            <Dropdown
              selected={location}
              onSelect={(d) => setLocation(d)}
              showSearch={true}
              label="Select Your Location"
              data={regions}
            >
              {Object.keys(regions).map((region) => (
                <ItemHead id={region} label={region}>
                  {regions[region].map((country) => {
                    return <Item id={country} />;
                  })}
                </ItemHead>
              ))}
            </Dropdown>
          </div>
        </div>

        <div className="w-full flex gap-8 justify-around">
          <div className="w-[40%]">
            <p className="mr-4 font-semibold text-right">
              Contact Information (Phone):
            </p>
          </div>

          <div className="w-[60%]">
            <DropdownWithInputField
              onSelect={(d) => setContactExtension(d)}
              showSearch={true}
              label={contactExtension}
              searchPlaceholder="Search Country"
              data={contract_extensions}
              inputProps={{
                placeholder: "Phone Number",
                type: "number",
                min: 10,
                max: 10,
                onChange: (e) => {
                  setContact(e.target.value);
                  setErrors({
                    ...errors,
                    contact: validateContactNumber(e.target.value),
                  });
                },
              }}
            />

            {errors.contact && (
              <p className="text-lg text-red-500 text-center">
                {errors.contact}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-8 mb-4">
          <div className="flex flex-col gap-y-4 my-4">
            {/* <p className="font-semibold ml-8">Your Content Category Niche</p> */}

            <div className="flex gap-8 justify-around">
              <div className="w-[40%]">
                <p className="mr-4 font-semibold text-right">
                  Primary Category Niche:
                </p>
              </div>
              <div className="w-[60%]">
                <Dropdown
                  selected={primaryCategory}
                  onSelect={(d) => setPrimaryCategory(d)}
                  showSearch={true}
                  label="Select Your Primary Category Niche"
                  searchPlaceholder="Search Category"
                  data={content_categories}
                />
              </div>
            </div>

            <div className="flex gap-8 justify-around">
              <div className="w-[40%]">
                <p className="mr-4 font-semibold text-right">
                  Secondary Category Niche:
                </p>
              </div>
              <div className="w-[60%]">
                <SelectFieldWithCheckbox
                  limit={secondaryCategories.length >= 3 ? true : false}
                  selecteds={secondaryCategories}
                  onSelect={handleCountriesChange}
                  showSearch={true}
                  label={
                    secondaryCategories.join(", ") !== ""
                      ? secondaryCategories.join(", ")
                      : "Select Your Secondary Category Niche (upto 3)"
                  }
                  searchPlaceholder="Search Category"
                  data={content_categories}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 my-8  justify-end">
          <Button size="lg" color="purple" outline={true} onClick={skipInfo}>
            Skip
          </Button>
          <Button size="lg" color="purple" onClick={saveInfo}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
