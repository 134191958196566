import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import signUpAdvertiser from "@api/advertisers/signup";

export default function useSignUpAdvertiser(
  options
){
  return useMutation({
    mutationFn: signUpAdvertiser,
    mutationKey: REACT_QUERY_KEYS.SIGN_UP_ADV,
    ...options,
  }
  );
};