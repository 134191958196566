import APPS, { DEFAULT_DOMAIN } from "@constants/app-names";
import { ADMIN_ROUTES } from "@constants/routes";
import { addToast, setCurrentUser } from "@store/slices/rootSlice";
import apiClient from "@utils/apiClient";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router";
import TOASTS from "@constants/toasts";

const RootPage = () => {
  const [error, setError] = useState(false);
  const { currentUser } = useSelector((state) => state.root);
  const dispatch = useDispatch();
  const goto = useNavigate();

  const fetchUserInfo = useCallback(() => {
    apiClient
      .get("/api/v1/common/auth")
      .then(async ({ data }) => {
        const { userData } = data;

        if (userData) {
          dispatch(setCurrentUser(userData));

          if (userData.isAdmin) {
            goto(ADMIN_ROUTES.DASHBOARD.path, APPS.admin);
          } else {
            dispatch(
              addToast(
                TOASTS.error("Insufficient privileges! Taking you back...")
              )
            );

            await new Promise((resolve) => setTimeout(resolve, 3000));

            const currentSchema = window.location.protocol;

            const main_sub_domain = APPS["main"];

            let targetUrl;

            if (main_sub_domain != "") {
              targetUrl = `${currentSchema}//${main_sub_domain}.${
                process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN
              }`;
            } else {
              targetUrl = `${currentSchema}//${
                process.env.REACT_APP_MY_DOMAIN ?? DEFAULT_DOMAIN
              }`;
            }

            window.location.href = targetUrl;
          }
        }
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  console.log(currentUser);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {error ? (
        <h1 className="text-4xl font-large font-bold">
          Something went wrong .. Please try again later!
        </h1>
      ) : (
        <ClipLoader color="#004EAD" size={150} />
      )}
    </div>
  );
};

export default RootPage;
