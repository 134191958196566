import Main from "@main/pages/Main";
import ErrorPage from "@main/pages/ErrorPage";
import AdvertisersDashboardPage from "./pages/Dashboard";
import AdvertisersSignupPage from "./pages/Signup";
import { createBrowserRouter } from "react-router-dom";
import { ADV_ROUTES, SHARED_ROUTES } from "@constants/routes";
import SettingsPage from "@main/pages/Settings";
import MainValidatePage from "@advertiser/pages/MainValidate";
import CreateCampaginFragment from "@advertiser/pages/CreateCampaign";
import CampaignApplications from "./pages/Campaigns/Applications";

const advertiserRouter = createBrowserRouter([
  {
    path: SHARED_ROUTES.MAIN.path,
    element: <Main routes={ADV_ROUTES} />,
    errorElement: (
      <ErrorPage
        message="Nothing here! You may want to go to home page"
        buttonText="Sign up instead!"
        link="/"
      />
    ),
    children: [
      {
        path: ADV_ROUTES.DASHBOARD.path,
        element: <AdvertisersDashboardPage />,
      },
      {
        path: ADV_ROUTES.CREATE_CAMPAIGN.path,
        element: <CreateCampaginFragment />,
      },
      {
        path: ADV_ROUTES.APPLICATIONS.path,
        element: <CampaignApplications />,
      },
      {
        path: ADV_ROUTES.SIGN_UP.path,
        element: <AdvertisersSignupPage />,
      },
      {
        path: SHARED_ROUTES.SETTINGS.path,
        element: <SettingsPage />,
      },
      {
        path: SHARED_ROUTES.MAIN.path,
        element: <MainValidatePage />,
      },
    ],
  },
]);

// if you want to add a page to this module please add to the upper array in such a way:

// {
//   path: ADMIN_ROUTES.SETTINGS.path,
//   element: <SettingsPage />,
// },

export default advertiserRouter;
