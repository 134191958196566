import React, { useState, useCallback } from "react";
import { Link } from "react-scroll";
import influcracy from "@assets/Influcracy_Logo.png";
import CommonLogin from "./commonLogin";
import useNavigator from "@hooks/useNavigator";
import { Helmet } from "react-helmet";
import FlowBiteHeader from "@components/Flowbite/Header";
import FlowBiteLogin from "@components/Flowbite/Login";

const BrandLogin = () => {
  const [navigate] = useNavigator();

  const logoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const [scrollNav, setScrollNav] = useState(false);

  const scrollTop = () => {
    // animateScroll.scrollToTop();
  };
  return (
    <>
      <Helmet>
        <title>Influcracy: Brand Login</title>
      </Helmet>
      {/* <FlowBiteHeader toggle = {false}/> */}
      {/* <FlowBiteLogin/> */}

      <CommonLogin persona="brand" />
    </>
  );
};

export default BrandLogin;
