import React, { useState } from "react";

const FileUpload = ({ label, error, onChange }) => {
  const [previewData, setPreviewData] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 300;
        const MAX_HEIGHT = 300;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        setPreviewData(compressedBase64);
      };

      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    onChange(file);
  };

  return (
    <>
      <label
        htmlFor="dropzone-file"
        className="block mb-2 text-2xl font-medium text-gray-900"
      >
        {label}
      </label>
      <div className="flex w-full">
        <label
          htmlFor="dropzone-file"
          className={`block mb-2 text-sm font-medium text-gray-900 dark:text-white ${
            error ? "border-red-500" : "border-gray-300"
          }`}
        >
          {previewData && (
            <img
              src={previewData}
              alt="Preview"
              className="w-5/12 my-4 rounded"
            />
          )}
          <input
            className={`hidden`}
            aria-describedby="file_input_help"
            id="file_input"
            type="file"
            onChange={handleFileChange}
          />
          <label
            htmlFor="file_input"
            className={`${
              previewData ? "hidden" : "block"
            } p-4 w-full text-xl text-white border border-gray-300 rounded-lg cursor-pointer bg-black dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`}
          >
            Choose File
          </label>
          {error && (
            <p className="block mb-2 text-lg font-medium text-red-500">
              {error.message}
            </p>
          )}
        </label>
      </div>
    </>
  );
};

export default FileUpload;
