const REACT_QUERY_KEYS = {
  SIGN_UP_ADV: "signUpAdvertiser",
  LOG_IN: "logIn",
  LOG_OUT: "logOut",
  CHECK_ADV: "checkAdvertiser",
  URL_ISSUE: "issueURL",
  GET_CAMPAIGNS: "getCampaigns",
  CREAT_CAMPAIGN: "createCampaign",
  UPDATE_CAMPAIGN: "updateCampaign",
  ADMIN_UPDATE_CAMPAIGN: "adminUpdateCampaign",
  AD_CAMPAIGNS: "adCampaigns",
  APPROVE_CAMPAIGN: "approveCampaign",
  REJECT_CAMPAIGN: "rejectCampaign",
  ACTIVATE_USER: "activateUser",
  DISCOVER_EMAIL: "discoverEmail",
  FORGOT_PASS: "forgotPassword",
  REGISTER_EMAIL: "registerEmail",
  RESET_PASS: "resetPassword",
  VERIFY_ACC: "verifyAccount",
  VERIFY_LINKEDIN: "verifyLinkedin",
  VERIFY_FACEBOOK: "verifyFacebook",
};
export default REACT_QUERY_KEYS;
