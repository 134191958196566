import React, { useEffect, useState } from "react";
import { getPage } from "@utils/tailwind.constants";
import { useDispatch } from "react-redux";
import { setCurrentUser, toggleDarkMode } from "@store/slices/rootSlice";
import useAppSettings from "@hooks/useAppSetting";
import darkModeIMG from "@assets/darkMode.gif";
import lightModeIMG from "@assets/lightMode.gif";
import signoutIMG from "@assets/signout.svg";
import useAuthorized from "@hooks/useAuthorized";
import CardOptions from "@instagram/fragments/CardOptions";
import MobileStaticUserInfo from "@components/MobileStaticUserInfo";
import useSignOut from "@hooks/useSignout";
import useNavigator from "@hooks/useNavigator";
import { useSelector } from "react-redux";
import apiClient from "@utils/apiClient";

export default function SettingsPage({}) {
  useAuthorized();
  const dispatch = useDispatch();
  const [dark] = useAppSettings();
  const [goTo] = useNavigator();
  const signOut = useSignOut();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(null);

  const root = useSelector((state) => state.root);

  const loadUserData = () => {
    apiClient.get("api/v1/common/auth").then(({ data }) => {
      const { userData } = data;
      setEmail(userData.email);
      dispatch(setCurrentUser(userData));
    });
  };

  useEffect(() => {
    if (!root.currentUser.email) {
      loadUserData();
    } else {
      setEmail(root.currentUser.email);
    }
  }, []);

  return (
    <div className={getPage("", false, false)}>
      <div className="grid gap-48">
        <MobileStaticUserInfo email={email ?? "Loading..."} />
        <CardOptions
          className="mx-auto"
          options={[
            // {
            //   text: "Toggle light/black",
            //   icon: dark ? lightModeIMG : darkModeIMG,
            //   onClick() {
            //     dispatch(toggleDarkMode());
            //   },
            // },
            {
              text: "Sign out",
              icon: signoutIMG,
              onClick() {
                signOut();
              },
            },
          ]}
          bottomButtonOption={{
            text: "Cancel",
            onClick() {
              goTo(-1);
            },
          }}
        />
      </div>
    </div>
  );
}
