import React from "react";

const TextareaField = React.forwardRef(({ label, ...field }, ref) => {
  return (
    <div className="mt-2 mb-4">
      <label
        htmlFor={field.name}
        className="block mb-2 text-2xl font-medium text-gray-900"
      >
        {label}
      </label>
      <textarea
        className={` bg-white  border-gray-300 text-gray-900 text-xl rounded-xl focus:border focus:ring-purple-500 focus:border-purple-500 block w-full p-4 ${
          field.error ? "border border-red-500" : "border-2 border-gray-300"
        }`}
        id={field.name}
        placeholder={label}
        {...field}
      />
      {field.error && (
        <p className="block mb-2 text-lg font-medium text-red-500">
          {field.error.message}
        </p>
      )}
    </div>
  );
});

export default TextareaField;
