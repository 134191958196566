export default [
    { value: "accounting", label: "Accounting" },
    { value: "aerospace", label: "Aerospace" },
    { value: "agriculture", label: "Agriculture" },
    { value: "airlines", label: "Airlines" },
    { value: "apparel", label: "Apparel" },
    { value: "architecture", label: "Architecture" },
    { value: "arts and crafts", label: "Arts and Crafts" },
    { value: "automotive", label: "Automotive" },
    { value: "banking", label: "Banking" },
    { value: "biotechnology", label: "Biotechnology" },
    { value: "business consulting", label: "Business Consulting" },
    { value: "chemicals", label: "Chemicals" },
    { value: "child care", label: "Child Care" },
    { value: "cleaning", label: "Cleaning" },
    { value: "clothing", label: "Clothing" },
    { value: "communications", label: "Communications" },
    { value: "computer hardware", label: "Computer Hardware" },
    { value: "computer software", label: "Computer Software" },
    { value: "construction", label: "Construction" },
    { value: "consumer electronics", label: "Consumer Electronics" },
    { value: "consumer goods", label: "Consumer Goods" },
    { value: "cosmetics", label: "Cosmetics" },
    { value: "defense", label: "Defense" },
    { value: "dental", label: "Dental" },
    { value: "design", label: "Design" },
    { value: "e-commerce", label: "E-commerce" },
    { value: "education", label: "Education" },
    { value: "electrical", label: "Electrical" },
    { value: "electronics", label: "Electronics" },
    { value: "energy", label: "Energy" },
    { value: "engineering", label: "Engineering" },
    { value: "entertainment", label: "Entertainment" },
    { value: "entrepreneurship", label: "Entrepreneurship" },
    { value: "environmental", label: "Environmental" },
    { value: "event planning", label: "Event Planning" },
    { value: "fashion", label: "Fashion" },
    { value: "film", label: "Film" },
    { value: "financial services", label: "Financial Services" },
    { value: "fine art", label: "Fine Art" },
    { value: "fitness", label: "Fitness" },
    { value: "food and beverage", label: "Food and Beverage" },
    { value: "gaming", label: "Gaming" },
    { value: "government", label: "Government" },
    { value: "graphic design", label: "Graphic Design" },
    { value: "healthcare", label: "Healthcare" },
    { value: "hospitality", label: "Hospitality" },
    { value: "human resources", label: "Human Resources" },
    { value: "industrial", label: "Industrial" },
    { value: "information technology", label: "Information Technology" },
    { value: "insurance", label: "Insurance" },
    { value: "interior design", label: "Interior Design" },
    { value: "internet", label: "Internet" },
    { value: "investment", label: "Investment" },
    { value: "journalism", label: "Journalism" },
    { value: "law enforcement", label: "Law Enforcement" },
    { value: "legal", label: "Legal" },
    { value: "logistics", label: "Logistics" },
    { value: "machinery", label: "Machinery" },
    { value: "management", label: "Management" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "marketing", label: "Marketing" },
    { value: "media", label: "Media" },
    { value: "medical", label: "Medical" },
    { value: "mining", label: "Mining" },
    { value: "mobile", label: "Mobile" },
    { value: "music", label: "Music" },
    { value: "nonprofit", label: "Nonprofit" },
    { value: "office supplies", label: "Office Supplies" },
    { value: "oil and gas", label: "Oil and Gas" },
    { value: "online marketing", label: "Online Marketing" },
    { value: "packaging", label: "Packaging" },
    { value: "paper", label: "Paper" },
    { value: "personal care", label: "Personal Care" },
    { value: "pharmaceutical", label: "Pharmaceutical" },
    { value: "photography", label: "Photography" },
    { value: "plumbing", label: "Plumbing" },
    { value: "printing", label: "Printing" },
    { value: "public relations", label: "Public Relations" },
    { value: "publishing", label: "Publishing" },
    { value: "real estate", label: "Real Estate" },
    { value: "recreation", label: "Recreation" },
    { value: "religious", label: "Religious" },
    { value: "research", label: "Research" },
    { value: "retail", label: "Retail" },
    { value: "sales", label: "Sales" },
    { value: "science", label: "Science" },
    { value: "security", label: "Security" },
    { value: "shipping", label: "Shipping" },
    { value: "social media", label: "Social Media" },
    { value: "software", label: "Software" },
    { value: "sporting goods", label: "Sporting Goods" },
    { value: "sports", label: "Sports" },
    { value: "staffing", label: "Staffing" },
    { value: "startup", label: "Startup" },
    { value: "technology", label: "Technology" },
    { value: "telecommunications", label: "Telecommunications" },
    { value: "television", label: "Television" },
    { value: "textiles", label: "Textiles" },
    { value: "tourism", label: "Tourism" },
    { value: "toys", label: "Toys" },
    { value: "transportation", label: "Transportation" },
    { value: "travel", label: "Travel" },
    { value: "utilities", label: "Utilities" },
    { value: "venture capital", label: "Venture Capital" },
    { value: "veterinary", label: "Veterinary" },
    { value: "video production", label: "Video Production" },
    { value: "web design", label: "Web Design" },
    { value: "wholesale", label: "Wholesale" },
    { value: "writing", label: "Writing" },
    { value: "other", label: "Other"},
  ];
  