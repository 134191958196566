import MyIGCampaignCard from "@components/CampaignCard";
import useAuthorized from "@hooks/useAuthorized";
import useNavigation from "@hooks/useNavigator";
import useGetAllCampaigns from "@requests/campaigns/useGetAllCampaigns";
import React, { useCallback, useEffect, useState } from "react";
import { ADV_ROUTES } from "@constants/routes";
import APPS from "@constants/app-names";
import Loader from "@components/Loader";
import { Button, Card } from "ui-neumorphism";
import { FaFile } from "react-icons/fa";
import apiClient from "@utils/apiClient";
import objects from "@assets/OBJECTS.svg";
import FlowBiteHeader from "@components/Flowbite/Header";
import HelperCard from "@components/Flowbite/Card";
import StatusBadge from "@components/Flowbite/StatusBadge";

export const CampaignsListView = () => {
  useAuthorized({ isMain: true, isAdvertiser: true });
  const [page, setPage] = useState(1);
  const [goto] = useNavigation();

  const [draftCampaigns, setDraftCampaigns] = useState([]);

  const { isLoading, data, error } = useGetAllCampaigns();

  const handleCreateCampaign = () => {
    goto(ADV_ROUTES.CREATE_CAMPAIGN.path, APPS.advertiser);
  };

  const fetchDraftCampaigns = useCallback(() => {
    apiClient.get("/api/v1/adv/campaigns/draft/").then((res) => {
      setDraftCampaigns(res.data.campaigns);
    });
  }, []);

  useEffect(() => {
    fetchDraftCampaigns();
  }, []);

  return (
    <>
      <div className="w-full sm:w-[80vw] md:w-[60vw] lg:w-[50vw] mx-auto">
        {isLoading ? <Loader /> : ""}

        <div className="flex items-start justify-between rounded-t mb-8">
          <h1 className="text-4xl font-large font-bold my-auto">
            My Campaigns
          </h1>
          <button
            onClick={handleCreateCampaign}
            className=" py-2.5 px-5 m-2 mb-2 text-2xl font-medium text-white focus:outline-none bg-purple-800 rounded border border-purple-800 hover:bg-purple-400 hover:text-purple-100 focus:z-10"
          >
            Create Campaign
          </button>
        </div>

        {!isLoading &&
          data?.campaigns.length < 1 &&
          draftCampaigns.length < 1 && (
            <>
              <div>
                <img
                  className="mx-auto w-100 h-100"
                  src={objects}
                  alt="abjects"
                ></img>
              </div>

              <p className="p-4 text-center">
                You don’t have any active campaigns. Please create one to get
                started.
              </p>

              <div className="flex justify-center">
                <button
                  onClick={handleCreateCampaign}
                  className="w-full py-2.5 px-5 m-2 mb-2 text-2xl font-medium text-white focus:outline-none bg-purple-800 rounded border border-purple-800 hover:bg-purple-400 hover:text-purple-100 focus:z-10"
                >
                  Create Campaign
                </button>
              </div>
            </>
          )}

        {data?.campaigns.map((iCampaign) => (
          <div>
            <MyIGCampaignCard campaign={iCampaign} src="brand" />
          </div>
        ))}

        {draftCampaigns.map((campaign) => (
          <HelperCard>
            <div className="flex flex-col items-end w-full gap-2 pt-4 px-4">
              <StatusBadge text={"processing"} color={"red"} />
            </div>

            <Card
              className="max-h-[15vh] overflow-hidden p-2 m-6"
              outlined={true}
            >
              <p className="my-4 mx-4 text-xl line-clamp-3 leading-normal">
                {campaign.description}
              </p>
            </Card>
          </HelperCard>
        ))}
      </div>
    </>
  );
};
