import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import { MAIN_ROUTES } from "@constants/routes";
import TOASTS from "@constants/toasts";
import useNavigator from "./useNavigator";
import APPS from "@constants/app-names";
import { useCookies } from "react-cookie";
import COOKIES_KEYS from "@constants/cookie-keys";
import { setToken } from "@store/slices/rootSlice";

const useAuthorization = (
  options = { isMain: true, isAdvertiser: false, isAdmin: false }
) => {
  const { isMain = true, isAdvertiser = false, isAdmin = false } = options;
  const [token, _] = useCookies([COOKIES_KEYS.TOKEN]);
  const { accessToken } = useSelector((state) => state.root);
  const advertiser = useSelector((state) => state.advertiser);
  const admin = useSelector((state) => state.admin);
  const [goTo] = useNavigator();
  const dispatch = useDispatch();

  const sendMessage = useCallback(
    (message, route) => {
      dispatch(addToast(TOASTS.error(message)));
      goTo(route, APPS["main"]);
    },
    [dispatch, goTo]
  );

  useEffect(() => {
    if (isMain && !Boolean(accessToken)) {
      sendMessage(
        "You must be signed in first to proceed!",
        MAIN_ROUTES.BRAND_SIGN_IN.path
      );
    }
    if (isAdvertiser && !Boolean(advertiser)) {
      sendMessage(
        "You must signup first as an advertiser to proceed!",
        MAIN_ROUTES.BRAND_SIGN_IN.path
      );
    }
    if (isAdmin && !Boolean(admin)) {
      sendMessage(
        "You are not authorized to proceed!",
        MAIN_ROUTES.INFLUENCER_SIGN_IN.path
      );
    }
  }, [
    accessToken,
    advertiser,
    admin,
    isMain,
    isAdvertiser,
    isAdmin,
    dispatch,
    goTo,
    sendMessage,
  ]);
};

export default useAuthorization;
