import FlowBiteHeader from "@components/Flowbite/Header";
import InterestedUsers from "@components/InsterestedUserinCampaign";
import Loader from "@components/Loader";
import { TabItem, TabItems } from "@components/TabSelector/TabItems";
import { Tab, Tabs } from "@components/TabSelector/Tabs";
import TOASTS from "@constants/toasts";
import { addToast } from "@store/slices/rootSlice";
import apiClient from "@utils/apiClient";
import { getPage } from "@utils/tailwind.constants";
import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const CampaignApplications = () => {
  const { campaign_id } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] = useState({});

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleAdminApplicationAction = (aid, action) => {
    setIsLoading(true);

    apiClient
      .put(`/api/v1/admin/campaigns/${campaign.cid}/applications/${aid}`, {
        status: action,
      })
      .then(() => {
        dispatch(
          addToast(
            TOASTS.success(
              `Application ${
                action === "APPROVED" ? "Approved" : "Rejected"
              } Successfully!`
            )
          )
        );
      })
      .catch((error) => {
        dispatch(
          addToast(
            TOASTS.error(
              `Failed to ${
                action === "APPROVED" ? "Approve" : "Reject"
              } Application!`
            )
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    apiClient
      .get(`/api/v1/admin/campaigns/${campaign_id}/applications`)
      .then((res) => {
        setCampaign(res.data.campaign);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <FlowBiteHeader />

      <div className={`${getPage("", false, false, true)}`}>
        {isLoading ? <Loader /> : null}

        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t mb-8">
          <h3 className="text-3xl font-semibold">{campaign.title}</h3>
        </div>

        <Tabs active={activeTab} onChange={handleTabClick} tabStyle="flowbite">
          <Tab>Pending Applications</Tab>
          <Tab>Approved Applications</Tab>
        </Tabs>

        <TabItems active={activeTab}>
          <TabItem>
            {campaign.applications &&
              campaign.applications?.map((application) => {
                if (application?.status === "REQUESTED_PENDING") {
                  return (
                    <div key={application.id} className="border p-4 rounded-xl">
                      <div class="flex flex-row justify-between items-center p-4">
                        <div className="flex">
                          <div className="text-4xl">
                            <a
                              href={`https://www.instagram.com/${application.user?.igdata?.username}/`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              @{application.user?.igdata?.username}
                            </a>
                          </div>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-8">
                          <Button
                            onClick={() =>
                              handleAdminApplicationAction(
                                application.id,
                                "REQUESTED"
                              )
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={() =>
                              handleAdminApplicationAction(
                                application.id,
                                "REJECTED"
                              )
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      </div>

                      <p className="p-4">
                        {application.statusMessages[application.status]}
                      </p>

                      {application.screeningResponse &&
                        application.screeningResponse.length > 0 && (
                          <div className="flex flex-col gap-4">
                            <p className="text-xl font-semibold text-left text-gray-900">
                              Screening Responses
                            </p>

                            {application.screeningResponse.map(
                              (response, index) => (
                                <div
                                  key={response.id}
                                  className="w-full flex flex-col"
                                >
                                  <div className="grow text-xl font-normal text-left text-gray-900">
                                    {index + 1}. {response.question}
                                  </div>
                                  <div className="grow text-xl font-normal text-left text-gray-900">
                                    A. {response.answer}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                    </div>
                  );
                }
              })}
          </TabItem>
          <TabItem>
            {campaign.applications &&
              campaign.applications?.map((application) => {
                if (application?.status !== "REQUESTED_PENDING") {
                  return (
                    <div className="border p-4 rounded-xl">
                      <div class="flex flex-row justify-between items-center p-4">
                        <div className="flex">
                          <div className="text-4xl">
                            <a
                              href={`https://www.instagram.com/${application.user?.igdata?.username}/`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              @{application.user?.igdata?.username}
                            </a>
                          </div>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-8">
                          {application.status}
                        </div>
                      </div>

                      <p className="p-4">
                        {application.statusMessages[application.status]}
                      </p>
                    </div>
                  );
                }
              })}
          </TabItem>
        </TabItems>
      </div>
    </>
  );
};

export default CampaignApplications;
