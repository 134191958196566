import React from "react";
function DeliverablesList({
  onEdit = (index) => {},
  onDelete = (index) => {},
  handleClose,
  ranges = [],
}) {
  return (
    <div className="my-8">
      {ranges.length > 1 &&
        ranges.slice(0, ranges.length - 1).map((range, index) => (
          <div
            key={range.id}
            className="rounded px-2 my-2"
            style={{ background: "rgba(255,255,255, 0.5)" }}
          >
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Range {index + 1}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-4xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => handleClose(index)}
              >
                <span>×</span>
              </button>
            </div>

            <div className="flex flex-col gap-4 my-4 text-xl">
              <div className="grid grid-cols-6 gap-4 ">
                <div className="flex flex-col col-span-2">
                  <div>Followers</div>
                  <div className="font-medium">
                    {range.min ?? 0}-{range.max ?? 0}
                  </div>
                </div>
                <div className="flex flex-col col-span-2">
                  <div># of Stories</div>
                  <div className="font-medium">{range.story_num ?? 0}</div>
                </div>
                <div className="flex flex-col col-span-2">
                  <div>Story with link</div>
                  <div className="font-medium">{range.story_num ?? 0}</div>
                </div>
              </div>

              <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col col-span-2">
                  <div>Image Post</div>
                  <div className="font-medium">{range.image_num ?? 0}</div>
                </div>
                <div className="flex flex-col col-span-2">
                  <div>Video Post</div>
                  <div className="font-medium">{range.video_num ?? 0}</div>
                </div>
                <div className="flex flex-col col-span-2">
                  <div>Link in Bio</div>
                  <div className="font-medium">{range.story_num ?? 0}</div>
                </div>
              </div>

              <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col col-span-2">
                  <div>Collab Price</div>
                  <div className="font-medium">{range.colab_price ?? 0}</div>
                </div>
                <div className="flex flex-col col-span-2">
                  <div>Product Cost</div>
                  <div className="font-medium">{range.prod_value ?? 0}</div>
                </div>
                <div className="flex flex-col col-span-2">
                  <div>Discount</div>
                  <div className="font-medium">{range.disc ?? 0}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default DeliverablesList;
