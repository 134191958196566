import React from "react";
import { Card, Button } from "ui-neumorphism";
// import "./PreviousPosts.css";

const PreviousPosts = () => {
  return (
    <div
      className="previous"
      style={{
        marginTop: "14rem",
        /* margin-left: -25rem; */
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        style={{
          marginBottom: "44px",
        }}
      >
        Previously Created Posts
      </Button>

      <Card
        rounded
        style={{
          padding: "14px",
          marginBottom: "44px",
        }}
      >
        <div
          className="post-sample"
          style={{
            margin: "16px",
            borderRadius: "16px",
            backgroundColor: "papayawhip",
            width: "80vw",
            height: "260px",
          }}
        ></div>
      </Card>
      <Card
        rounded
        style={{
          padding: "14px",
          marginBottom: "44px",
        }}
      >
        <div
          className="post-sample"
          style={{
            margin: "16px",
            borderRadius: "16px",
            backgroundColor: "papayawhip",
            width: "80vw",
            height: "260px",
          }}
        ></div>{" "}
      </Card>
      <Card
        rounded
        style={{
          padding: "14px",
          marginBottom: "44px",
        }}
      >
        <div
          className="post-sample"
          style={{
            margin: "16px",
            borderRadius: "16px",
            backgroundColor: "papayawhip",
            width: "80vw",
            height: "260px",
          }}
        ></div>{" "}
      </Card>
      <Card
        rounded
        style={{
          padding: "14px",
          marginBottom: "44px",
        }}
      >
        <div
          className="post-sample"
          style={{
            margin: "16px",
            borderRadius: "16px",
            backgroundColor: "papayawhip",
            width: "80vw",
            height: "260px",
          }}
        ></div>{" "}
      </Card>
    </div>
  );
};

export default PreviousPosts;
