import Main from "@main/pages/Main";
import ErrorPage from "@main/pages/ErrorPage";
import { INSTAGRAM_ROUTES, SHARED_ROUTES } from "@constants/routes";
import { createBrowserRouter } from "react-router-dom";
import Dashboard from "@tiktok/pages/Dashboard";
import ValidateTK from "@tiktok/pages/ValidateTK";
import TKConnect from "@tiktok/pages/Connect";

const tiktokRouter = createBrowserRouter([
  {
    path: SHARED_ROUTES.MAIN.path,
    element: <Main routes={INSTAGRAM_ROUTES} />,
    errorElement: (
      <ErrorPage
        message="Nothing here! You may want to go to home page"
        buttonText="Sign up instead!"
        link="/"
      />
    ),
    children: [
      {
        path: SHARED_ROUTES.MAIN.path,
        element: <ValidateTK />,
      },
      {
        path: SHARED_ROUTES.DASHBOARD.path,
        element: <Dashboard />,
      },
      {
        path: INSTAGRAM_ROUTES.CONNECT.path,
        element: <TKConnect />,
      },
    ],
  },
]);

export default tiktokRouter;
