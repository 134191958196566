import React from "react";
import { Card } from "ui-neumorphism";

const Terms = () => {
  return (
    <div
    style={{
        padding: "16px",
        marginTop: "5rem",
    }}>

        <Card style={{
        padding: "16px",
    }}>

        <h1 class="mb-2 text-8xl font-bold tracking-tight text-gray-900 dark:text-white">
        Terms and Conditions
      </h1>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        BY ACCESSING OR USING https://influcracy.com YOU REPRESENT THAT YOU HAVE
        THE FULL AUTHORITY TO ACT TO BIND YOURSELF, ANY THIRD PARTY, COMPANY, OR
        LEGAL ENTITY, AND THAT YOUR USE AND/OR INTERACTION, AS WELL AS
        CONTINUING TO USE OR INTERACT, WITH THE SITE CONSTITUTES YOUR HAVING
        READ AND AGREED TO THESE TERMS OF USE AS WELL AS OTHER AGREEMENTS THAT
        WE MAY POST ON THE SITE. BY VIEWING, VISITING, USING, OR INTERACTING
        WITH https://influcracy.com OR WITH ANY BANNER, POP-UP, OR ADVERTISING
        THAT APPEARS ON IT, YOU ARE AGREEING TO ALL THE PROVISIONS OF THIS TERMS
        OF USE POLICY AND THE PRIVACY POLICY OF https://influcracy.com .
        https://influcracy.com SPECIFICALLY DENIES ACCESS TO ANY INDIVIDUAL THAT
        IS COVERED BY THE CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA) OF
        1998. https://influcracy.com RESERVES THE RIGHT TO DENY ACCESS TO ANY
        PERSON OR VIEWER FOR ANY LAWFUL REASON. UNDER THE TERMS OF THE PRIVACY
        POLICY, WHICH YOU ACCEPT AS A CONDITION FOR VIEWING,
        https://influcracy.com IS ALLOWED TO COLLECT AND STORE DATA AND
        INFORMATION FOR THE PURPOSE OF EXCLUSION AND FOR MANY OTHER USES. THIS
        TERMS OF USE AGREEMENT MAY CHANGE FROM TIME TO TIME. VISITORS HAVE AN
        AFFIRMATIVE DUTY, AS PART OF THE CONSIDERATION FOR PERMISSION TO ACCESS
        https://influcracy.com , TO KEEP THEMSELVES INFORMED OF SUCH CHANGES BY
        REVIEWING THIS TERMS OF USE PAGE EACH TIME THEY VISIT
        https://influcracy.com .
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        PARTIES TO THE TERMS OF USE AGREEMENT
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        Visitors, viewers, users, subscribers, members, affiliates, or
        customers, collectively referred to herein as “Visitors,” are parties to
        this agreement. The website and its owners and/or operators are parties
        to this agreement, herein referred to as “Website.”
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        USE OF INFORMATION FROM THIS WEBSITE
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        Unless you have entered into an express written contract with this
        website to the contrary, visitors, viewers, subscribers, members,
        affiliates, or customers have no right to use this information in a
        commercial or public setting; they have no right to broadcast it, copy
        it, save it, print it, sell it, or publish any portions of the content
        of this website. By accessing the contents of this website, you agree to
        this condition of access and you acknowledge that any unauthorized use
        is unlawful and may subject you to civil or criminal penalties. Again,
        Visitor has no rights whatsoever to use the content of, or portions
        thereof, including its databases, invisible pages, linked pages,
        underlying code, or other intellectual property the site may contain,
        for any reason or for any use whatsoever. In recognition of the fact
        that it may be difficult to quantify the exact damages arising from
        infringement of this provision, Visitor agrees to compensate the owners
        of https://influcracy.com with liquidated damages in the amount of U.S.
        $100,000, or, if it can be calculated, the actual costs and actual
        damages for breach of this provision, whichever is greater. Visitor
        warrants that he or she understands that accepting this provision is a
        condition of accessing https://influcracy.com and that accessing
        https://influcracy.com constitutes acceptance.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        OWNERSHIP OF WEBSITE OR RIGHT TO USE,SELL, PUBLISH CONTENTS OF THIS
        WEBSITE
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        The website and its contents are owned or licensed by the website’s
        owner. Material contained on the website must be presumed to be
        proprietary and copyrighted. Visitors have no rights whatsoever in the
        site content. Use of website content for any reason is unlawful unless
        it is done with express contract or permission of the website.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        OWNERSHIP OF WEBSITE OR RIGHT TO USE,SELL, PUBLISH CONTENTS OF THIS
        WEBSITE
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        The website and its contents are owned or licensed by the website’s
        owner. Material contained on the website must be presumed to be
        proprietary and copyrighted. Visitors have no rights whatsoever in the
        site content. Use of website content for any reason is unlawful unless
        it is done with express contract or permission of the website.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        HYPERLINKING TO SITE, CO-BRANDING, “FRAMING” AND REFERENCING SITE
        PROHIBITED
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        Unless expressly authorized by the website, no one may hyperlink
        https://influcracy.com , or portions thereof, (including, but not
        limited to, logotypes, trademarks, branding or copyrighted material) to
        theirs for any reason. Furthermore, you are not permitted to reference
        the URL (website address) of this website or any page of this website in
        any commercial or non-commercial media without express permission from
        us, nor are you allowed to ‘frame’ the site. You specifically agree to
        cooperate with the Website to remove or deactivate any such activities,
        and be liable for all damages arising from violating this provision. In
        recognition of the fact that it may be difficult to quantify the exact
        damages arising from infringement of this provision, you agree to
        compensate the owners of https://influcracy.com with liquidated damages
        in the amount of U.S. $100,000, or, if it can be calculated, the actual
        costs and actual damages for breach of this provision, whichever is
        greater. You warrant that you understand that accepting this provision
        is a condition of accessing https://influcracy.com and that accessing
        https://influcracy.com constitutes acceptance.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        DISCLAIMER FOR CONTENTS OF SITE
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        https://influcracy.com disclaims any responsibility for the accuracy of
        the content appearing at, linked to on, or mentioned on
        https://influcracy.com . Visitors assume all risk relating to viewing,
        reading, using, or relying upon this information. Unless you have
        otherwise formed an express contract to the contrary with us, you have
        no right to rely on any information contained herein as accurate. We
        make no such warranty. DISCLAIMER FOR HARM CAUSED TO YOUR COMPUTER OR
        SOFTWARE FROM INTERACTING WITH THIS WEBSITE OR ITS CONTENTS. VISITOR
        ASSUMES ALL RISK OF VIRUSES, WORMS, OR OTHER CORRUPTING FACTORS. We
        assume no responsibility for damage to computers or software of the
        visitor or any person the visitor subsequently communicates with from
        corrupting code or data that is inadvertently passed to the visitor’s
        computer. Again, visitor views and interacts with https://influcracy.com
        , or banners or pop-ups or advertising displayed thereon, at his own
        risk.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        LIMITATION OF LIABILITY
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        By viewing, using, or interacting in any manner with
        https://influcracy.com , including banners, advertising, or pop-ups,
        downloads, and as a condition of the website to allow his lawful
        viewing, Visitor forever waives all right to claims of damage of any and
        all description based on any causal factor resulting in any possible
        harm, no matter how heinous or extensive, whether physical or emotional,
        foreseeable or unforeseeable, whether personal or commercial in nature.
        For any jurisdictions that may now allow for these exclusions our
        maximum liability will not exceed the amount paid by you, if any, for
        using our website or service. Additionally, you agree not to hold us
        liable for any damages related to issues beyond our control, including
        but not limited to, acts of God, war, terrorism, insurrection, riots,
        criminal activity, natural disasters, disruption of communications or
        infrastructure, labor shortages or disruptions (including unlawful
        strikes), shortages of materials, and any other events which are not
        within our control.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        INDEMNIFICATION
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        Visitor agrees that in the event he causes damage to us or a third party
        as a result of or relating to the use of https://influcracy.com ,
        Visitor will indemnify us for, and, if applicable, defend us against,
        any claims for damages.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        SUBMISSIONS
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        Visitor agrees as a condition of viewing, that any communication between
        Visitor and Website is deemed a submission. All submissions, including
        portions thereof, graphics contained thereon, or any of the content of
        the submission, shall become the exclusive property of the Website and
        may be used, without further permission, for commercial use without
        additional consideration of any kind. Visitor agrees to only communicate
        that information to the Website, which it wishes to forever allow the
        Website to use in any manner as it sees fit. “Submissions” is also a
        provision of the Privacy Policy.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        NOTICE
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        No additional notice of any kind for any reason is required to be given
        to Visitor and Visitor expressly warrants an understanding that the
        right to notice is waived as a condition for permission to view or
        interact with the website.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        DISPUTES
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        As part of the consideration that the Website requires for viewing,
        using or interacting with this website, Visitor agrees to use binding
        arbitration for any claim, dispute, or controversy (“CLAIM”) of any kind
        (whether in contract, tort or otherwise) arising out of or relating to
        this purchase, this product, including solicitation issues, privacy
        issues, and terms of use issues. Arbitration shall be conducted pursuant
        to the rules of the American Arbitration Association which are in effect
        on the date a dispute is submitted to the American Arbitration
        Association. Information about the American Arbitration Association, its
        rules, and its forms are available from the American Arbitration
        Association, 335 Madison Avenue, Floor 10, New York, New York,
        10017-4605. Hearing will take place in the city or county of the owner
        of https://influcracy.com . In no case shall the viewer, visitor,
        member, subscriber or customer have the right to go to court or have a
        jury trial. Viewer, visitor, member, subscriber or customer will not
        have the right to engage in pre-trial discovery except as provided in
        the rules; you will not have the right to participate as a
        representative or member of any class of claimants pertaining to any
        claim subject to arbitration; the arbitrator’s decision will be final
        and binding with limited rights of appeal. The prevailing party shall be
        reimbursed by the other party for any and all costs associated with the
        dispute arbitration, including attorney fees, collection fees,
        investigation fees, travel expenses.
      </p>

      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        JURISDICTION AND VENUE
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        If any matter concerning this purchase shall be brought before a court
        of law, pre- or post-arbitration, Viewer, visitor, member, subscriber or
        customer agrees to that the sole and proper jurisdiction to be the state
        and city declared in the contact information of the web owner unless
        otherwise specified here. In the event that litigation is in a federal
        court, the proper court shall be the closest federal court to the owner
        of https://influcracy.com ’s address. APPLICABLE LAW Viewer, visitor,
        member, subscriber or customer agrees that the applicable law to be
        applied shall, in all cases, be that of the state of the owner of
        https://influcracy.com .
      </p>
        </Card>
    </div>
  );
};

export default Terms;
