import { createSlice } from "@reduxjs/toolkit";
const INIT_STATE = {
  username: "",
  followers_count: 0,
};
export const tkSlice = createSlice({
  name: "tkdata",
  initialState: INIT_STATE,
  reducers: {
    resetTKState: () => INIT_STATE,
    setTKData: (_, { payload }) => {
      return { ...payload };
    },
  },
});

export const { setTKData, resetTKState } = tkSlice.actions;

export default tkSlice.reducer;
