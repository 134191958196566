import React from "react";

const BackButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      class="text-white mb-4 bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="arrow-left">
          <g id="Vector">
            <path d="M1.75 6.99984H12.25Z" fill="white" />
            <path
              d="M5.83333 11.0832L1.75 6.99984M1.75 6.99984L5.83333 2.9165M1.75 6.99984H12.25"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>

      <span class="sr-only">Icon description</span>
    </button>
  );
};

export default BackButton;
