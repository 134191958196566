import { MAIN_ROUTES, SHARED_ROUTES } from "@constants/routes";
import SocialMediaConnectPage from "@main/pages/SocailMediaConnect";
import SettingsPage from "@main/pages/Settings";
import InfluencerLogin from "@main/pages/SignIn/InfluencerLogin";
import BrandLogin from "@main/pages/SignIn/BrandLogin";
import UserVerification from "@main/pages/UserVerification";
import Influencer from "@main/pages/Influencers/Influencer";
import Brand from "@main/pages/Brands/Brand";
import Main from "@main/pages/Main";
import ErrorPage from "@main/pages/ErrorPage";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "@main/fragments/HomePage/HomePage";
import About from "@main/pages/About/About";
import GoogleLogin from "@main/pages/GoogleLogin/index";
import Terms from "@main/pages/Terms/index";
import Privacy from "@main/pages/Privacy/index";
import LinkedIn from "./pages/Linkedin";
import FacebookLogin from "./pages/FacebookLogin";

const landingRouter = createBrowserRouter([
  {
    path: SHARED_ROUTES.MAIN.path,
    element: <Main routes={MAIN_ROUTES} />,
    errorElement: (
      <ErrorPage
        message="Nothing here! You may want to go to home page"
        buttonText="Sign up instead!"
        link="/"
      />
    ),
    children: [
      {
        path: SHARED_ROUTES.MAIN.path,
        element: <HomePage />,
      },
      {
        path: MAIN_ROUTES.SOCIAL_MEDIA_ACCS.path,
        element: <SocialMediaConnectPage />,
      },
      {
        path: SHARED_ROUTES.SETTINGS.path,
        element: <SettingsPage />,
      },
      {
        path: MAIN_ROUTES.INFLUENCER_SIGN_IN.path,
        element: <InfluencerLogin />,
      },
      {
        path: MAIN_ROUTES.BRAND_SIGN_IN.path,
        element: <BrandLogin />,
      },
      {
        path: MAIN_ROUTES.USER_VERIFICATION.path,
        element: <UserVerification />,
      },
      {
        path: MAIN_ROUTES.GOOGLE_LOGIN.path,
        element: <GoogleLogin />,
      },
      {
        path: MAIN_ROUTES.LINKEDIN.path,
        element: <LinkedIn />,
      },
      {
        path: MAIN_ROUTES.FACEBOOK.path,
        element: <FacebookLogin />,
      },
      {
        path: MAIN_ROUTES.INFLUENCER.path,
        element: <Influencer />,
      },
      {
        path: MAIN_ROUTES.BRAND.path,
        element: <Brand />,
      },
      {
        path: MAIN_ROUTES.ABOUT.path,
        element: <About />,
      },
      {
        path: MAIN_ROUTES.TOU.path,
        element: <Terms />,
      },
      {
        path: MAIN_ROUTES.PRIVACY.path,
        element: <Privacy />,
      },
    ],
  },
]);

export default landingRouter;
