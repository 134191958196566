import { useDispatch } from "react-redux";
import warningIMG from "@assets/warning.png";
import Container from "@components/Container";
import ExtendedButton from "@components/ExtendedButton";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import { Button, Typography } from "ui-neumorphism";
import useAppSettings from "@hooks/useAppSetting";

export default function CardOptions({
  className = " ",
  options = [
    {
      text: "No option passed!",
      icon: warningIMG,
      onClick: null,
    },
  ],
  bottomButtonOption,
}) {
  const dispatch = useDispatch();
  const defaultClickHandler = () => {
    dispatch(addToast(TOASTS.warning("This options is not implemented yet!")));
  };
  const [dark, isMobile] = useAppSettings();
  return (
    <Container card className={className}>
      {options.map(({ text, icon, onClick }, index) => (
        <>
          <div className="flex gap-1 items-center" key={text}>
            <img className="h-8 w-8 object-contain " src={icon || warningIMG} />
            <Button
              text
              size="large"
              className="flex-1"
              key={text}
              onClick={onClick || defaultClickHandler}
            >
              <Typography
                dark={dark}
                type={isMobile ? "body-1" : "h5"}
                className="w-full ml-2"
              >
                {text || "No text was passed for this option!"}
              </Typography>
            </Button>
          </div>

          {index !== options.length - 1 && (
            <hr className="bg-gray-400 h-px rounded-md"></hr>
          )}
        </>
      ))}
      {bottomButtonOption && (
        <ExtendedButton
          handleClick={bottomButtonOption.onClick || defaultClickHandler}
          img={bottomButtonOption.icon}
          text={
            bottomButtonOption.text ||
            "No text was passed for the bottom option!"
          }
        />
      )}
    </Container>
  );
}
