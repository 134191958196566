import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import discoverEmail from "@api/auth/discover-email";

export default function useDiscoverEmail(
  options
) {
  return useMutation(
    {
      mutationFn: discoverEmail,
      mutationKey: REACT_QUERY_KEYS.DISCOVER_EMAIL,
      ...options,
    }
  );
};