import Create from "@linkedin/pages/Create";
import Earn from "@linkedin/pages/Earn";
import Promote from "@linkedin/pages/Promote";
import React, { useState } from "react";
import { H4, H6, Tab, Card, Tabs, Button } from "ui-neumorphism";
import Li from "@assets/linkedin.svg";
import "ui-neumorphism/dist/index.css";


class LiNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  renderTabContent() {
    const { active } = this.state;

    switch (active) {
      case 0:
        return <Create/>
      case 1:
        return <Promote/>
      case 2:
        return <Earn/>
      default:
        return null;
    }
  }

  render() {
    const { active } = this.state;

    return (
      <>
        <div
          style={{
            position: "absolute",
            padding: "10px",
            top: 0,
            left: 0,
            width: "100%",
            // zIndex: 999,
          }}
        >
          <Button
            rounded
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "32rem",
              marginTop: "15rem",
              marginBottom: "2rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div className="footer_icons">
              <img src={Li} alt="" />
            </div>
            Connect Your LinkedIn
          </Button>
          <Card
            rounded={true}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Card flat className="px-4 fill-width">
              <Card flat className="pa-4 mt-12">
                <Tabs
                  underlined
                  value={active}
                  onChange={({ active }) => this.setState({ active })}
                >
                  <Tab>Create</Tab>
                  <Tab>Promote</Tab>
                  <Tab>Earn</Tab>
                </Tabs>
              </Card>
            </Card>
          </Card>
        </div>
        <div style={{
          padding: "18rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", }}>
          {this.renderTabContent()}
        </div>
      </>
    );
  }
}

export default LiNav;
