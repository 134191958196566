import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import resetPassword from "@api/auth/reset-password";

export default function useResetPassword(
  options
){
  return useMutation({
    mutationFn: resetPassword,
    mutationKey: REACT_QUERY_KEYS.RESET_PASS,
    ...options
  }
  );
};