import Header from '@main/fragments/Header/Header'
import React from 'react'

const About = () => {
  return (
    <>
    <Header/>
    </>
  )
}

export default About