import { CSS_COLORS } from "@constants/theme";
import useAppSettings from "@hooks/useAppSetting";
import { getCard } from "@utils/tailwind.constants";

export default function Container({
  card = false,
  children = "No children passed!",
  className =""
}) {
  const [dark] = useAppSettings();
  const shadowColor = dark
    ? CSS_COLORS.DARK_BG_SHADOW
    : CSS_COLORS.LIGHT_BG_SHADOW;
  return (
    <div
      style={
        card
          ? {
              background: dark ? CSS_COLORS.DARK_BG : CSS_COLORS.LIGHT_BG,
              boxShadow: `10px 10px 20px ${shadowColor}, -10px -10px 16px ${shadowColor}`,
            }
          : {}
      }
      className={getCard(null, true)+ " flex flex-col " + className}
    >
      {children}
    </div>
  );
}
