import React, { useState, cloneElement, useEffect } from "react";

function SelectFieldWithCheckbox({
  label,
  onSelect,
  selecteds,
  children,
  showSearch,
  data,
  limit = false,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [mainData, setMainData] = useState(data);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onSelectAbstract = (e) => {
    onSelect(e.target.id);
  };

  // Function to wrap and clone child elements with additional props
  const wrapChild = (child) => {
    return cloneElement(child, {
      onClick:
        child.type === Item
          ? child.props.onClick
          : child.props.onClick || toggleDropdown,
      onSelect: onSelectAbstract,
      selecteds: selecteds,
      isChecked: selecteds && selecteds.includes(child.props.id),
      limit: limit,
    });
  };

  function filterDataByText(text) {
    if (!text) {
      return data;
    }

    const searchTextLower = text.toLowerCase();
    const searchData = {};

    for (const region in data) {
      const countries = data[region].filter((country) =>
        country.toLowerCase().includes(searchTextLower)
      );

      if (countries.length > 0) {
        searchData[region] = countries;
      }
    }

    setFilteredData(searchData);
  }

  const searchData = (e) => {
    setSearchText(e.target.value);
    filterDataByText(e.target.value);
  };

  useEffect(() => {
    if (searchText !== "" && searchText.length > 0) {
      setMainData(filteredData);
    } else {
      setMainData(data);
    }
  }, [searchText, filteredData, data]);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="w-full text-gray-900 border border-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center "
        type="button"
      >
        {label}
        <svg
          className={`w-2.5 h-2.5 ml-auto ${
            isDropdownOpen ? "rotate-180" : ""
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isDropdownOpen && (
        <div className="max-h-[50vh] overflow-auto z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 dark:divide-gray-600">
          {showSearch && (
            <div class="p-3">
              <label for="input-group-search" class="sr-only">
                Search
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="input-group-search"
                  onChange={searchData}
                  class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Country"
                />
              </div>
            </div>
          )}

          <ul
            class="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownCheckboxButton"
          >
            {mainData &&
              typeof mainData === "object" &&
              !Array.isArray(mainData) &&
              mainData !== null &&
              Object.keys(mainData).map((key) => {
                return wrapChild(
                  <ItemHead id={key}>
                    {mainData[key].map((item) => {
                      return <Item id={item} />;
                    })}
                  </ItemHead>
                );
              })}

            {mainData &&
              Array.isArray(mainData) &&
              mainData !== null &&
              mainData.map((key) => {
                return wrapChild(<Item id={key} />);
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

function Item({ id, isChecked, onSelect, limit }) {
  return (
    <li>
      <div class="flex items-center">
        <input
          id={id}
          type="checkbox"
          onChange={onSelect}
          checked={isChecked}
          disabled={!isChecked && limit}
          value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />
        <label
          for={id}
          class="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
        >
          {id}
        </label>
      </div>
    </li>
  );
}

function ItemHead({ id, selecteds, onSelect, children }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onSelectAbstract = (e) => {
    onSelect(e);
  };

  const wrapChild = (child) => {
    return cloneElement(child, {
      onSelect: onSelectAbstract,
      isChecked: selecteds && selecteds.includes(child.props.id),
    });
  };

  return (
    <li>
      <div class="flex items-center " onClick={toggleCollapsed}>
        <span className="text-lg font-semibold">{id}</span>

        <svg
          className={`w-2.5 h-2.5 ml-auto ${isCollapsed ? "rotate-180" : ""}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </div>

      {isCollapsed && children && (
        <ul
          class="ml-2 p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownCheckboxButton"
        >
          {isCollapsed &&
            children &&
            children.map((child) => {
              return wrapChild(child);
            })}
        </ul>
      )}
    </li>
  );
}

export { SelectFieldWithCheckbox, Item, ItemHead };
