import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Tab } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
import apiClient from "@utils/apiClient";
import { InterestedApplicant } from "./Interested/IntrestedApplicant";
import Loader from "@components/Loader";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";

const InterestedUsers = ({ campaign, onBackClick }) => {
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [applications, setApplications] = useState({});
  const dispatch = useDispatch();

  const updateApplication = useCallback((aid, action, note) => {
    setIsUpdating(true);
    apiClient
      .put(`/api/v1/adv/campaigns/${campaign.cid}/applications/${aid}`, {
        action: action,
        message: note,
      })
      .then(({ data }) => {
        dispatch(
          addToast(TOASTS.success(`Application ${action} Successfully.`))
        );
        fetchApplications();
      })
      .catch((error) => {
        dispatch(
          addToast(
            TOASTS.error("Something went wrong while updating application.")
          )
        );
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, []);

  const fetchApplications = useCallback(() => {
    setIsLoading(true);

    apiClient
      .get(`/api/v1/adv/campaigns/${campaign.cid}/applications`)
      .then(({ data }) => {
        setApplications(data.applications);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchApplications();
  }, []);

  const renderTabContent = () => {
    switch (active) {
      case 0:
        return (
          <>
            {applications &&
            applications.REQUESTED &&
            applications.REQUESTED.length > 0 ? (
              applications.REQUESTED.map((application) => (
                <InterestedApplicant
                  key={application.id} // Don't forget to add a unique key when rendering a list
                  application={application}
                  onAdvAction={updateApplication}
                  isUpdating={isUpdating}
                  status={"REQUESTED"}
                />
              ))
            ) : (
              <div className="text-center p-10">
                <p>No requested applications found.</p>
              </div>
            )}
          </>
        );
      case 1:
        return (
          <>
            {applications &&
            ((applications.APPROVED && applications.APPROVED.length > 0) ||
              (applications.COMPLETED && applications.COMPLETED.length > 0) ||
              (applications.COMPLETED_APPROVED &&
                applications.COMPLETED_APPROVED.length > 0)) ? (
              <>
                {applications.APPROVED && applications.APPROVED.length > 0 && (
                  <>
                    {applications.APPROVED.map((application) => (
                      <InterestedApplicant
                        key={application.id}
                        application={application}
                        onAdvAction={updateApplication}
                        isUpdating={isUpdating}
                        status={"APPROVED"}
                      />
                    ))}
                  </>
                )}

                {applications.COMPLETED &&
                  applications.COMPLETED.length > 0 && (
                    <>
                      {applications.COMPLETED.map((application) => (
                        <InterestedApplicant
                          key={application.id}
                          application={application}
                          onAdvAction={updateApplication}
                          isUpdating={isUpdating}
                          status={"COMPLETED"}
                        />
                      ))}
                    </>
                  )}

                {applications.COMPLETED_APPROVED &&
                  applications.COMPLETED_APPROVED.length > 0 && (
                    <>
                      {applications.COMPLETED_APPROVED.map((application) => (
                        <InterestedApplicant
                          key={application.id}
                          application={application}
                          onAdvAction={updateApplication}
                          isUpdating={isUpdating}
                          status={"COMPLETED_APPROVED"}
                        />
                      ))}
                    </>
                  )}
              </>
            ) : (
              <div className="text-center p-10">
                <p>No approved or completed applications found.</p>
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}

      <div className="m-4">
        <Tabs
          underlined
          value={active}
          onChange={({ active }) => setActive(active)}
          style={{
            marginTop: "10px",
          }}
        >
          <Tab>
            <>
              <span>Interested</span>

              <span className="ml-2 inline-flex items-center justify-center w-8 h-8 mr-2 text-xl font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                <span>{applications?.REQUESTED?.length ?? 0}</span>
              </span>
            </>
          </Tab>
          <Tab>
            <>
              <span>Approved</span>

              <span className="ml-2 inline-flex items-center justify-center w-8 h-8 mr-2 text-xl font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                <span>
                  {(Number(applications?.APPROVED?.length) || 0) +
                    (Number(applications?.COMPLETED?.length) || 0) +
                    (Number(applications?.COMPLETED_APPROVED?.length) || 0)}
                </span>
              </span>
            </>
          </Tab>
        </Tabs>
        <div>{renderTabContent()}</div>
      </div>
    </>
  );
};

export default InterestedUsers;
