import React, { useState } from "react";
import { getPage } from "@utils/tailwind.constants";
import { useDispatch, useSelector } from "react-redux";
import icInsta from "@assets/ic-logo-insta.png";
import profileIMG from "@assets/avatar.svg";
import { H4 } from "ui-neumorphism";
import { Tabs, Tab } from "@components/TabSelector/Tabs";
import { TabItems, TabItem } from "@components/TabSelector/TabItems";
import apiClient from "@utils/apiClient";
import Loader from "@components/Loader";
import { SHARED_ROUTES } from "@constants/routes";
import useNavigation from "@hooks/useNavigator";
import FlowBiteHeader from "@components/Flowbite/Header";
import { resetTKState } from "@store/slices/tkSlice";
import { getTikTokAuthUrl } from "@utils/helperSSO";
import TKNewCampaigns from "@tiktok/fragments/NewCampaigns";
import TKMyCampaigns from "@tiktok/fragments/MyCampaign";

const Dashboard = () => {
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const tkData = useSelector((state) => state.tkData);
  const [isDemo, _] = useState(tkData?.username === "");
  const profilePC = tkData.info?.gender ? 100 : 30;

  const dispatch = useDispatch();

  const [goto] = useNavigation();

  const handleRedirect = () => {
    goto(SHARED_ROUTES.MAIN.path);
  };

  const tiktokAuth = (e) => {
    e.preventDefault();

    const tikTokAuthUrl = getTikTokAuthUrl();
    window.location.href = tikTokAuthUrl;
  };

  const disconnectTK = (e) => {
    setIsLoading(true);
    apiClient
      .delete("/api/v1/tk/me")
      .then((data) => {})
      .finally(() => {
        setIsLoading(false);
        handleRedirect();
        dispatch(resetTKState());
      });
  };

  const handleTabClick = (index) => {
    setActive(index);
  };

  const tabHeaders = (
    <Tabs active={active} onChange={handleTabClick} tabStyle="flowbite">
      <Tab>New Campaigns</Tab>
      <Tab>My Campaigns</Tab>
    </Tabs>
  );

  const tabContents = (
    <TabItems active={active}>
      <TabItem>
        {isDemo ? (
          <div>
            As a first-time user, please connect your TikTok to verify your
            username and account type
          </div>
        ) : (
          <TKNewCampaigns />
        )}
      </TabItem>
      <TabItem>
        {isDemo ? (
          <div>
            As a first-time user, please connect your TikTok to verify your
            username and account type
          </div>
        ) : (
          <TKMyCampaigns />
        )}
      </TabItem>
    </TabItems>
  );

  return (
    <>
      <FlowBiteHeader
        showProfileCompletionBtn={profilePC !== 100 && !isDemo}
        profileCompletionPC={profilePC}
      />

      <div className={`${getPage("", false, false, true)}`}>
        {isLoading && <Loader />}

        {isDemo && (
          <div className="flex justify-center shadow-lg rounded-full p-8 m-4 w-fit mx-auto">
            <div className="flex gap-4 align-items-center">
              <img src={icInsta} alt="ic-logo" className="w-12 h-12" />

              <button onClick={tiktokAuth}>Connect your tiktok</button>
            </div>
          </div>
        )}

        {!isDemo && (
          <div className="flex gap-4">
            <img
              className="h-24 w-24 self-center rounded-full"
              src={profileIMG}
              alt="User Profile"
            />

            <div>
              <H4>{tkData?.username}</H4>
              <button onClick={disconnectTK} className="text-blue-800">
                Disconnect
              </button>
            </div>
          </div>
        )}

        <div className="mx-auto w-full sm:max-w-3xl">
          {tabHeaders}

          {tabContents}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
