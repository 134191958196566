/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { getPage } from "@utils/tailwind.constants";
import { Tabs, Tab } from "@components/TabSelector/Tabs";
import { TabItems, TabItem } from "@components/TabSelector/TabItems";
import FlowBiteHeader from "@components/Flowbite/Header";
import InstagramCampaignFragment from "@fragments/InstagramCampaign";

function CreateCampaginFragment() {
  const [active, setActive] = useState(0);

  const handleTabClick = (index) => {
    setActive(index);
  };

  const tabHeaders = (
    <Tabs active={active} onChange={handleTabClick} tabStyle="flowbite">
      <Tab>Instagram</Tab>
      <Tab>LinkedIn</Tab>
    </Tabs>
  );

  const tabContents = (
    <TabItems active={active}>
      <TabItem>
        <InstagramCampaignFragment />
      </TabItem>
      <TabItem>
        <p>
          LinkedIn campaigns is under development. Stay tuned for updates as we
          continue to enhance.
        </p>
      </TabItem>
    </TabItems>
  );

  return (
    <>
      <FlowBiteHeader />
      <div className={getPage("", false, false, true)}>
        <div className="w-full sm:w-[80vw] md:w-[60vw] lg:w-[50vw] mx-auto">
          <div className="text-black mb-[2rem] text-6xl font-semibold leading-9">
            Create Campaign
          </div>

          <InstagramCampaignFragment />
        </div>
      </div>
    </>
  );
}

export default CreateCampaginFragment;
