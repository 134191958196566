import apiClient from "@utils/apiClient";
import React, { useCallback, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { H3 } from "ui-neumorphism";
import { SHARED_ROUTES } from "@constants/routes";
import useNavigation from "@hooks/useNavigator";
import { setTKData } from "@store/slices/tkSlice";

function ValidateTK() {
  const [goto] = useNavigation();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);

  const getTKInfo = useCallback(async () => {
    await apiClient
      .get("/api/v1/tk/me")
      .then(({ data }) => {
        if (data.tkdata) {
          dispatch(setTKData(data.tkdata));
        }
        goto(SHARED_ROUTES.DASHBOARD.path);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  useEffect(() => {
    getTKInfo();
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {error ? (
        <H3>Something went wrong .. Please try again later!</H3>
      ) : (
        <ClipLoader color="#004EAD" size={150} />
      )}
    </div>
  );
}

export default ValidateTK;
