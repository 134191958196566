import React, { useCallback, useEffect, useState } from "react";
import apiClient from "@utils/apiClient";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import HelperCard from "@components/Flowbite/Card";
import CampaignModal from "@components/CampaignModal";
import StatusBadge from "@components/Flowbite/StatusBadge";
import { Button } from "flowbite-react";
import { FaSpinner } from "react-icons/fa";
import CampaignCompletionModal from "@components/CampaignCompletionModal";

const CampaignCardInfluencer = ({ campaign, onRefetch, type = "insta" }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [showCampaignCompletionModal, setShowCampaignCompletionModal] =
    useState(false);
  const [fetchedImageData, setFetchedImageData] = useState("");

  const viewCampaign = () => {
    setShowCampaignModal(true);
  };

  const closeCampaignModal = () => {
    setShowCampaignModal(false);
  };

  const closeCampaignCompletionModal = () => {
    setShowCampaignCompletionModal(false);
  };

  const successMsg = (action) => {
    let message;

    switch (action) {
      case "REQUESTED":
        message = `Requested to Campaign Successfully!`;
        break;
      case "DENIED":
        message = `Denied Campaign Successfully!`;
        break;
      case "WITHDRAW":
        message = `Withdrawn from Campaign Successfully!`;
        break;
      default:
        message = `Campaign marked as completed Successfully!`;
        break;
    }

    dispatch(addToast(TOASTS.success(message)));
  };

  const errorMSg = (action) => {
    let message;

    switch (action) {
      case "REQUESTED":
        message = `Failed to Request Campaign!`;
        break;
      case "DENIED":
        message = `Failed to Deny Campaign!`;
        break;
      case "WITHDRAW":
        message = `Failed to Withdraw from Campaign!`;
        break;
      default:
        message = `Failed to mark campaign as Completed!`;
        break;
    }

    dispatch(addToast(TOASTS.error(message)));
  };

  const createOrUpdateCampaign = useCallback(
    (action, message = "no message", screeningResponse = []) => {
      setIsLoading(true);

      const base_url = type === "insta" ? `/api/v1/ig` : `/api/v1/tk`;

      apiClient
        .post(`${base_url}/campaigns/${campaign.cid}`, {
          action: action,
          message: message,
          screeningResponse: screeningResponse,
        })
        .then(({ data }) => {
          if (onRefetch) {
            onRefetch();
          }

          successMsg(action);
        })
        .catch((err) => {
          errorMSg(action);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [campaign.cid, onRefetch]
  );

  const withdrawRequest = () => {
    createOrUpdateCampaign("WITHDRAW");
  };

  const makeCampaignCompleted = () => {
    // createOrUpdateCampaign("COMPLETED");
    setShowCampaignCompletionModal(true);
  };

  const renderButtons = () => {
    if (campaign.status === "APPROVED") {
      return (
        <div class="mt-6 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
          <Button
            onClick={viewCampaign}
            className="w-full"
            color="purple"
            outline
          >
            View
          </Button>
          <Button
            disabled={isLoading}
            icon
            onClick={makeCampaignCompleted}
            className="w-full"
            color="purple"
            outline
          >
            Mark as Complete
            {isLoading && <FaSpinner className="w-5 h-5 mx-2 animate-spin" />}
          </Button>
        </div>
      );
    } else if (campaign.status === "AVAILABLE") {
      return (
        <div class="mt-6 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
          <Button
            className="w-full"
            color="purple"
            outline
            onClick={viewCampaign}
            disabled={isLoading}
          >
            View and Apply
            {isLoading && <FaSpinner className="w-5 h-5 mx-2 animate-spin" />}
          </Button>
        </div>
      );
    } else if (campaign.status === "REJECTED") {
      return (
        <div class="mt-6 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
          <Button
            onClick={viewCampaign}
            className="w-full"
            color="purple"
            outline
          >
            View
          </Button>
          <Button className="w-full" color="purple" outline disabled>
            Rejected
          </Button>
        </div>
      );
    } else if (campaign.status === "COMPLETED") {
      return (
        <div class="mt-6 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
          <Button
            onClick={viewCampaign}
            className="w-full"
            color="purple"
            outline
          >
            View
          </Button>
          <Button className="w-full" color="purple" outline disabled>
            Completed
          </Button>
        </div>
      );
    } else if (campaign.status === "REQUESTED") {
      return (
        <div class="mt-6 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
          <Button
            onClick={viewCampaign}
            className="w-full"
            color="purple"
            outline
          >
            View
          </Button>
          <Button
            disabled={isLoading}
            onClick={withdrawRequest}
            className="w-full"
            color="purple"
            outline
          >
            Withdraw
            {isLoading && <FaSpinner className="w-5 h-5 mx-2 animate-spin" />}
          </Button>
        </div>
      );
    }
  };

  const fetchImage = useCallback(async () => {
    try {
      if (campaign?.logo) {
        const response = await apiClient.get(
          `/api/v1/common/file?file_key=${campaign?.logo}`,
          {
            responseType: "arraybuffer",
          }
        );
        const imageData = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        setFetchedImageData(imageData);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }, [campaign?.logo]);

  useEffect(() => {
    fetchImage();
  });

  return (
    <>
      {/* {renderFloatingIcon()} */}
      <HelperCard>
        <div className="flex gap-4 justify-between">
          <span className="flex-auto flex items-center gap-4">
            <img
              className="rounded-full w-12 h-12 mx-4 object-scale-down"
              src={`data:image/jpeg;base64, ${fetchedImageData}`}
              alt="Brand Logo"
            />

            <h3 className="truncate text-xl font-semibold">
              {campaign?.title}
            </h3>
          </span>

          <div className="">
            {campaign?.status !== "AVAILABLE" && (
              <StatusBadge text={campaign?.status} color={"green"} />
            )}
          </div>
        </div>

        <p className="my-4 mx-4 text-xl line-clamp-3 leading-normal">
          {campaign?.description}
        </p>

        {campaign?.status === "APPROVED" &&
          campaign?.statusMessage &&
          campaign?.statusMessage !== "" && (
            <div className="text-lg p-4 bg-red-200 text-gray-700 rounded">
              <span className="block font-semibold">
                Brand ask asked for resubmission with the following note:
              </span>
              <span>{campaign?.statusMessage}</span>
            </div>
          )}

        {renderButtons()}

        <CampaignModal
          isOpen={showCampaignModal}
          onClose={closeCampaignModal}
          onInfluencerAction={createOrUpdateCampaign}
          campaign={campaign}
          logoImg={fetchedImageData}
        />

        <CampaignCompletionModal
          isOpen={showCampaignCompletionModal}
          onClose={closeCampaignCompletionModal}
          campaign={campaign}
          type={type}
          onRefetch={onRefetch}
        />
      </HelperCard>
    </>
  );
};

export default CampaignCardInfluencer;
