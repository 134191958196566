import { ADMIN_ROUTES, SHARED_ROUTES } from "@constants/routes";
import Main from "@main/pages/Main";
import ErrorPage from "@main/pages/ErrorPage";
import { createBrowserRouter } from "react-router-dom";
import SettingsPage from "@main/pages/Settings";
import AdminDashboardPage from "./pages/Dashboard";
import RootPage from "./pages/RootPage";
import CreateCampagin from "./pages/Campaigns";
import CampaignApplications from "./pages/Campaigns/Applications";

const adminRouter = createBrowserRouter([
  {
    path: ADMIN_ROUTES.MAIN.path,
    element: <Main routes={ADMIN_ROUTES} />,
    errorElement: (
      <ErrorPage
        message="Nothing here! You may want to go to home page"
        buttonText="Sign up instead!"
        link="/"
      />
    ),
    children: [
      {
        path: SHARED_ROUTES.MAIN.path,
        element: <RootPage />,
      },
      {
        path: ADMIN_ROUTES.DASHBOARD.path,
        element: <AdminDashboardPage />,
      },
      {
        path: ADMIN_ROUTES.APPLICATIONS.path,
        element: <CampaignApplications />,
      },
      {
        path: ADMIN_ROUTES.CREATE_CAMPAIGN.path,
        element: <CreateCampagin />,
      },
      {
        path: SHARED_ROUTES.SETTINGS.path,
        element: <SettingsPage />,
      },
    ],
  },
]);

// if you want to add a page to this module please add to the upper array in such a way:

// {
//   path: ADMIN_ROUTES.SETTINGS.path,
//   element: <SettingsPage />,
// },

export default adminRouter;
