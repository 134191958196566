import { createSlice } from "@reduxjs/toolkit";
const INIT_STATE = {
  name: "Unkown Name",
  designation: "Unkown Designation",
  industry: "Unkown Industry",
  visibility: true,
  created: Date.now(),
  uid: {
    email: "Unkwon Email",
    last_login: Date.now(),
    uid: "",
  },
  aid: "",
};
export const advertiserSlice = createSlice({
  name: "advertiser",
  initialState: INIT_STATE,
  reducers: {
    resetAdvState: () => INIT_STATE,
    setAdvertiser: (_, { payload }) => {
      return { ...payload };
    },
  },
});

export const { setAdvertiser, resetAdvState } = advertiserSlice.actions;

export default advertiserSlice.reducer;
