import { useSelector } from "react-redux";
import {
  DARK_COLOR,
  DARK_SECONDARY_COLOR,
  DARK_TETARY_COLOR,
  LIGHT_COLOR,
  LIGHT_SECONDARY_COLOR,
  LIGHT_TETARY_COLOR,
  COLORS_RAW,
} from "@utils/tailwind.constants";
export default () => {
  const { darkMode } = useSelector((state) => state);
  const fgColor = darkMode ? LIGHT_COLOR : DARK_COLOR;
  const secondaryColor = darkMode
    ? DARK_SECONDARY_COLOR
    : LIGHT_SECONDARY_COLOR;
  const bgColor = darkMode ? DARK_COLOR : LIGHT_COLOR;
  const tetaryColor = darkMode ? DARK_TETARY_COLOR : LIGHT_TETARY_COLOR;

  const fgColorRaw = darkMode ? COLORS_RAW.LIGHT_COLOR : COLORS_RAW.DARK_COLOR;
  const secondaryRaw = darkMode
    ? COLORS_RAW.DARK_SECONDARY_COLOR
    : COLORS_RAW.LIGHT_SECONDARY_COLOR;
  const bgColorRaw = darkMode ? COLORS_RAW.DARK_COLOR : COLORS_RAW.LIGHT_COLOR;
  const tetaryColorRaw = darkMode
    ? COLORS_RAW.DARK_TETARY_COLOR
    : COLORS_RAW.LIGHT_TETARY_COLOR;
  return {
    bgColor,
    fgColor,
    secondaryColor,
    tetaryColor,
    fgColorRaw,
    secondaryRaw,
    bgColorRaw,
    tetaryColorRaw,
  };
};
