import { Carousel } from "flowbite-react";
import React from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    zIndex: 100,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirmation Dialog"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="min-w-[70vw] sm:min-w-[50vw] md:min-w-[40vw] lg:min-w-[30vw]">
        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t mb-8">
          <h3 className="text-3xl font-semibold">Logout</h3>
          <button
            type="button"
            onClick={onClose}
            className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-12 h-12 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
          >
            <IoClose className="w-8 h-8" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div>
          <h3 class="mb-5 text-xl font-medium text-gray-500 dark:text-gray-400">
            Are you sure you want to logout ?
          </h3>
          <button
            data-modal-hide="popup-modal"
            type="button"
            onClick={onConfirm}
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
          >
            Yes, I'm sure
          </button>
          <button
            onClick={onClose}
            data-modal-hide="popup-modal"
            type="button"
            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            No, cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
