import Main from "@main/pages/Main";
import ErrorPage from "@main/pages/ErrorPage";
import { LINKEDIN_ROUTES, SHARED_ROUTES } from "@constants/routes";
import { createBrowserRouter } from "react-router-dom";
import SettingsPage from "@main/pages/Settings";
import LiDashboard from "./pages/LiDashboard";

const linkedinRouter = createBrowserRouter([
  {
    path: SHARED_ROUTES.MAIN.path,
    element: <Main routes={LINKEDIN_ROUTES} />,
    errorElement: (
      <ErrorPage
        message="Nothing here! You may want to go to home page"
        buttonText="Sign up instead!"
        link='/'
      />
    ),
    children: [
      {
        path: SHARED_ROUTES.MAIN.path,
        element: <div className="w-full h-full flex justify-center items-center"><h1>Linkedin In App</h1></div>
      },
      {
        path: SHARED_ROUTES.SETTINGS.path,
        element: <SettingsPage/>
      },
      {
        path: LINKEDIN_ROUTES.DASHBOARD.path,
        element: <LiDashboard/>
      }
    ],
  },
]);

// if you want to add a page to this module please add to the upper array in such a way:

// {
//   path: ADMIN_ROUTES.SETTINGS.path,
//   element: <SettingsPage />,
// },

export default linkedinRouter;