import { createSlice } from "@reduxjs/toolkit";
const INIT_STATE = {
  username: "",
  followers_count: 0,
};
export const igSlice = createSlice({
  name: "igdata",
  initialState: INIT_STATE,
  reducers: {
    resetIGState: () => INIT_STATE,
    setIGData: (_, { payload }) => {
      return { ...payload };
    },
  },
});

export const { setIGData, resetIGState } = igSlice.actions;

export default igSlice.reducer;
