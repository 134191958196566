import React from "react";
import infinity from "@assets/infinity.png"
import influencerImg from "@assets/InfluencerImg.jpg"
import strength from "@assets/strength.png"
import grow from "@assets/grow.png"
import trust from "@assets/trust.png"
import "./InfluencerSection.css"

const InfluencerSection = () => {
  return (
    <section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white">Why Influencers turns to Influcracy</h2>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-10 h-10 text-primary-600 lg:w-10 lg:h-10 dark:text-primary-300" src={strength} alt="" />
              </div>
              <h3 class="mb-2 text-3xl font-bold dark:text-white">Expand Your Influence</h3>
              <p class="text-gray-500 dark:text-gray-400">Unlock exclusive brand collaborations and reach new horizons. Influcracy empowers your influencer journey with expert support and limitless opportunities for growth.
</p>
          </div>
          <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-10 h-10 text-primary-600 lg:w-10 lg:h-10 dark:text-primary-300" src={grow} alt="" />
              </div>
              <h3 class="mb-2 text-3xl font-bold dark:text-white">Elevate Your Presence
</h3>
              <p class="text-gray-500 dark:text-gray-400">Craft, Execute, Excel. Seamlessly collaborate with diverse brands to achieve monthly marketing milestones. Elevate your online presence, strengthening your authority in your niche.</p>
          </div>
          <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-10 h-10 text-primary-600 lg:w-10 lg:h-10 dark:text-primary-300" src={trust} alt="" />
              </div>
              <h3 class="mb-2 text-3xl font-bold dark:text-white">Connect and Prosper</h3>
              <p class="text-gray-500 dark:text-gray-400">Forge connections, foster trust. Our structured workflows and tailored permissions ensure security while you focus on audience expansion. Influcracy: Where influence thrives.</p>
          </div>
      </div>
  </div>
</section>
  );
};

export default InfluencerSection;
