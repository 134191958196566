import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import activateUser from "@api/auth/activate-user";

export default function useActivateUser(
  options
){
  return useMutation({
    mutationFn: activateUser,
    mutationKey: REACT_QUERY_KEYS.ACTIVATE_USER,
    ...options
  }
  );
};