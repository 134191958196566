import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import verifyFacebook from "@api/auth/verify-facebook";

export default function useVerifyFacebook(options) {
  return useMutation({
    mutationFn: verifyFacebook,
    mutationKey: REACT_QUERY_KEYS.VERIFY_FACEBOOK,
    ...options,
  });
}
