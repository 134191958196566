import APPS from "@constants/app-names";

export const CSS_COLORS = {
  LIGHT_BG: "var(--light-bg)",
  DARK_BG: "var(--dark-bg)",
  LIGHT_BG_SHADOW: "var(--light-bg-dark-shadow)",
  DARK_BG_SHADOW: "var(--dark-bg-dark-shadow)",
  PRIMARY_LIGHT: "var(--primary-light)",
  PRIMARY_DARK: "var(--primary-dark)",
  PRIMARY: "var(--primary)",
};

const THEMES = {
  advertiser: {
    "--light-bg": "#ffffff",
    "--light-bg-dark-shadow": "#bec8e4",
    "--light-bg-light-shadow": "#ffffff",
    "--dark-bg": "#444444",
    "--dark-bg-dark-shadow": "#363636",
    "--dark-bg-light-shadow": "#525252",
    "--white": "#ffffff",
    "--black": "#000000",
    "--primary": "#3f6de2",
    "--primary-dark": "#aa0660",
    "--primary-light": "#fa7ac0",
  },
  instagram: {
    "--light-bg": "#ffffff",
    "--light-bg-dark-shadow": "#bec8e4",
    "--light-bg-light-shadow": "#ffffff",
    "--dark-bg": "#444444",
    "--dark-bg-dark-shadow": "#363636",
    "--dark-bg-light-shadow": "#525252",
    "--white": "#ffffff",
    "--black": "#000000",
    "--primary": "#3f6de2",
    "--primary-dark": "#aa0660",
    "--primary-light": "#fa7ac0",
  },
  tiktok: {
    "--light-bg": "#ffffff",
    "--light-bg-dark-shadow": "#bec8e4",
    "--light-bg-light-shadow": "#ffffff",
    "--dark-bg": "#444444",
    "--dark-bg-dark-shadow": "#363636",
    "--dark-bg-light-shadow": "#525252",
    "--white": "#ffffff",
    "--black": "#000000",
    "--primary": "#3f6de2",
    "--primary-dark": "#aa0660",
    "--primary-light": "#fa7ac0",
  },
  main: {
    "--light-bg": "#ffffff",
    "--light-bg-dark-shadow": "#bec8e4",
    "--light-bg-light-shadow": "#ffffff",
    "--dark-bg": "#444444",
    "--dark-bg-dark-shadow": "#363636",
    "--dark-bg-light-shadow": "#525252",
    "--white": "#ffffff",
    "--black": "#000000",
    "--primary": "#3f6de2",
    "--primary-dark": "#aa0660",
    "--primary-light": "#fa7ac0",
  },
  linkedin: {
    "--light-bg": "#E9B7B9",
    "--light-bg-dark-shadow": "#ba9294",
    "--light-bg-light-shadow": "#ffdcde",
    "--dark-bg": "#292E35",
    "--dark-bg-dark-shadow": "#21252a",
    "--dark-bg-light-shadow": "#313740",
    "--primary": "#8672FB",
    "--primary-dark": "#4526f9",
    "--primary-light": "#c7befd",
    "--selector-color": "linear-gradient(270deg, #F4D6D6 -6.99%, #BD1999 100%)",
  },
  admin: {
    "--light-bg": "#ffffff",
    "--light-bg-dark-shadow": "#bec8e4",
    "--light-bg-light-shadow": "#ffffff",
    "--dark-bg": "#444444",
    "--dark-bg-dark-shadow": "#363636",
    "--dark-bg-light-shadow": "#525252",
    "--white": "#ffffff",
    "--black": "#000000",
    "--primary": "#3f6de2",
    "--primary-dark": "#aa0660",
    "--primary-light": "#fa7ac0",
  },
};

export default THEMES;
