import React from "react";
import { getPage } from "@utils/tailwind.constants";
import useAuthorized from "@hooks/useAuthorized";
import { CampaignsListView } from "./Campaigns";
import FlowBiteHeader from "@components/Flowbite/Header";

export default function AdvertisersDashboardPage({}) {
  useAuthorized({ isMain: true, isAdvertiser: true });

  return (
    <>
      <FlowBiteHeader toggle={true} />
      <div className={getPage("", false, false, true)}>
        <CampaignsListView />
      </div>
    </>
  );
}
