import Header from "@main/fragments/Header/Header";
import React from "react";
import Home from "@main/fragments/Home/Home";
import Services from "@main/fragments/Services/Services";
import Contact from "@main/fragments/Contact/Contact";
import Footer from "@main/fragments/Footer/Footer";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Empower Your Brand and Influence with Influcracy</title>
        <meta
          name="description"
          content="Unlock the potential of influencer marketing with Influcracy. Connect brands with influencers and maximize reach for impactful campaigns. Join us as an influencer or brand today!"
        />
        <meta name="keywords" content="Influcracy.com, influencer marketing, about us, brand collaborations, digital advertising" />
      </Helmet>
      <Header />
      <Home />
      {/* <Unique/> */}
      <Services />
      {/* <Process/> */}
      <Contact />
      {/* <Testimonials/> */}
      <Footer />
    </>
  );
};

export default HomePage;
