import React from "react";
import Toast from "@components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "@store/slices/rootSlice";

export default function NotificationCenter() {
  const {toasts} = useSelector((state) => state.root);
  const dispatch = useDispatch();
  return (
    <div
      style={{ transform: "translate(-50%)", zIndex: "9999" }}
      className="fixed w-4/5 left-1/2 md:w-1/3 top-0 flex items-center flex-col "
    >
      {toasts.map(({ id, type, message }) => (
        <Toast
          key={id}
          id={id}
          type={type}
          description={message}
          onClose={(id) => {
            dispatch(closeToast(id));
          }}
        />
      ))}
    </div>
  );
}
