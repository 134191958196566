import { useSelector } from "react-redux";
import useMediaQuery from "./useMediaQuery";
import getCurrentApp from '@utils/get-current-app'

function useAppSettings() {
  const {darkMode} = useSelector(state=>state.root);
  const  isMobile = useMediaQuery();
  const currentApp = getCurrentApp();

  return [darkMode, isMobile, currentApp]
}

export default useAppSettings;
