import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
// import { verifyAccount } from "@api/auth/auth";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import errorIcon from "@assets/ic-error.png";
import useNavigator from "@hooks/useNavigator";
import { MAIN_ROUTES } from "@constants/routes";
import useActivateUser from "@requests/auth/useActivateUser";
import useResetPassword from "@requests/auth/useResetPassword";

const UserVerification = () => {
  const location = useLocation();
  const [goto] = useNavigator();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);

  const [source, setSource] = useState(searchParams.get("src"));
  const [token, setToken] = useState(searchParams.get("token"));

  const [isLoading, setIsLoading] = useState(true);
  const [activationErrMsg, setActivationErrMsg] = useState("");
  const [isActivationSuccess, setIsActivationSuccess] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [remainingTime, setRemainingTime] = useState(3);

  const startTimer = useCallback(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const contineActivation = useActivateUser({
    onSuccess() {
      startTimer();
      setIsLoading(false);
      setIsRedirecting(true);
      setIsActivationSuccess(true);
    },
    onError(err) {
      startTimer();
      setIsLoading(false);
      setIsRedirecting(true);
      setIsActivationSuccess(false);
      //TODO: BIKI, please change the error format from the backend to enable this error parser everywhere!
      //httpErrorHandler(err, dispatch, goto);
      if (
        err.response.status &&
        err.response.status === 400 &&
        err.response.data.message
      ) {
        setActivationErrMsg(err.response.data.message);
        dispatch(addToast(TOASTS.error(err.response.data.message)));
      } else {
        setActivationErrMsg(err.response.data.message);
        dispatch(
          addToast(
            TOASTS.error(
              "Something went wrong at our end. Please check back later"
            )
          )
        );
      }
    },
  });

  const continueReset = useResetPassword({
    onSuccess() {
      startTimer();
      setIsLoading(false);
      setIsRedirecting(true);
      setIsActivationSuccess(true);
    },
    onError(err) {
      startTimer();
      setIsLoading(false);
      setIsRedirecting(true);
      setIsActivationSuccess(false);
      //TODO: BIKI, please change the error format from the backend to enable this error parser everywhere!
      //httpErrorHandler(err, dispatch, goto);
      if (
        err.response.status &&
        err.response.status === 400 &&
        err.response.data.message
      ) {
        setActivationErrMsg(err.response.data.message);
        dispatch(addToast(TOASTS.error(err.response.data.message)));
      } else {
        setActivationErrMsg("Error Resetting your account");
        dispatch(
          addToast(
            TOASTS.error(
              "Something went wrong at our end. Please check back later"
            )
          )
        );
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    setIsRedirecting(false);

    if (source === "register") {
      contineActivation.mutate({ activationToken: token });
    } else if (source === "reset") {
      continueReset.mutate({ resetLinkToken: token });
    }
  }, []);

  if (remainingTime === 0) {
    goto(MAIN_ROUTES.INFLUENCER_SIGN_IN.path);
    return null;
  }

  return (
    <div>
      <div className="flex flex-col justify-center items-center h-screen">
        {isLoading && <ClipLoader color="#004EAD" size={150} />}

        {!isLoading && !isActivationSuccess && (
          <img
            src={errorIcon}
            alt="Error"
            className="h-60 w-60 rounded-full object-cover"
          />
        )}

        <br />

        {isLoading && !isRedirecting && (
          <p>
            {source === "register" ? "Activating" : "Resetting"} your account...
          </p>
        )}

        {isRedirecting && isActivationSuccess && (
          <>
            <p className="text-xl">Account activated successfully.</p>

            <p className="text-xl">
              Redirecting you to the login page in {remainingTime} seconds...
            </p>
          </>
        )}

        {isRedirecting && !isActivationSuccess && (
          <>
            <p className="text-xl">{activationErrMsg}</p>

            <p className="text-xl">
              Redirecting you to the login page in {remainingTime} seconds...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default UserVerification;
