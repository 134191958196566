import APPS, { CURRENT_PROTOCOL, DEFAULT_DOMAIN } from "@constants/app-names";
import getCurrentApp from "@utils/get-current-app";
import { useNavigate } from "react-router-dom";

const useNavigation = () => {
  const navigate = useNavigate();
  const currentApp = getCurrentApp();
  const currentSubDomain = APPS[currentApp];

  const getCurrentAppHost = (app = currentApp) => {
    let subDomain = app ?? currentSubDomain;
    const domain = process.env.REACT_APP_MY_DOMAIN || DEFAULT_DOMAIN;

    subDomain = subDomain !== "" ? subDomain + "." : subDomain;

    const host = `${subDomain}${domain}`;
    return host;
  };

  const navigateTo = (path, app = currentSubDomain, searchPrams, props) => {
    const queryParams = searchPrams
      ? `?${new URLSearchParams(searchPrams).toString()}`
      : "";
    const url = `${path}${queryParams}`;
    
    if (currentSubDomain === app) {
      navigate(url, { state: props });
    } else {
      window.location.href = `${CURRENT_PROTOCOL}://${getCurrentAppHost(
        app
      )}${url}`;
    }
  };
  return [navigateTo, getCurrentAppHost];
};

export default useNavigation;
