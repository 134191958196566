import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import login from "@api/auth/log-in";

export default function useLogIn(
  options
){
  return useMutation({
    mutationFn: login,
    mutationKey: REACT_QUERY_KEYS.SIGN_IN,
    ...options
  }
  );
};