import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputField from "@components/FormInput/InputField";
import DeliverablesList from "./Deliverables/DeliverablesList";
import NewDeliverableModal from "./Deliverables/NewDeliverableModal";
import TextareaField from "@components/FormInput/TextareaField";
import FileUpload from "@components/FormInput/FileUpload";
import apiClient from "@utils/apiClient";
import TOASTS from "@constants/toasts";
import { addToast } from "@store/slices/rootSlice";
import { useDispatch } from "react-redux";
import MediaUploader from "@advertiser/components/FormInput/SampleMediaUploader";
import Loader from "@components/Loader";
import { ADV_ROUTES } from "@constants/routes";
import APPS from "@constants/app-names";
import useNavigation from "@hooks/useNavigator";
import SelectField from "@components/FormInput/SelectField";
import {
  SelectFieldWithCheckbox,
  Item,
  ItemHead,
} from "@components/FormInput/SelectFieldWithCheckbox";
import { TwoColumnGrid } from "@components/Accesbility";
import ChipsBadge from "@components/Flowbite/ChipsBadge";
import { Button } from "flowbite-react";
import { FaPlus } from "react-icons/fa";
import countries from "@utils/countries.json";
import { Tab, Tabs } from "@components/TabSelector/Tabs";
import { TabItem, TabItems } from "@components/TabSelector/TabItems";

const defaultValues = {
  title: "",
  description: "",
  logo: "",
  medias: [],
  min: 0,
  max: 0,
  instaDeliverableFields: [
    {
      min: null,
      max: null,
      story_num: null,
      story_link_num: null,
      image_num: null,
      video_num: null,
      bio_link_num: null,
      colab_price: null,
      prod_value: null,
      disc: null,
      prod_link: "",
    },
  ],
  tiktokDeliverableFields: [
    {
      min: null,
      max: null,
      tiktok_video_num: null,
      tiktok_story_num: null,
      bio_link_num: null,
      colab_price: null,
      prod_value: null,
      disc: null,
      prod_link: "",
    },
  ],
  target: {
    age: {
      min: 0,
      max: 100,
    },
    gender: "",
    location: "",
  },
};

function InstagramCampaignFragment() {
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ defaultValues, shouldUnregister: false });

  const dispatch = useDispatch();
  const [goto] = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [activePlatform, setActivePlatform] = useState(0);

  const [selectedFile, setSelectedFile] = useState();
  const [fileError, setFileError] = useState();
  const [deliverableError, setDeliverableError] = useState(false);

  const [isQuestionBtnOnFoucs, setIsQuestionBtnOnFocus] = useState(false);
  const [questionValue, setQuestionValue] = useState("");

  const [mediaFiles, setMediaFiles] = useState({});

  const {
    fields: instaDeliverableFields,
    append: appendInstaDeliverable,
    update: updateInstaDeliverable,
    remove: removeInstaDeliverable,
  } = useFieldArray({
    name: "instaDeliverableFields",
    control,
  });

  const {
    fields: tiktokDeliverableFields,
    append: appendtiktokDeliverable,
    update: updatetiktokDeliverable,
    remove: removetiktokDeliverable,
  } = useFieldArray({
    name: "tiktokDeliverableFields",
    control,
  });
  const {
    fields: questionFields,
    append: appendQuestions,
    remove: removeQuestions,
  } = useFieldArray({
    name: "questions",
    control,
  });

  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const [showRequirements, setShowRequirements] = useState(false);
  const [createType, setCreateType] = useState("quick");

  const onSubmit = async (data) => {
    if (!selectedFile) {
      setFileError({ message: "Please select a file." });
      return;
    }

    if (
      instaDeliverableFields.length < 2 &&
      tiktokDeliverableFields.length < 2
    ) {
      setDeliverableError(true);
      return;
    }

    setIsLoading(true);

    let brand_logo_key = "";

    const formData = new FormData();
    formData.append("file", selectedFile);

    await apiClient
      .post("/api/v1/common/file/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        brand_logo_key = res.data.key;
      });

    const medias = [];
    const promises = [];

    for (const key in mediaFiles) {
      const formData = new FormData();
      formData.append("file", mediaFiles[key]);

      const promise = apiClient
        .post("/api/v1/common/file/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          medias.push(res.data.key);
        });

      promises.push(promise);
    }

    await Promise.all(promises);

    let instaMin = null,
      instaMax = null,
      tiktokMin = null,
      tiktokMax = null;

    if (instaDeliverableFields.length > 1) {
      const { min, max } = findMinMaxFromRanges(
        instaDeliverableFields.slice(0, instaDeliverableFields.length - 1)
      );

      instaMin = min;
      instaMax = max;
    }

    if (tiktokDeliverableFields.length > 1) {
      const { min, max } = findMinMaxFromRanges(
        tiktokDeliverableFields.slice(0, tiktokDeliverableFields.length - 1)
      );
      tiktokMin = min;
      tiktokMax = max;
    }

    const instaDeliverables = instaDeliverableFields
      .slice(0, instaDeliverableFields.length - 1)
      .map(({ id, ...deliverable }) => deliverable);

    const tiktokDeliverables = tiktokDeliverableFields
      .slice(0, tiktokDeliverableFields.length - 1)
      .map(({ id, ...deliverable }) => deliverable);

    const payload = {
      title: data.title,
      description: data.description,
      min: { insta: instaMin, tiktok: tiktokMin },
      max: { insta: instaMax, tiktok: tiktokMax },
      logo: brand_logo_key,
      medias: medias,
      deliverables: {
        insta: instaDeliverables,
        tiktok: tiktokDeliverables,
      },
      questions: questionFields.map(({ id, ...question }) => question.value),
      target: {
        age: {
          min: +data.target.age.min,
          max: +data.target.age.max,
        },
        gender: selectedGenders.join(","),
        location: selectedCountries.join(","),
      },
    };

    apiClient
      .post("/api/v1/adv/campaigns/", payload)
      .then((res) => {
        dispatch(addToast(TOASTS.success("Campaign Created Successfully!")));
        goto(ADV_ROUTES.DASHBOARD.path, APPS["advertiser"]);
      })
      .catch((err) => {
        dispatch(addToast(TOASTS.error("Failed to create campaign!")));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const findMinMaxFromRanges = (ranges) => {
    if (ranges.length === 0) {
      return null;
    }

    let minVal = ranges[0].min;
    let maxVal = ranges[0].max;

    for (const range of ranges) {
      if (range.min < minVal) {
        minVal = range.min;
      }

      if (range.max > maxVal) {
        maxVal = range.max;
      }
    }

    return { min: minVal, max: maxVal };
  };

  const handleFileChange = (file) => {
    if (file) {
      setFileError(null);
    }

    setSelectedFile(file);
  };

  const handleMediaFileSelected = (id, file) => {
    setMediaFiles((prevFiles) => ({
      ...prevFiles,
      [id]: file,
    }));
  };

  const onError = (error) => {
    if (!selectedFile) {
      setFileError({ message: "Please select a file." });
    }

    if (instaDeliverableFields.length < 2) {
      setDeliverableError(true);
      return;
    }
  };

  useEffect(() => {
    if (showRequirements) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [showRequirements]);

  const handleCloseModal = () => {
    setShowRequirements(false);
    if (activePlatform === 0) {
      updateInstaDeliverable(instaDeliverableFields.length - 1, {
        ...defaultValues.instaDeliverableFields[0],
      });
    } else {
      updatetiktokDeliverable(tiktokDeliverableFields.length - 1, {
        ...defaultValues.tiktokDeliverableFields[0],
      });
    }
  };

  const handleAddRequirements = () => {
    setShowRequirements(false);
    setDeliverableError(false);
    if (activePlatform === 0) {
      appendInstaDeliverable({ ...defaultValues.instaDeliverableFields[0] });
    } else {
      appendtiktokDeliverable({ ...defaultValues.tiktokDeliverableFields[0] });
    }
  };

  const handleRangeRemove = (idx) => {
    if (activePlatform === 0) {
      removeInstaDeliverable(idx);
    } else {
      removetiktokDeliverable(idx);
    }
  };

  const renderMediaUploaders = () => {
    const uploaders = [];
    for (let i = 1; i <= 4; i++) {
      uploaders.push(
        <MediaUploader
          id={`media-${i}`}
          onFileSelected={handleMediaFileSelected}
        />
      );
    }
    return uploaders;
  };

  const handleSelect = (selectedValue) => {
    setCreateType(selectedValue);
  };

  const handleCreate = () => {
    if (createType === "advance") {
      handleSubmit(onSubmit, onError);
    } else {
      handleQuickCreate();
    }
  };

  const handleQuickCreate = () => {
    setIsLoading(true);
    const desc = getValues("description");
    apiClient
      .post("/api/v1/adv/campaigns/draft/", { description: desc })
      .then((res) => {
        dispatch(addToast(TOASTS.success("Campaign Created Successfully!")));
      })
      .catch((err) => {
        dispatch(addToast(TOASTS.error("Failed to create campaign!")));
      })
      .finally(() => {
        setIsLoading(false);
        goto(ADV_ROUTES.DASHBOARD.path, APPS["advertiser"]);
      });
  };

  const continents = [
    "Asia",
    "Africa",
    "Europe",
    "North America",
    "South America",
  ];

  const handleGenderChange = (id) => {
    const isAlreadyExist = selectedGenders.includes(id);

    if (isAlreadyExist) {
      setSelectedGenders(selectedGenders.filter((gender) => gender !== id));
    } else {
      setSelectedGenders([...selectedGenders, id]);
    }
  };

  const handleGenderRemove = (id) => {
    setSelectedGenders(selectedGenders.filter((gender) => gender !== id));
  };

  const handleCountriesChange = (id) => {
    const isAlreadyExist = selectedCountries.includes(id);

    if (isAlreadyExist) {
      setSelectedCountries(
        selectedCountries.filter((country) => country !== id)
      );
    } else {
      setSelectedCountries([...selectedCountries, id]);
    }
  };

  const handleCountriesRemove = (id) => {
    setSelectedCountries(selectedCountries.filter((country) => country !== id));
  };

  const addQuestion = () => {
    appendQuestions({ value: questionValue });
    setQuestionValue("");
  };

  // useEffect(() => {
  //   appendInstaDeliverable({
  //     ...defaultValues.instaDeliverableFields[0],
  //   });

  //   appendtiktokDeliverable({
  //     ...defaultValues.tiktokDeliverableFields[0],
  //   });
  // }, []);

  return (
    <>
      {isLoading && <Loader />}

      <div className="flex flex-col items-stretch justify-start w-full gap-2 p-4">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <SelectField
            id="campaign_type_select"
            label="Campaign creation type"
            onSelect={handleSelect}
            defaultValue={createType}
            className="mb-4"
          >
            <option value="quick">Quick</option>
            <option value="advance">Advanced</option>
          </SelectField>

          <Controller
            control={control}
            name="title"
            rules={{
              required: { value: true, message: "Title is required" },
              minLength: {
                value: 3,
                message: "Please enter at least 3 characters",
              },
            }}
            render={({ field }) =>
              createType === "advance" && (
                <InputField
                  label="Title*"
                  placeholder="Enter campaign title"
                  error={errors[field.name]}
                  {...field}
                />
              )
            }
          />

          <Controller
            control={control}
            name="description"
            rules={{
              required: { value: true, message: "Description is required" },
              minLength: {
                value: 10,
                message: "Please enter at least 10 characters",
              },
            }}
            render={({ field }) => (
              <TextareaField
                label="Description*"
                placeholder="Describe about your campaign"
                error={errors[field.name]}
                {...field}
              />
            )}
          />

          <div style={{ display: createType === "advance" ? "block" : "none" }}>
            <FileUpload
              label="Brand Logo*"
              error={fileError}
              onChange={handleFileChange}
            />

            <div className="mt-4 border border-gray-300 rounded-lg p-2">
              <Tabs
                active={activePlatform}
                onChange={(index) => {
                  setActivePlatform(index);
                }}
                tabStyle="flowbite"
              >
                <Tab>
                  <>
                    <span>Instagram</span>
                    <span className="ml-2 inline-flex items-center justify-center w-8 h-8 mr-2 text-xl font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                      <span>{instaDeliverableFields.length - 1}</span>
                    </span>
                  </>
                </Tab>
                <Tab>
                  <>
                    <span>Tiktok</span>
                    <span className="ml-2 inline-flex items-center justify-center w-8 h-8 mr-2 text-xl font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                      <span>{tiktokDeliverableFields.length - 1}</span>
                    </span>
                  </>
                </Tab>
              </Tabs>

              <TabItems active={activePlatform}>
                <TabItem>
                  <p className="block mt-8 mb-2 text-2xl font-medium text-gray-900">
                    Deliverables*
                  </p>

                  <DeliverablesList
                    type={activePlatform === 0 ? "instagram" : "tiktok"}
                    ranges={instaDeliverableFields}
                    handleClose={handleRangeRemove}
                  />

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRequirements(true);
                    }}
                    className="w-full py-2.5 px-5 m-2 mb-2 text-2xl font-medium text-purple-900 focus:outline-none bg-white rounded border border-purple-800 hover:bg-purple-100 hover:text-purple-700 focus:z-10"
                  >
                    Add requirements for Instagram
                  </button>
                </TabItem>
                <TabItem>
                  <p className="block mt-8 mb-2 text-2xl font-medium text-gray-900">
                    Deliverables*
                  </p>

                  <DeliverablesList
                    type={activePlatform === 0 ? "instagram" : "tiktok"}
                    ranges={tiktokDeliverableFields}
                    handleClose={handleRangeRemove}
                  />

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRequirements(true);
                    }}
                    className="w-full py-2.5 px-5 m-2 mb-2 text-2xl font-medium text-purple-900 focus:outline-none bg-white rounded border border-purple-800 hover:bg-purple-100 hover:text-purple-700 focus:z-10"
                  >
                    Add requirements for Tiktok
                  </button>
                </TabItem>
              </TabItems>
            </div>

            {deliverableError && (
              <p className="block mb-2 text-lg font-medium text-red-500">
                Please add at least 1 deliverable
              </p>
            )}

            <p className="block my-8 text-2xl font-medium text-gray-900">
              Sample Media
            </p>

            <div className="grid grid-flow-col grid-cols-4 gap-4 my-2">
              {renderMediaUploaders()}
            </div>
          </div>

          <div style={{ display: createType === "advance" ? "block" : "none" }}>
            <p className="block my-8 text-2xl font-medium text-gray-900">
              Target Influencers
            </p>

            <div className="ml-8">
              <div className="grid grid-flow-col grid-cols-4 gap-4 items-center">
                <div className="col-span-1">
                  <p className="block my-8 text-xl font-medium text-gray-900">
                    Target Age
                  </p>
                </div>

                <TwoColumnGrid className="mt-4 col-span-3">
                  <div className="flex">
                    <Controller
                      control={control}
                      name={`target.age.min`}
                      rules={{
                        required: {
                          value: true,
                          message:
                            "Please enter minimum target age of influencers",
                        },
                        min: {
                          value: 0,
                          message:
                            "Minimum target age Should be greater than or equal to 0",
                        },
                        max: {
                          value: 90,
                          message: "Minimum target age Should be less than 90",
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <div className="flex flex-col">
                            <InputField
                              type="number"
                              placeholder="Enter Minimum target age"
                              error={errors.target?.age?.min}
                              {...field}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="flex">
                    <Controller
                      control={control}
                      name={`target.age.max`}
                      rules={{
                        required: {
                          value: true,
                          message:
                            "Please enter maximum target age of influencers",
                        },
                        min: {
                          value: 10,
                          message:
                            "Maximum target age Should be greater than or equal to 10",
                        },
                        max: {
                          value: 100,
                          message: "Minimum target age Should be less than 100",
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <InputField
                            type="number"
                            placeholder="Enter Maximum target age"
                            error={errors.target?.age?.max}
                            {...field}
                          />
                        );
                      }}
                    />
                  </div>
                </TwoColumnGrid>
              </div>

              <div className="flex flex-col gap-4">
                <div className="grid grid-flow-col grid-cols-4 gap-4 items-center">
                  <div className="col-span-1">
                    <p className="block my-8 text-xl font-medium text-gray-900">
                      Gender
                    </p>
                  </div>

                  <div className="grid grid-flow-row gap-4 col-span-3">
                    <div className="flex">
                      {selectedGenders.map((gender) => (
                        <ChipsBadge
                          text={gender}
                          color="gray"
                          onClick={handleGenderRemove}
                        />
                      ))}
                    </div>

                    <SelectFieldWithCheckbox
                      selecteds={selectedGenders}
                      onSelect={handleGenderChange}
                      label={"Select Required Genders"}
                      data={["Male", "Female", "Other"]}
                    />
                  </div>
                </div>

                <div className="grid grid-flow-col grid-cols-4 gap-4 items-center">
                  <div className="col-span-1">
                    <p className="block my-8 text-xl font-medium text-gray-900">
                      Region
                    </p>
                  </div>

                  <div className="grid col-span-3 gap-4">
                    <div className="flex">
                      {selectedCountries.map((gender) => (
                        <ChipsBadge
                          text={gender}
                          color="gray"
                          onClick={handleCountriesRemove}
                        />
                      ))}
                    </div>

                    <SelectFieldWithCheckbox
                      selecteds={selectedCountries}
                      onSelect={handleCountriesChange}
                      showSearch={true}
                      label="Select Required Regions"
                      data={countries}
                    >
                      {continents.map((region) => (
                        <ItemHead id={region} label={region}>
                          {countries[region].map((country) => {
                            return <Item id={country} />;
                          })}
                        </ItemHead>
                      ))}
                    </SelectFieldWithCheckbox>
                  </div>
                </div>
              </div>
            </div>

            <p className="block my-8 text-2xl font-medium text-gray-900">
              Screening Questions
            </p>

            <div className="ml-8">
              <div>
                <ol>
                  {questionFields.map((question, index) => (
                    <li key={index} className="w-full flex">
                      <div className="grow">
                        Q{index + 1}. {question.value}
                      </div>

                      <div>
                        <button
                          onClick={() => removeQuestions(index)}
                          className="p-1 ml-auto border-0 float-right text-4xl leading-none font-semibold outline-none focus:outline-none text-purple-700 focus:text-gray-700"
                        >
                          <span>×</span>
                        </button>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>

              {questionFields.length < 3 && (
                <div className="w-full flex items-center justify-center gap-4">
                  <InputField
                    rootClass="grow"
                    type="text"
                    name="question"
                    onChange={(e) => setQuestionValue(e.target.value)}
                    value={questionValue}
                    placeholder="Enter question"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") addQuestion();
                    }}
                  />

                  <Button
                    color="purple"
                    size="lg"
                    outline={true}
                    onClick={addQuestion}
                    onMouseOver={() => setIsQuestionBtnOnFocus(true)}
                    onMouseOut={() => setIsQuestionBtnOnFocus(false)}
                  >
                    <FaPlus
                      color={isQuestionBtnOnFoucs ? "white" : "#5521b5"}
                      className="mr-2 w-6 h-6"
                    />
                    Add Question
                  </Button>
                </div>
              )}
            </div>
          </div>

          <br />

          <div onClick={handleCreate} className="w-full my-8">
            <button className="w-full h-12 px-6 text-indigo-100 transition-colors duration-150 bg-purple-800 rounded-lg focus:shadow-outline hover:bg-purple-900">
              Create
            </button>
          </div>
        </form>

        <NewDeliverableModal
          type={activePlatform === 0 ? "instagram" : "tiktok"}
          isModalOpen={showRequirements}
          handleClose={handleCloseModal}
          handleAdd={handleAddRequirements}
          idx={
            activePlatform === 0
              ? instaDeliverableFields.length - 1
              : tiktokDeliverableFields.length - 1
          }
          trigger={trigger}
          control={control}
          errors={errors}
        />
      </div>
    </>
  );
}

export default InstagramCampaignFragment;
