import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import verifyGoogle from "@api/auth/verify-google";

export default function useVerifyGoogle(options) {
  return useMutation({
    mutationFn: verifyGoogle,
    mutationKey: REACT_QUERY_KEYS.RESET_PASS,
    ...options,
  });
}
