import React, { useCallback, useEffect, useState } from "react";
import { getPage } from "@utils/tailwind.constants";
import useAuthorized from "@hooks/useAuthorized";
import { useSelector } from "react-redux";
import useNavigator from "@hooks/useNavigator";
import { Tab, Tabs } from "@components/TabSelector/Tabs";
import { TabItem, TabItems } from "@components/TabSelector/TabItems";
import { Button, Dropdown } from "flowbite-react";
import apiClient from "@utils/apiClient";
import CampaignCard from "@components/CampaignCard";
import Loader from "@components/Loader";
import HelperCard from "@components/Flowbite/Card";
import StatusBadge from "@components/Flowbite/StatusBadge";
import { Card } from "ui-neumorphism";
import { ADMIN_ROUTES } from "@constants/routes";
import getCurrentApp from "@utils/get-current-app";
import APPS from "@constants/app-names";

export default function AdminDashboardPage({}) {
  useAuthorized({ isMain: true, isAdvertiser: true, isAdmin: true });
  const admin = useSelector((state) => state.admin);
  const [active, setActive] = useState(0);
  const [goTo] = useNavigator();
  const [activeFilter, setActiveFilter] = useState("ALL");
  const [campaigns, setCampaigns] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentApp = getCurrentApp();
  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const fetchCampaigns = useCallback(() => {
    setIsLoading(true);

    apiClient
      .get("/api/v1/admin/campaigns", {
        params: { filter_by: activeFilter },
      })
      .then((res) => {
        setCampaigns(res.data.campaigns);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [activeFilter]);

  const fetchDraftCampaigns = useCallback(() => {
    setIsLoading(true);

    apiClient
      .get("/api/v1/admin/campaigns/drafts")
      .then((res) => {
        setDrafts(res.data.draft_campaigns);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const processCampaign = (draft) => {
    goTo(ADMIN_ROUTES.CREATE_CAMPAIGN.path, currentApp, null, {
      isEdit: true,
      campaign: draft,
    });
  };

  useEffect(() => {
    fetchCampaigns();
    fetchDraftCampaigns();
  }, [activeFilter]);

  const tabHeaders = (
    <Tabs
      active={active}
      onChange={(index) => setActive(index)}
      tabStyle="flowbite"
      align="left"
    >
      <Tab>Campaigns</Tab>
      <Tab>Drafts</Tab>

      <Tab type="filter">
        <Dropdown label={activeFilter}>
          <Dropdown.Item onClick={() => handleFilterChange("ALL")}>
            ALL
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilterChange("PENDING")}>
            PENDING
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilterChange("APPROVED")}>
            APPROVED
          </Dropdown.Item>
        </Dropdown>
      </Tab>
    </Tabs>
  );

  return (
    <div className={getPage("", false, false)}>
      {isLoading && <Loader />}

      <div className="mb-8">Admin Dashboard</div>

      {tabHeaders}

      <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        {active === 0 &&
          campaigns.map((campaign) => (
            <CampaignCard
              key={campaign.id}
              campaign={campaign}
              src="admin"
              onRefetch={fetchCampaigns}
            />
          ))}

        {active === 1 &&
          drafts.map((draft) => (
            <HelperCard>
              <div className="flex flex-col items-end w-full gap-2 pt-4 px-4">
                <StatusBadge text={"processing"} color={"red"} />
              </div>

              <Card
                className="max-h-[15vh] overflow-hidden p-2 m-6"
                outlined={true}
              >
                <p className="my-4 mx-4 text-xl line-clamp-3 leading-normal">
                  {draft.description}
                </p>
              </Card>

              <div className="flex justify-end">
                <Button
                  onClick={() => processCampaign(draft)}
                  color="purple"
                  outline
                >
                  Process
                </Button>
              </div>
            </HelperCard>
          ))}
      </div>
    </div>
  );
}
