import { useMutation } from "react-query";
import REACT_QUERY_KEYS from "@constants/react-query-keys";
import verifyLinkedin from "@api/auth/verify-linkedin";

export default function useVerifyLinkedin(options) {
  return useMutation({
    mutationFn: verifyLinkedin,
    mutationKey: REACT_QUERY_KEYS.RESET_PASS,
    ...options,
  });
}
