import APPS from "@constants/app-names";
import useColorScheme from "@hooks/useColorScheme";
import useNavigator from "@hooks/useNavigator";
import {
  BUTTON,
  FONT_SEMIBOLD,
  LINKEDIN_COLOR,
  PARAGRAPH,
  SUBTITLE,
  TITLE,
} from "@utils/tailwind.constants";

export default function ErrorPage({ message, buttonText, link }) {
  const { bgColor, fgColor } = useColorScheme();
  const [goTo] = useNavigator();
  return (
    <div
      className={`w-screen h-screen bg-${bgColor} flex flex-col justify-center items-center gap-10`}
    >
      <h1 className={`${TITLE} text-${fgColor}`}>Opps ...</h1>
      <h2 className={`${SUBTITLE} text-${fgColor} text-center`}> {message}</h2>
      {buttonText && (
        <a
          onClick={(e) => {
            e.preventDefault();
            goTo(link);
          }}
          className={`${BUTTON} bg-${LINKEDIN_COLOR}`}
        >
          <p className={`${PARAGRAPH} ${FONT_SEMIBOLD} text-white`}>
            {buttonText}
          </p>
        </a>
      )}
    </div>
  );
}
