// FormInput.js
import React from "react";

const FormInput = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  required,
  min,
  max,
}) => (
  <>
    <div class="mb-6 relative">
      <input
        type={type}
        id="floating_outlined"
        class="h-[5rem] my-[3rem] bg-gray-50 border border-gray-300 text-gray-900 text-[15px] rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500block appearance-none peer"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
      />
      <label
        for="floating_outlined"
        class="absolute text-xl text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-purple-600 peer-focus:dark:text-purple-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {label}
      </label>
    </div>
  </>
);

export default FormInput;
