import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputField from "@components/FormInput/InputField";
import DeliverablesList from "./Deliverables/DeliverablesList";
import NewDeliverableModal from "./Deliverables/NewDeliverableModal";
import TextareaField from "@components/FormInput/TextareaField";
import FileUpload from "@components/FormInput/FileUpload";
import apiClient from "@utils/apiClient";
import TOASTS from "@constants/toasts";
import { addToast } from "@store/slices/rootSlice";
import { useDispatch } from "react-redux";
import MediaUploader from "@advertiser/components/FormInput/SampleMediaUploader";
import Loader from "@components/Loader";
import { ADMIN_ROUTES, ADV_ROUTES } from "@constants/routes";
import APPS from "@constants/app-names";
import useNavigation from "@hooks/useNavigator";
import SelectField from "@components/FormInput/SelectField";

function InstagramCampaignFragment({ stateData }) {
  const defaultValues = {
    title: "",
    description: stateData.isEdit ? stateData.campaign.description : "",
    logo: "",
    medias: [],
    min: 0,
    max: 0,
    deliverables: [
      {
        min: null,
        max: null,
        story_num: null,
        story_link_num: null,
        image_num: null,
        video_num: null,
        bio_link_num: null,
        colab_price: null,
        prod_value: null,
        disc: null,
        prod_link: "",
      },
    ],
  };

  const {
    register,
    trigger,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues,
  } = useForm({ defaultValues, shouldUnregister: false });

  const dispatch = useDispatch();
  const [goto] = useNavigation();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [fileError, setFileError] = useState();
  const [deliverableError, setDeliverableError] = useState(false);

  const [mediaFiles, setMediaFiles] = useState({});

  const { fields, append, update, remove } = useFieldArray({
    name: "deliverables",
    control,
  });

  const [showRequirements, setShowRequirements] = useState(false);
  const [createType, setCreateType] = useState(
    stateData.isEdit ? "advance" : "quick"
  );

  const onSubmit = async (data) => {
    if (!selectedFile) {
      setFileError({ message: "Please select a file." });
      return;
    }

    if (fields.length < 2) {
      setDeliverableError(true);
      return;
    }

    setIsLoading(true);

    let brand_logo_key = "";

    const formData = new FormData();
    formData.append("file", selectedFile);

    await apiClient
      .post("/api/v1/common/file/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        brand_logo_key = res.data.key;
      });

    const medias = [];
    const promises = [];

    for (const key in mediaFiles) {
      const formData = new FormData();
      formData.append("file", mediaFiles[key]);

      const promise = apiClient
        .post("/api/v1/common/file/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          medias.push(res.data.key);
        });

      promises.push(promise);
    }

    await Promise.all(promises);

    const { min, max } = findMinMaxFromRanges(
      fields.slice(0, fields.length - 1)
    );

    const payload = {
      title: data.title,
      description: data.description,
      min: min,
      max: max,
      logo: brand_logo_key,
      medias: medias,
      deliverables: fields
        .slice(0, fields.length - 1)
        .map(({ id, ...deliverable }) => deliverable),
    };

    console.log(payload);

    apiClient
      .post(
        `/api/v1/admin/campaigns/drafts/${stateData.campaign.dcid}`,
        payload
      )
      .then((res) => {
        dispatch(
          addToast(TOASTS.success("Draft Campaign Processed Successfully!"))
        );
        goto(ADMIN_ROUTES.DASHBOARD.path, APPS["admin"]);
      })
      .catch((err) => {
        dispatch(addToast(TOASTS.error("Failed to create campaign!")));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const findMinMaxFromRanges = (ranges) => {
    if (ranges.length === 0) {
      return null;
    }

    let minVal = ranges[0].min;
    let maxVal = ranges[0].max;

    for (const range of ranges) {
      if (range.min < minVal) {
        minVal = range.min;
      }

      if (range.max > maxVal) {
        maxVal = range.max;
      }
    }

    return { min: minVal, max: maxVal };
  };

  const handleFileChange = (file) => {
    if (file) {
      setFileError(null);
    }

    setSelectedFile(file);
  };

  const handleMediaFileSelected = (id, file) => {
    setMediaFiles((prevFiles) => ({
      ...prevFiles,
      [id]: file,
    }));
  };

  const onError = (error) => {
    if (!selectedFile) {
      setFileError({ message: "Please select a file." });
    }

    if (fields.length < 2) {
      setDeliverableError(true);
      return;
    }
  };

  useEffect(() => {
    if (showRequirements) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [showRequirements]);

  const handleCloseModal = () => {
    setShowRequirements(false);
    update(fields.length - 1, { ...defaultValues.deliverables[0] });
  };

  const handleAddRequirements = () => {
    setShowRequirements(false);
    setDeliverableError(false);
    append({ ...defaultValues.deliverables[0] });
  };

  const handleRangeRemove = (idx) => {
    remove(idx);
  };

  const renderMediaUploaders = () => {
    const uploaders = [];
    for (let i = 1; i <= 4; i++) {
      uploaders.push(
        <MediaUploader
          id={`media-${i}`}
          onFileSelected={handleMediaFileSelected}
        />
      );
    }
    return uploaders;
  };

  const handleSelect = (selectedValue) => {
    setCreateType(selectedValue);
  };

  const handleCreate = () => {
    if (createType === "advance") {
      handleSubmit(onSubmit, onError);
    } else {
      handleQuickCreate();
    }
  };

  const handleQuickCreate = () => {
    setIsLoading(true);
    const desc = getValues("description");
    apiClient
      .post(`/api/v1/admin/campaigns/draft/`, {
        description: desc,
      })
      .then((res) => {
        dispatch(addToast(TOASTS.success("Campaign Created Successfully!")));
      })
      .catch((err) => {
        dispatch(addToast(TOASTS.error("Failed to create campaign!")));
      })
      .finally(() => {
        setIsLoading(false);
        goto(ADV_ROUTES.DASHBOARD.path, APPS["advertiser"]);
      });
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="flex flex-col items-stretch justify-start w-full gap-2 p-4">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {!stateData.isEdit && (
            <SelectField
              id="campaign_type_select"
              label="Campaign creation type"
              onSelect={handleSelect}
              defaultValue={createType}
              className="mb-4"
            >
              <option value="quick">Quick</option>
              <option value="advance">Advanced</option>
            </SelectField>
          )}
          <Controller
            control={control}
            name="title"
            rules={{
              required: { value: true, message: "Title is required" },
              minLength: {
                value: 3,
                message: "Please enter at least 3 characters",
              },
            }}
            render={({ field }) =>
              createType === "advance" && (
                <InputField
                  label="Title*"
                  placeholder="Enter campaign title"
                  error={errors[field.name]}
                  {...field}
                />
              )
            }
          />

          <Controller
            control={control}
            name="description"
            rules={{
              required: { value: true, message: "Description is required" },
              minLength: {
                value: 10,
                message: "Please enter at least 10 characters",
              },
            }}
            render={({ field }) => (
              <TextareaField
                label="Description*"
                placeholder="Describe about your campaign"
                error={errors[field.name]}
                {...field}
              />
            )}
          />

          <div style={{ display: createType === "advance" ? "block" : "none" }}>
            <FileUpload
              label="Brand Logo*"
              error={fileError}
              onChange={handleFileChange}
            />

            <p className="block mt-8 mb-2 text-2xl font-medium text-gray-900">
              Deliverables*
            </p>

            <DeliverablesList ranges={fields} handleClose={handleRangeRemove} />

            <button
              onClick={(e) => {
                e.preventDefault();
                setShowRequirements(true);
              }}
              className="w-full py-2.5 px-5 m-2 mb-2 text-2xl font-medium text-purple-900 focus:outline-none bg-white rounded border border-purple-800 hover:bg-purple-100 hover:text-purple-700 focus:z-10"
            >
              Add requirements
            </button>

            {deliverableError && (
              <p className="block mb-2 text-lg font-medium text-red-500">
                Please add at least 1 deliverable
              </p>
            )}

            <p className="block my-8 text-2xl font-medium text-gray-900">
              Sample Media
            </p>

            <div className="grid grid-flow-col grid-cols-4 gap-4 my-2">
              {renderMediaUploaders()}
            </div>
          </div>

          <br />

          <div onClick={handleCreate} className="w-full my-8">
            <button className="w-full h-12 px-6 text-indigo-100 transition-colors duration-150 bg-purple-800 rounded-lg focus:shadow-outline hover:bg-purple-900">
              Create
            </button>
          </div>
        </form>

        <NewDeliverableModal
          isModalOpen={showRequirements}
          handleClose={handleCloseModal}
          handleAdd={handleAddRequirements}
          idx={fields.length - 1}
          trigger={trigger}
          control={control}
          errors={errors}
        />
      </div>
    </>
  );
}

export default InstagramCampaignFragment;
