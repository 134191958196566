import React from "react";
import { getPage } from "@utils/tailwind.constants";
import { useDispatch } from "react-redux";
import useAuthorized from "@hooks/useAuthorized";
import httpErrorHandler, {
  extractErrors,
} from "@middleware/http-error-handler";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import { setAdvertiser } from "@store/slices/advertiserSlice";
import useColorScheme from "@hooks/useColorScheme";
import useSignUpAdvertiser from "@requests/advertisers/useSignUpAdvertiser";
import useNavigator from "@hooks/useNavigator";
import { ADV_ROUTES } from "@constants/routes";
import FlowBiteLogin from "@components/Flowbite/Login";
import FlowBiteHeader from "@components/Flowbite/Header";




export default function AdvertisersSignupPage() {
  useAuthorized();
  const { tetaryColor, fgColor } = useColorScheme();
  const dispatch = useDispatch();
  const [goTo] = useNavigator();
  let errorMessages = {};

  const signUp = useSignUpAdvertiser({
    onSuccess: (data) => {
      dispatch(addToast(TOASTS.success("You have successfully signed up!")));
      goTo(ADV_ROUTES.DASHBOARD.path);
      dispatch(setAdvertiser(data));
    },
    onError: (error) => {
      httpErrorHandler(error, dispatch, goTo);
      errorMessages = extractErrors(error);
    },
  });

  return (
    <>
    <FlowBiteHeader/>
    <div className={getPage("", false, false)}>
      <FlowBiteLogin signUp={signUp} errorMessages={errorMessages} goTo={goTo} />
    </div>
    </>
  );
}
