import APPS from "@constants/app-names";

export default function getCurrentApp() {
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "";
  if (parts.length >= 3) {
    subdomain = parts[0];
  }
  return Object.keys(APPS).find((key) => APPS[key] === subdomain) ?? "main";
}
