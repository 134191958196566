import React, { useState, useCallback } from "react";
import { Link } from "react-scroll";
import influcracy from "@assets/Influcracy_Logo.png";
import CommonLogin from "./commonLogin";
import useNavigator from "@hooks/useNavigator";
import { Helmet } from "react-helmet";
import FlowBiteHeader from "@components/Flowbite/Header";
import FlowBiteLogin from "@components/Flowbite/Login";

const InfluencerLogin = (toggle) => {
  const [navigate] = useNavigator();

  const logoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const [scrollNav, setScrollNav] = useState(false);

  const scrollTop = () => {
    // animateScroll.scrollToTop();
  };
  return (
    <>
      <Helmet>
        <title>Influcracy: Influencer Login</title>
      </Helmet>
      {/* <header className={`${scrollNav ? "scroll-header" : ""} header`}>
        <nav className="nav">
          <Link to="/" onClick={() => { scrollTop(); logoClick(); }} className="nav__logo">
            <img style={{
              height: "6vh",
            }} src={influcracy} alt="" />
          </Link>
        </nav>
      </header> */}

      {/* <FlowBiteHeader toggle={false}/> */}

      <CommonLogin persona="influencer" />
    </>
  );
};

export default InfluencerLogin;
