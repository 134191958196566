import { RouterProvider } from "react-router-dom";
import "./App.css";
import { overrideThemeVariables } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";
import useAppSettings from "@hooks/useAppSetting";
import getCurrentRouter from "BaseRoutes";
import THEMES from '@constants/theme'
import { CookiesProvider } from 'react-cookie';

const queryClient = new QueryClient({});


export default function App() {
  const [_, __, currentApp] = useAppSettings();
  useEffect(() => {
    const currentTheme = THEMES[currentApp];
    if (currentTheme) {
      overrideThemeVariables(currentTheme);
    }
  }, [currentApp]);

  return (
    <div className="">
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <RouterProvider router={getCurrentRouter()} />
        </CookiesProvider>
      </QueryClientProvider>
      {/* <ReactQueryDevtools  /> */}
    </div>
  );
}
