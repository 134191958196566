import React, { useState, cloneElement, useEffect, useRef } from "react";
import InputField from "./InputField";

function DropdownWithInputField({
  label,
  onSelect,
  selected,
  children,
  showSearch,
  searchPlaceholder,
  data,
  inputProps,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [mainData, setMainData] = useState(data);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    clearSearch();
  };

  const onSelectAbstract = (e) => {
    onSelect(e.target.id);
    toggleDropdown();
  };

  // Function to wrap and clone child elements with additional props
  const wrapChild = (child) => {
    return cloneElement(child, {
      onClick:
        child.type === Item
          ? child.props.onClick
          : child.props.onClick || toggleDropdown,
      onSelect: onSelectAbstract,
    });
  };

  function filterDataByText(text) {
    if (!text) {
      return data; // Assuming "data" is the original unfiltered data
    }

    const searchTextLower = text.toLowerCase();
    let searchData = [];

    for (const code of data) {
      if (code.name.toLowerCase().includes(searchTextLower)) {
        searchData.push(code);
      }
    }

    setFilteredData(searchData);
  }

  const searchData = (e) => {
    setSearchText(e.target.value);
    filterDataByText(e.target.value);
    console.log("first", filteredData);
  };

  useEffect(() => {
    if (searchText !== "" && searchText.length > 0) {
      setMainData(filteredData);
    } else {
      setMainData(data);
    }
  }, [data, filteredData, searchText]);

  const clearSearch = () => {
    setSearchText("");
    setMainData(data);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex">
        <button
          onClick={toggleDropdown}
          className="w-[20%] text-gray-900 border border-gray-300 focus:ring-purple-500 focus:border-purple-500 font-medium rounded-l-lg text-lg px-5 py-2.5 text-center inline-flex items-center "
          type="button"
        >
          <span className="mr-2">{label}</span>
          <svg
            className={`w-2.5 h-2.5 ml-auto ${
              isDropdownOpen ? "rotate-180" : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        <input
          className=" text-gray-900 border border-gray-300 rounded-r-lg grow focus:ring-purple-500 focus:border-purple-500 "
          id="input-group-search"
          {...inputProps}
        />
      </div>

      {isDropdownOpen && (
        <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full max-h-[50vh] overflow-auto">
          {showSearch && (
            <div class="p-3">
              <label for="input-group-search" class="sr-only">
                Search
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="input-group-search"
                  onChange={searchData}
                  class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={searchPlaceholder ?? "Search Country"}
                />
              </div>
            </div>
          )}

          <ul
            class="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownCheckboxButton"
          >
            {mainData &&
              typeof mainData === "object" &&
              !Array.isArray(mainData) &&
              mainData !== null &&
              Object.keys(mainData).map((key) => {
                return wrapChild(
                  <ItemHead id={key}>
                    {mainData[key].map((item) => {
                      return <Item id={item} />;
                    })}
                  </ItemHead>
                );
              })}

            {mainData &&
              Array.isArray(mainData) &&
              mainData !== null &&
              mainData.map((key) => {
                return wrapChild(<Item id={key.dial_code} label={key.name} />);
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

function Item({ id, label, isChecked, onSelect }) {
  return (
    <li>
      <div class="flex items-center">
        <button id={id} onClick={onSelect} className="text-lg">
          {`${label} (${id})`}
        </button>
      </div>
    </li>
  );
}

function ItemHead({ id, onSelect, children }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onSelectAbstract = (e) => {
    onSelect(e);
  };

  const wrapChild = (child) => {
    return cloneElement(child, {
      onSelect: onSelectAbstract,
    });
  };

  return (
    <li>
      <div class="flex items-center cursor-pointer" onClick={toggleCollapsed}>
        <p className="text-lg font-semibold">{id}</p>

        <svg
          className={`w-2.5 h-2.5 ml-auto ${isCollapsed ? "rotate-180" : ""}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </div>

      {isCollapsed && children && (
        <ul
          class="ml-2 p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownCheckboxButton"
        >
          {isCollapsed &&
            children &&
            children.map((child) => {
              return wrapChild(child);
            })}
        </ul>
      )}
    </li>
  );
}

export { DropdownWithInputField, Item, ItemHead };
