import React from "react";
import { useDispatch } from "react-redux";
import instagramIMG from "@assets/instagram.png";
import tiktokIMG from "@assets/tiktok.png";
import youtubeIMG from "@assets/youtube.png";
import linkedinIMG from "@assets/linkedin-blue.svg";
import { getPage } from "@utils/tailwind.constants";
import ExtendedButton from "@components/ExtendedButton";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import useNavigator from "@hooks/useNavigator";
import { ADV_ROUTES, SHARED_ROUTES } from "@constants/routes";
import APPS from "@constants/app-names";
import FlowBiteHeader from "@components/Flowbite/Header";

export default function SocialMediaConnectPage({}) {
  // useAuthorized();
  const dispatch = useDispatch();
  const [goTo] = useNavigator();
  return (
    <>
      <FlowBiteHeader />
      <div className={getPage("", true, true)}>
        <ExtendedButton
          text="For Instagram Influencer"
          img={instagramIMG}
          handleClick={() => {
            goTo(SHARED_ROUTES.MAIN.path, APPS.instagram);
          }}
        />
        <ExtendedButton
          text="For TikTok Influencer"
          img={tiktokIMG}
          handleClick={() => {
            goTo(SHARED_ROUTES.MAIN.path, APPS.tiktok);
          }}
        />
        {/* <ExtendedButton
          text="For Linkedin Influencer"
          img={linkedinIMG}
          handleClick={() => {
            goTo(SHARED_ROUTES.MAIN.path, APPS.linkedin);
          }}
        />
        <ExtendedButton
          text="For Youtube Influencer"
          img={youtubeIMG}
          handleClick={() => {
            dispatch(
              addToast(
                TOASTS.warning(
                  "Did I finish development to click on this button ? Don't click on it till I finish!"
                )
              )
            );
          }}
        /> */}
      </div>
    </>
  );
}
