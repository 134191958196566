import React, { useCallback, useEffect, useState } from "react";
import "./Header.css";
import { links } from "@main/fragments/Data";
import { Link } from "react-scroll";
import { animateScroll } from "react-scroll";
import influcracy from "@assets/Influcracy_Logo.png";
import useNavigator from "@hooks/useNavigator";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const scrollTop = () => {
    animateScroll.scrollToTop();
  };

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const [navigate] = useNavigator();
  
    const logoClick = useCallback(() => {
      navigate("/");
    }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate("/about");
  }, [navigate]);

  const onInfluencerClick = useCallback(() => {
    navigate("/influencer");
  }, [navigate]);

  const onBrandClick = useCallback(() => {
    navigate("/brand");
  }, [navigate]);

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  useEffect(
    () => {
      document.body.classList.toggle("no-scroll", showMenu);
    },
    { showMenu }
  );

  return (
    <header className={`${scrollNav ? "scroll-header" : ""} header`}>
      <nav className="nav">
        <Link to="/" onClick={() => { scrollTop(); logoClick(); }} className="nav__logo">
        <a href="" class="flex items-center" onClick={logoClick}>
            <span class="self-center text-purple-800 text-4xl font-bold whitespace-nowrap dark:text-white">
              Influcracy
            </span>
          </a>
        </Link>

        <div className={`${showMenu ? "nav__menu show-menu" : "nav__menu"}`}>
          <div className="nav__data">
            <ul className="nav__list">
              <li className="nav__item" key="id">
                <Link
                  className="nav__link"
                  spy={true}
                  hashSpy={true}
                  offset={15}
                  smooth={true}
                  duration={500}
                  onClick={onHomeClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  spy={true}
                  hashSpy={true}
                  offset={15}
                  smooth={true}
                  duration={500}
                  onClick={onAboutClick}
                >
                  About Us
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  spy={true}
                  hashSpy={true}
                  offset={15}
                  smooth={true}
                  duration={500}
                  onClick={onInfluencerClick}
                >
                  Influencers
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  spy={true}
                  hashSpy={true}
                  offset={15}
                  smooth={true}
                  duration={500}
                  onClick={onBrandClick}
                >
                  Brands
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  to="contact"
                  spy={true}
                  hashSpy={true}
                  offset={15}
                  smooth={true}
                  duration={500}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="nav__btns">
          <div
            className={`${
              showMenu ? "nav__toggle animate-toggle" : "nav__toggle"
            }`}
            onClick={() => setShowMenu(!showMenu)}
          >
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
