import React, { useState } from "react";
import { getPage } from "@utils/tailwind.constants";
import { useDispatch, useSelector } from "react-redux";
import MyCampaignsInIgInfluencers from "../MyCampaign";
import NewCampaignsIgInfluencers from "../NewCampaigns";
import icInsta from "@assets/ic-logo-insta.png";
import profileIMG from "@assets/avatar.svg";
import { H4 } from "ui-neumorphism";
import { Tabs, Tab } from "@components/TabSelector/Tabs";
import { TabItems, TabItem } from "@components/TabSelector/TabItems";
import apiClient from "@utils/apiClient";
import Loader from "@components/Loader";
import { SHARED_ROUTES } from "@constants/routes";
import useNavigation from "@hooks/useNavigator";
import FlowBiteHeader from "@components/Flowbite/Header";
import { resetIGState } from "@store/slices/igSlice";
import { getInstaAuthUrl } from "@utils/helperSSO";

const Dashboard = () => {
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const igData = useSelector((state) => state.igData);
  const [isDemo, _] = useState(igData?.username === "");
  const profilePC = igData.info?.gender ? 100 : 30;

  const dispatch = useDispatch();

  const [goto] = useNavigation();

  const handleRedirect = () => {
    goto(SHARED_ROUTES.MAIN.path);
  };

  const instaAuth = (e) => {
    e.preventDefault();

    const instaAuthUrl = getInstaAuthUrl();
    window.location.href = instaAuthUrl;
  };

  const disconnectInsta = (e) => {
    setIsLoading(true);
    apiClient
      .delete("/api/v1/ig/me")
      .then((data) => {})
      .finally(() => {
        setIsLoading(false);
        handleRedirect();
        dispatch(resetIGState());
      });
  };

  const handleTabClick = (index) => {
    setActive(index);
  };

  const tabHeaders = (
    <Tabs active={active} onChange={handleTabClick} tabStyle="flowbite">
      <Tab>New Campaigns</Tab>
      <Tab>My Campaigns</Tab>
    </Tabs>
  );

  const tabContents = (
    <TabItems active={active}>
      <TabItem>
        {isDemo ? (
          <div>
            As a first-time user, please connect your Instagram to verify your
            username and account type
          </div>
        ) : (
          <NewCampaignsIgInfluencers />
        )}
      </TabItem>
      <TabItem>
        {isDemo ? (
          <div>
            As a first-time user, please connect your Instagram to verify your
            username and account type
          </div>
        ) : (
          <MyCampaignsInIgInfluencers />
        )}
      </TabItem>
    </TabItems>
  );

  return (
    <>
      <FlowBiteHeader
        showProfileCompletionBtn={profilePC !== 100}
        profileCompletionPC={profilePC}
      />

      <div className={`${getPage("", false, false, true)}`}>
        {isLoading && <Loader />}

        {isDemo && (
          <div className="flex justify-center shadow-lg rounded-full p-8 m-4 w-fit mx-auto">
            <div className="flex gap-4 align-items-center">
              <img src={icInsta} alt="ic-logo" className="w-12 h-12" />

              <button onClick={instaAuth}>Connect your instagram</button>
            </div>
          </div>
        )}

        {!isDemo && (
          <div className="flex gap-4">
            <img
              className="h-24 w-24 self-center rounded-full"
              src={profileIMG}
              alt="User Profile"
            />

            <div>
              <H4>{igData?.username}</H4>
              <button onClick={disconnectInsta}>Disconnect</button>
            </div>
          </div>
        )}

        <div className="mx-auto w-full sm:max-w-3xl">
          {tabHeaders}

          {tabContents}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
