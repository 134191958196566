import { useEffect, useState } from "react";
import { Button, Card } from "ui-neumorphism";
import PubCompletionModal from "@components/PubCompletionModal";

export const InterestedApplicant = ({ application, onAdvAction, status }) => {
  const [showCampaignCompletionModal, setShowCampaignCompletionModal] =
    useState(false);
  const [deliverableData, setDeliverableData] = useState([]);

  const pushIfNotNull = (data, title, value, formatter = null) => {
    if (value && value !== null) {
      data.push({
        title,
        subtitle: formatter ? formatter(value) : value.toString(),
      });
    }
  };

  const handleCompletionModalClose = () => {
    setShowCampaignCompletionModal(false);
  };

  const convertData = (followers, inputData) => {
    const data = [];

    pushIfNotNull(data, "Followers", followers);

    pushIfNotNull(data, "# of Image Post", inputData.image_num);
    pushIfNotNull(data, "# of Video Post", inputData.video_num);
    pushIfNotNull(data, "# of Stories", inputData.story_num);
    pushIfNotNull(data, "# of Stories with link", inputData.story_link_num);

    pushIfNotNull(data, "Link in Bio", inputData.bio_link_num);

    pushIfNotNull(
      data,
      "Collab Price",
      inputData.colab_price,
      (value) => `$ ${value}`
    );

    pushIfNotNull(
      data,
      "Product Cost",
      inputData.prod_value,
      (value) => `$ ${value}`
    );

    pushIfNotNull(data, "Discount", inputData.disc, (value) => `${value}%`);

    setDeliverableData(data);
  };

  const approveApplication = () => {
    onAdvAction(application.id, "APPROVE");
  };

  const denyApplication = () => {
    onAdvAction(application.id, "DENY");
  };

  const showCompletionRequest = () => {
    setShowCampaignCompletionModal(true);
  };

  useEffect(() => {
    convertData(application.followers_count, application.deliverable);
  });

  const getDeepLink = () => {
    if (application.platform === "INSTAGRAM") {
      return `https://www.instagram.com/${application.username}/`;
    } else if (application.platform === "TIKTOK") {
      return `https://www.tiktok.com/@${application.username}/`;
    }
  };

  return (
    <Card
      outlined
      style={{
        margin: "2rem",
      }}
    >
      <div class="flex flex-row justify-between items-center p-8">
        <div className="text-4xl">
          <a href={getDeepLink()} target="_blank" rel="noreferrer">
            @{application.username}
          </a>
        </div>

        {status === "REQUESTED" && (
          <div className="flex flex-col sm:flex-row gap-8">
            <Button onClick={approveApplication}>Approve</Button>
            <Button onClick={denyApplication}>Deny</Button>
          </div>
        )}

        {status === "APPROVED" && (
          <div className="flex flex-col sm:flex-row gap-8">
            <Button bgColor={"green"} color={"white"}>
              In Progress
            </Button>
          </div>
        )}

        {status === "COMPLETED" && (
          <div className="flex flex-col sm:flex-row gap-8">
            <Button
              bgColor={"green"}
              color={"white"}
              onClick={showCompletionRequest}
            >
              View Completion Request
            </Button>
          </div>
        )}

        {status === "COMPLETED_APPROVED" && (
          <div className="flex flex-col sm:flex-row gap-8">
            <Button bgColor={"green"} color={"white"}>
              COMPLETED
            </Button>
          </div>
        )}
      </div>

      <div class="flex flex-wrap gap-8">
        {deliverableData.map(({ title, subtitle }) => {
          return (
            <div className="p-8">
              <h4>{title}</h4>
              <div>
                <span>{subtitle}</span>
              </div>
            </div>
          );
        })}
      </div>

      {status === "REQUESTED" &&
        application.screeningResponse &&
        application.screeningResponse.length > 0 && (
          <div className="flex flex-col gap-4 m-4">
            <p className="text-xl font-semibold text-left text-gray-900">
              Screening Responses
            </p>

            {application.screeningResponse.map((response, index) => (
              <div key={response.id} className="w-full flex flex-col">
                <div className="grow text-xl font-normal text-left text-gray-900">
                  {index + 1}. {response.question}
                </div>
                <div className="grow text-xl font-normal text-left text-gray-900">
                  A. {response.answer}
                </div>
              </div>
            ))}
          </div>
        )}

      <PubCompletionModal
        isOpen={showCampaignCompletionModal}
        onClose={handleCompletionModalClose}
        application={application}
        onAdvAction={onAdvAction}
      />
    </Card>
  );
};
