import React, { useCallback } from "react";
import "./BrandHome.css";
import useNavigator from "@hooks/useNavigator";
import { MAIN_ROUTES } from "@constants/routes";

const BrandHome = () => {
  const [navigate] = useNavigator();

  const brandSigninClick = useCallback(() => {
    navigate(MAIN_ROUTES.BRAND_SIGN_IN.path);
  }, [navigate]);
  return (
    <>
    
    <section class="mt-[6rem] bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
        <h1 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white"> Drive impactful campaigns with our curated network of influencers</h1>
        <p class="mb-6 text-dark text-gray-500 md:text-2xl dark:text-gray-400"> With Influcracy's large network of diverse and influential voices,
          brands gain access to a wide array of influencers spanning various
          niches. This extensive network allows brands to find the perfect
          match, ensuring campaigns reach the right audience, maximize impact,
          and unlock the full potential of influencer marketing</p>
        <div class="flex items-center flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <button onClick={brandSigninClick} type="button" class="w-[22rem] h-[4rem] p-4 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-large rounded-full text-xl px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Sign In</button>
        </div>
    </div>
</section></>
  );
};

export default BrandHome;
