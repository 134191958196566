import React, { useState } from "react";
import { Button } from "flowbite-react";
import { FaPlus } from "react-icons/fa";

const MediaUploader = ({ id, onFileSelected }) => {
  const [previewData, setPreviewData] = useState("");
  const handleButtonClick = (id) => {
    document.getElementById(id).click();
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 300;
        const MAX_HEIGHT = 300;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        setPreviewData(compressedBase64);
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);

    onFileSelected(id, file);
  };

  return (
    <div className="col-span-1 m-auto">
      <label htmlFor={id}>
        {previewData && (
          <img
            src={previewData}
            alt="Preview"
            className="w-[80%] h-[60%] rounded"
          />
        )}

        {!previewData && (
          <Button
            color="gray"
            className="bg-white"
            onClick={() => handleButtonClick(id)}
          >
            <FaPlus color="#5521b5" className="h-8 w-8" />
          </Button>
        )}

        <input
          id={id}
          name={id}
          type="file"
          hidden
          onChange={handleFileSelected}
        />
      </label>
    </div>
  );
};

export default MediaUploader;
