import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices/rootSlice";
import advertiserReducer from "./slices/advertiserSlice";
import adminReducer from "./slices/adminSlice";
import igReducer from "./slices/igSlice";
import tkReducer from "./slices/tkSlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  root: rootReducer,
  advertiser: advertiserReducer,
  admin: adminReducer,
  igData: igReducer,
  tkData: tkReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
