import CreateForm from "@components/CreateForm";
import PreviousPosts from "@components/PreviousPosts";
import React from "react";
import { Card } from "ui-neumorphism";

const Create = () => {
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>
      <Card
        rounded
        style={{
          marginTop: "10rem",
          padding: "18px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          // minWidth: "300px",
        }}
      >
        <CreateForm/>


      </Card>
        <PreviousPosts/>
    </div>
  );
};

export default Create;
