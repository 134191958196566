import React, { useCallback, useEffect, useState } from "react";
import { Card } from "ui-neumorphism";
import HelperCard from "@components/Flowbite/Card";
import StatusBadge from "@components/Flowbite/StatusBadge";
import CampaignModal from "@components/CampaignModal";
import apiClient from "@utils/apiClient";
import { FaSpinner } from "react-icons/fa";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { TbCloudPause } from "react-icons/tb";
import { TbCloudRain } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";

const CampaignCard = ({ campaign, src, onRefetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [fetchedImageData, setFetchedImageData] = useState("");
  const [pendingApplications, setPendingApplications] = useState(0);
  const dispatch = useDispatch();

  const handleOpenCampaignModal = () => {
    setShowCampaignModal(true);
  };

  const closeCampaignModal = () => {
    setShowCampaignModal(false);
  };

  const handleAdminCampaignAction = (action) => {
    setShowCampaignModal(false);
    setIsLoading(true);

    apiClient
      .put(`/api/v1/admin/campaigns/${campaign.cid}`, {
        visibility: action,
      })
      .then(() => {
        onRefetch();

        dispatch(addToast(TOASTS.success(`Campaign ${action} Successfully!`)));
      })
      .catch((error) => {
        dispatch(addToast(TOASTS.error(`Failed to ${action} Campaign!`)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdvAction = (action) => {
    setShowCampaignModal(false);
    setIsLoading(true);

    apiClient
      .put(`/api/v1/adv/campaigns/${campaign.cid}`, {
        visibility: action,
      })
      .then(() => {
        onRefetch();
      })
      .catch((error) => {
        dispatch(addToast(TOASTS.error(`Failed to ${action} Campaign!`)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchImage = useCallback(async () => {
    try {
      if (campaign?.logo) {
        const response = await apiClient.get(
          `/api/v1/common/file?file_key=${campaign?.logo}`,
          {
            responseType: "arraybuffer",
          }
        );
        const imageData = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        setFetchedImageData(imageData);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }, [campaign]);

  useEffect(() => {
    fetchImage();

    if (
      src === "admin" &&
      campaign.applications &&
      campaign.applications.length > 0
    ) {
      const pendingApplications = campaign.applications.filter(
        (application) => application.status === "REQUESTED_PENDING"
      );

      setPendingApplications(pendingApplications.length);
    }
  }, []);

  const handleDeleteCampaign = () => {
    if (src === "admin") {
      handleAdminCampaignAction("DELETED");
    } else if (src === "brand") {
      handleAdvAction("DELETED");
    }
  };

  const handlePauseCampaign = () => {
    if (src === "admin") {
      handleAdminCampaignAction("PAUSED");
    } else if (src === "brand") {
      handleAdvAction("PAUSED");
    }
  };

  const handleResumeCampaign = () => {
    if (src === "admin") {
      handleAdminCampaignAction("APPROVED");
    } else if (src === "brand") {
      handleAdvAction("APPROVED");
    }
  };

  return (
    <>
      <HelperCard>
        <>
          <Card className="m-2" outlined={true}>
            <div className="grid grid-cols-10 gap-4 m-2 justify-between">
              <span className="col-span-8 flex items-center gap-4">
                <img
                  className="rounded-full w-12 h-12 mx-4 object-scale-down"
                  src={`data:image/jpeg;base64, ${fetchedImageData}`}
                  alt="Brand Logo"
                />

                <h3 className="truncate text-xl font-semibold">
                  {campaign?.title}
                </h3>
              </span>

              <div className="col-span-2 flex items-center justify-center">
                <StatusBadge text={campaign?.visibility} color={"green"} />
              </div>
            </div>

            <p className="my-4 mx-4 text-xl line-clamp-3 leading-normal">
              {campaign.description}
            </p>

            <div class="flex flex-row flex-wrap justify-center gap-20 mb-8">
              <button
                disabled={isLoading}
                onClick={handleOpenCampaignModal}
                type="button"
                class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-purple-700 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                <AiOutlineEye className="w-5 h-5 mr-2" />
                <span>View Campaign</span>

                {/* {isLoading && (
                  <FaSpinner className="w-5 h-5 mx-2 animate-spin" />
                )} */}
              </button>

              {campaign.visibility === "PENDING" && (
                <button
                  disabled={isLoading}
                  onClick={handleDeleteCampaign}
                  type="button"
                  class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-purple-700 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                >
                  <AiOutlineDelete className="w-5 h-5 mr-2" />
                  <span>Delete</span>

                  {isLoading && (
                    <FaSpinner className="w-5 h-5 mx-2 animate-spin" />
                  )}
                </button>
              )}

              {campaign.visibility === "APPROVED" && (
                <button
                  disabled={isLoading}
                  onClick={handlePauseCampaign}
                  type="button"
                  class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-purple-700 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                >
                  <TbCloudPause className="w-5 h-5 mr-2" />
                  <span>Pause</span>

                  {isLoading && (
                    <FaSpinner className="w-5 h-5 mx-2 animate-spin" />
                  )}
                </button>
              )}

              {campaign.visibility === "PAUSED" && (
                <button
                  disabled={isLoading}
                  onClick={handleResumeCampaign}
                  type="button"
                  class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-purple-700 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                >
                  <TbCloudRain className="w-5 h-5 mr-2" />
                  <span>Resume</span>

                  {isLoading && (
                    <FaSpinner className="w-5 h-5 mx-2 animate-spin" />
                  )}
                </button>
              )}
            </div>
          </Card>

          {pendingApplications > 0 && (
            <div className="text-center">
              <span className="text-base text-red-500">{`${pendingApplications} applications waiting for review`}</span>
            </div>
          )}
        </>

        <CampaignModal
          isOpen={showCampaignModal}
          onClose={closeCampaignModal}
          logoImg={fetchedImageData}
          onAdminAction={handleAdminCampaignAction}
          campaign={campaign}
          src={src}
        />
      </HelperCard>
    </>
  );
};

export default CampaignCard;
