import { Button, Carousel, Label, ListGroup, Textarea } from "flowbite-react";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import ICSample from "@assets/ic-sample.png";
import LabeledTextArea from "@components/Flowbite/LabeledTextArea";
import apiClient from "@utils/apiClient";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";
import InputField from "@components/FormInput/InputField";
import { FaSpinner, FaUsers } from "react-icons/fa";

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    zIndex: 100,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CampaignModal = ({
  isOpen,
  onClose,
  campaign,
  logoImg,
  onAdminAction,
  onInfluencerAction,
  src = "influencer",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAcceptInput, setShowAcceptInput] = useState(false);
  const [showDenyInput, setShowDenyInput] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState([]);

  const dispatch = useDispatch();

  const handleClose = () => {
    setShowAcceptInput(false);
    setShowDenyInput(false);
    onClose();
  };

  const handleAnsChange = (index, value) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index] = {
      question: campaign.questions[index],
      answer: value,
    };
    setQuestionAnswers(newQuestionAnswers);
  };

  const renderActionButtons = useCallback(() => {
    if (showAcceptInput) {
      if (campaign && campaign.questions && campaign.questions.length > 0) {
        return (
          <div className="w-full">
            <p className="text-xl font-semibold">Screening Questions</p>

            <div className="mt-4">
              {campaign.questions.map((question, index) => (
                <div key={index} className="mb-4">
                  <InputField
                    type="text"
                    label={`${index + 1}. ${question}`}
                    name="question"
                    onChange={(e) => handleAnsChange(index, e.target.value)}
                    required={true}
                  />
                </div>
              ))}
            </div>

            <LabeledTextArea
              labelText="Add a note"
              questions={campaign}
              placeholder="Tell us why do you think you are suitable for the campaign..."
              buttonText="Apply"
              onSubmit={(message) => {
                handleClose();
                onInfluencerAction("REQUESTED", message, questionAnswers);
              }}
              onCancel={() => {
                setShowAcceptInput(false);
                setShowDenyInput(false);
              }}
            />
          </div>
        );
      } else {
        return (
          <LabeledTextArea
            labelText="Add a note"
            questions={campaign}
            placeholder="Tell us why do you think you are suitable for the campaign..."
            buttonText="Apply"
            onSubmit={(message) => {
              handleClose();
              onInfluencerAction("REQUESTED", message);
            }}
            onCancel={() => {
              setShowAcceptInput(false);
              setShowDenyInput(false);
            }}
          />
        );
      }
    } else if (showDenyInput) {
      return (
        <LabeledTextArea
          labelText="Tell us why..."
          placeholder="Please Tell us why do you think you this campaign is not suitable for you..."
          buttonText="Deny"
          onSubmit={(message) => {
            handleClose();
            onInfluencerAction("DENIED", message);
          }}
          onCancel={() => {
            setShowAcceptInput(false);
            setShowDenyInput(false);
          }}
        />
      );
    } else {
      if (campaign?.status !== "REQUESTED" && campaign?.status !== "APPROVED") {
        return (
          <div class="mt-10 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
            <Button
              onClick={() => setShowDenyInput(true)}
              className="w-full"
              color="purple"
              outline
            >
              Deny
            </Button>
            <Button
              onClick={() => setShowAcceptInput(true)}
              className="w-full"
              color="purple"
              outline
            >
              Apply
            </Button>
          </div>
        );
      }
    }
  }, [showAcceptInput, showDenyInput, handleClose, campaign?.status]);

  const createOrUpdateApplication = useCallback(
    (action, message = "no message") => {
      apiClient
        .post(`/api/v1/ig/campaigns/${campaign.cid}`, {
          action: action,
          message: message,
        })
        .then(({ data }) => {
          dispatch(
            addToast(TOASTS.success("Applied to Campaign Successfully!"))
          );
        })
        .catch((err) => {
          dispatch(addToast(TOASTS.error("Failed to Campaign Successfully!")));
        });
    },
    [campaign.cid, dispatch]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Campaign Details"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="w-[70vw] sm:w-[50vw] md:w-[40vw] lg:w-[30vw] max-h-[70vh]">
        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t mb-8">
          <h3 className="text-3xl font-semibold">Campaign Details</h3>
          <button
            type="button"
            onClick={handleClose}
            className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-12 h-12 ml-auto inline-flex justify-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
          >
            <IoClose className="w-8 h-8" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div>
          <div>
            <div class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[75px] relative p-6">
              <div class="flex justify-start items-center flex-grow relative gap-2">
                <img
                  className="w-20 h-20 rounded-full"
                  src={`data:image/jpeg;base64, ${logoImg}`}
                  alt="some"
                />

                <p class="text-2xl font-semibold text-left text-gray-900">
                  {campaign?.title ?? "Campaign title"}
                </p>
              </div>
            </div>

            <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-4 p-6">
              <p class="text-xl font-semibold text-left text-gray-900">
                Description
              </p>

              <p class="text-lg text-left text-gray-700">
                {campaign?.description ?? "Campaign description"}
              </p>

              {campaign?.mediaFiles?.length ? (
                <>
                  <p class="text-xl font-semibold text-left text-gray-900">
                    Sample Media
                  </p>

                  <Carousel className="h-[20vh]">
                    {Array.isArray(campaign.mediaFiles) &&
                      campaign.mediaFiles?.map((file, index) => (
                        <div key={index}>
                          <img src={file} alt="sample" />
                        </div>
                      ))}
                  </Carousel>
                </>
              ) : null}

              {src === "influencer" && (
                <div className="w-full grid gap-4">
                  <p class="text-xl font-semibold text-left text-gray-900">
                    Deliverabless
                  </p>

                  <ListGroup className="w-full">
                    {campaign?.deliverable?.story_link_num &&
                    campaign?.deliverable?.story_link_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.story_link_num} Stories/Posts
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.story_num &&
                    campaign?.deliverable?.story_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.story_num} Story with link
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.image_num &&
                    campaign?.deliverable?.image_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.image_num} Image Posts
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.video_num &&
                    campaign?.deliverable?.video_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.video_num} Video Posts
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.tiktok_story_num &&
                    campaign?.deliverable?.tiktok_story_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.tiktok_story_num} TikTok
                          Stories
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.tiktok_video_num &&
                    campaign?.deliverable?.tiktok_video_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.tiktok_video_num} TikTok
                          Videos
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.bio_link_num &&
                    campaign?.deliverable?.bio_link_num > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          {campaign?.deliverable?.bio_link_num} days link in bio
                        </span>
                      </ListGroup.Item>
                    ) : null}
                  </ListGroup>

                  <p class="text-xl font-semibold text-left text-gray-900">
                    Compensation
                  </p>

                  <ListGroup className="w-full">
                    {campaign?.deliverable?.colab_price &&
                    campaign?.deliverable?.colab_price > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          Collab price of ${campaign?.deliverable?.colab_price}
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.prod_value &&
                    campaign?.deliverable?.prod_value > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          Product worth of ${campaign?.deliverable?.prod_value}
                        </span>
                      </ListGroup.Item>
                    ) : null}

                    {campaign?.deliverable?.disc &&
                    campaign?.deliverable?.disc > 0 ? (
                      <ListGroup.Item icon={GoDotFill}>
                        <span className="text-lg text-gray-700 hover:text-gray-900">
                          Discount worth of ${campaign?.deliverable?.disc}
                        </span>
                      </ListGroup.Item>
                    ) : null}
                  </ListGroup>

                  {/* {renderActionButtons()} */}
                </div>
              )}

              {campaign.target && (
                <div className="w-full grid gap-2">
                  <p class="text-xl font-semibold text-left text-gray-900">
                    Ideal influencer
                  </p>

                  <div>
                    <p class="text-lg text-left text-gray-700">
                      Age: {campaign.target?.age.min} -{" "}
                      {campaign.target?.age.max}
                    </p>

                    <p class="text-lg text-left text-gray-700">
                      Gender:{" "}
                      {campaign.target?.gender !== ""
                        ? campaign.target?.gender
                        : "All"}
                    </p>

                    <p class="text-lg text-left text-gray-700">
                      Location:{" "}
                      {campaign.target?.location !== ""
                        ? campaign.target?.location
                        : "All"}
                    </p>
                  </div>
                </div>
              )}

              {src !== "influencer" &&
                campaign.questions &&
                campaign.questions.length > 0 && (
                  <div className="w-full grid gap-2">
                    <p class="text-xl font-semibold text-left text-gray-900">
                      Questions
                    </p>
                    <div>
                      <ol>
                        {campaign.questions.map((question, index) => (
                          <li key={index} className="w-full flex">
                            <div className="grow text-xl font-normal text-left text-gray-900">
                              Q{index + 1}. {question}
                            </div>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                )}

              {src === "influencer" && <>{renderActionButtons()}</>}

              {src === "admin" && campaign.visibility === "PENDING" && (
                <div className="w-full grid gap-4">
                  <div class="mt-10 flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-2.5 py-2.5">
                    <Button
                      onClick={() => {
                        onAdminAction("REJECTED");
                      }}
                      className="w-full"
                      color="purple"
                      outline
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => {
                        onAdminAction("APPROVED");
                      }}
                      className="w-full"
                      color="purple"
                      outline
                    >
                      Approve
                    </Button>
                  </div>
                </div>
              )}

              <div className="w-full flex justify-center">
                {src === "brand" && (
                  <a
                    disabled={isLoading}
                    href={`/campaign/${campaign.cid}/applications`}
                    target="_blank"
                    rel="noreferrer"
                    type="button"
                    class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-gray-800 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                  >
                    <FaUsers className="w-5 h-5 mr-2" />
                    <span>Interested Users</span>
                  </a>
                )}

                {src === "admin" && campaign.visibility === "APPROVED" && (
                  <a
                    disabled={isLoading}
                    href={`/campaign/${campaign.cid}/applications`}
                    target="_blank"
                    rel="noreferrer"
                    type="button"
                    class="flex items-center px-8 py-3.5 text-base font-medium text-gray-900 border border-gray-800 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                  >
                    <FaUsers className="w-5 h-5 mr-2" />
                    <span>Applications</span>

                    {isLoading && (
                      <FaSpinner className="w-5 h-5 mx-2 animate-spin" />
                    )}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CampaignModal;
