export default [
    { value: "account executive", label: "Account Executive" },
    { value: "account manager", label: "Account Manager" },
    { value: "accountant", label: "Accountant" },
    { value: "administrative assistant", label: "Administrative Assistant" },
    { value: "advertising manager", label: "Advertising Manager" },
    { value: "angel investor", label: "Angel Investor" },
    { value: "analyst", label: "Analyst" },
    { value: "application developer", label: "Application Developer" },
    { value: "architect", label: "Architect" },
    { value: "art director", label: "Art Director" },
    { value: "assistant manager", label: "Assistant Manager" },
    { value: "associate", label: "Associate" },
    { value: "attorney", label: "Attorney" },
    { value: "auditor", label: "Auditor" },
    { value: "brand manager", label: "Brand Manager" },
    { value: "business analyst", label: "Business Analyst" },
    {
      value: "business development manager",
      label: "Business Development Manager",
    },
    {
      value: "business operations manager",
      label: "Business Operations Manager",
    },
    {
      value: "ceo (chief executive officer)",
      label: "CEO (Chief Executive Officer)",
    },
    {
      value: "cfo (chief financial officer)",
      label: "CFO (Chief Financial Officer)",
    },
    {
      value: "chro (chief human resources officer)",
      label: "CHRO (Chief Human Resources Officer)",
    },
    {
      value: "cio (chief information officer)",
      label: "CIO (Chief Information Officer)",
    },
    {
      value: "coo (chief operating officer)",
      label: "COO (Chief Operating Officer)",
    },
    {
      value: "cpo (chief procurement officer)",
      label: "CPO (Chief Procurement Officer)",
    },
    { value: "cro (chief risk officer)", label: "CRO (Chief Risk Officer)" },
    {
      value: "cto (chief technology officer)",
      label: "CTO (Chief Technology Officer)",
    },
    { value: "call center representative", label: "Call Center Representative" },
    { value: "chief accountant", label: "Chief Accountant" },
    { value: "chief compliance officer", label: "Chief Compliance Officer" },
    { value: "chief marketing officer", label: "Chief Marketing Officer" },
    { value: "chief strategy officer", label: "Chief Strategy Officer" },
    { value: "client services manager", label: "Client Services Manager" },
    { value: "co-founder", label: "Co-Founder" },
    { value: "communications manager", label: "Communications Manager" },
    { value: "compliance officer", label: "Compliance Officer" },
    { value: "construction manager", label: "Construction Manager" },
    { value: "consultant", label: "Consultant" },
    { value: "content manager", label: "Content Manager" },
    { value: "contract administrator", label: "Contract Administrator" },
    { value: "controller", label: "Controller" },
    { value: "creative director", label: "Creative Director" },
    { value: "credit analyst", label: "Credit Analyst" },
    {
      value: "customer service representative",
      label: "Customer Service Representative",
    },
    { value: "data analyst", label: "Data Analyst" },
    { value: "data architect", label: "Data Architect" },
    { value: "database administrator", label: "Database Administrator" },
    { value: "designer", label: "Designer" },
    { value: "developer", label: "Developer" },
    { value: "digital marketing manager", label: "Digital Marketing Manager" },
    { value: "director", label: "Director" },
    { value: "e-commerce manager", label: "E-Commerce Manager" },
    { value: "editor", label: "Editor" },
    { value: "electrical engineer", label: "Electrical Engineer" },
    { value: "engineer", label: "Engineer" },
    { value: "environmental engineer", label: "Environmental Engineer" },
    { value: "executive assistant", label: "Executive Assistant" },
    { value: "facilities manager", label: "Facilities Manager" },
    { value: "finance manager", label: "Finance Manager" },
    { value: "financial analyst", label: "Financial Analyst" },
    { value: "financial controller", label: "Financial Controller" },
    { value: "founder", label: "Founder" },
    { value: "graphic designer", label: "Graphic Designer" },
    { value: "hr assistant", label: "HR Assistant" },
    { value: "hr manager", label: "HR Manager" },
    { value: "health and safety manager", label: "Health and Safety Manager" },
    { value: "help desk technician", label: "Help Desk Technician" },
    { value: "human resources generalist", label: "Human Resources Generalist" },
    { value: "industrial designer", label: "Industrial Designer" },
    {
      value: "information security analyst",
      label: "Information Security Analyst",
    },
    {
      value: "information technology manager",
      label: "Information Technology Manager",
    },
    {
      value: "inside sales representative",
      label: "Inside Sales Representative",
    },
    { value: "instructional designer", label: "Instructional Designer" },
    { value: "insurance agent", label: "Insurance Agent" },
    { value: "interior designer", label: "Interior Designer" },
    { value: "investment banker", label: "Investment Banker" },
    { value: "investor", label: "Investor" },
    { value: "it support specialist", label: "IT Support Specialist" },
    { value: "java developer", label: "Java Developer" },
    { value: "journalist", label: "Journalist" },
    { value: "key account manager", label: "Key Account Manager" },
    { value: "kitchen manager", label: "Kitchen Manager" },
    { value: "laboratory technician", label: "Laboratory Technician" },
    { value: "lawyer", label: "Lawyer" },
    { value: "logistics coordinator", label: "Logistics Coordinator" },
    { value: "machine operator", label: "Machine Operator" },
    { value: "maintenance manager", label: "Maintenance Manager" },
    { value: "management consultant", label: "Management Consultant" },
    { value: "manager", label: "Manager" },
    { value: "manufacturing engineer", label: "Manufacturing Engineer" },
    { value: "marketing coordinator", label: "Marketing Coordinator" },
    { value: "marketing manager", label: "Marketing Manager" },
    { value: "materials manager", label: "Materials Manager" },
    { value: "mechanical engineer", label: "Mechanical Engineer" },
    { value: "media buyer", label: "Media Buyer" },
    { value: "medical assistant", label: "Medical Assistant" },
    { value: "medical technologist", label: "Medical Technologist" },
    { value: "merchandiser", label: "Merchandiser" },
    { value: "network administrator", label: "Network Administrator" },
    { value: "nurse practitioner", label: "Nurse Practitioner" },
    { value: "occupational therapist", label: "Occupational Therapist" },
    { value: "office administrator", label: "Office Administrator" },
    { value: "operations manager", label: "Operations Manager" },
    { value: "packaging engineer", label: "Packaging Engineer" },
    { value: "paralegal", label: "Paralegal" },
    { value: "payroll manager", label: "Payroll Manager" },
    { value: "personal assistant", label: "Personal Assistant" },
    { value: "physical therapist", label: "Physical Therapist" },
    { value: "physician assistant", label: "Physician Assistant" },
    { value: "pilot", label: "Pilot" },
    { value: "plant manager", label: "Plant Manager" },
    { value: "product manager", label: "Product Manager" },
    { value: "production manager", label: "Production Manager" },
    { value: "program manager", label: "Program Manager" },
    { value: "project coordinator", label: "Project Coordinator" },
    { value: "project manager", label: "Project Manager" },
    {
      value: "public relations specialist",
      label: "Public Relations Specialist",
    },
    { value: "purchasing manager", label: "Purchasing Manager" },
    { value: "quality assurance analyst", label: "Quality Assurance Analyst" },
    { value: "quality control manager", label: "Quality Control Manager" },
    { value: "real estate agent", label: "Real Estate Agent" },
    { value: "receptionist", label: "Receptionist" },
    { value: "recruiter", label: "Recruiter" },
    { value: "regional sales manager", label: "Regional Sales Manager" },
    { value: "research analyst", label: "Research Analyst" },
    { value: "restaurant manager", label: "Restaurant Manager" },
    { value: "retail sales associate", label: "Retail Sales Associate" },
    { value: "sales manager", label: "Sales Manager" },
    { value: "sales representative", label: "Sales Representative" },
    { value: "scientist", label: "Scientist" },
    { value: "security officer", label: "Security Officer" },
    { value: "senior account executive", label: "Senior Account Executive" },
    { value: "senior account manager", label: "Senior Account Manager" },
    { value: "senior analyst", label: "Senior Analyst" },
    { value: "senior architect", label: "Senior Architect" },
    { value: "senior designer", label: "Senior Designer" },
    { value: "senior developer", label: "Senior Developer" },
    { value: "senior engineer", label: "Senior Engineer" },
    { value: "senior manager", label: "Senior Manager" },
    { value: "senior product manager", label: "Senior Product Manager" },
    { value: "senior project manager", label: "Senior Project Manager" },
    {
      value: "senior quality assurance analyst",
      label: "Senior Quality Assurance Analyst",
    },
    { value: "senior software engineer", label: "Senior Software Engineer" },
    { value: "senior technical writer", label: "Senior Technical Writer" },
    { value: "social media manager", label: "Social Media Manager" },
    { value: "software developer", label: "Software Developer" },
    { value: "software engineer", label: "Software Engineer" },
    { value: "solution architect", label: "Solution Architect" },
    { value: "sous chef", label: "Sous Chef" },
    { value: "sql developer", label: "SQL Developer" },
    { value: "staff accountant", label: "Staff Accountant" },
    { value: "store manager", label: "Store Manager" },
    { value: "supply chain manager", label: "Supply Chain Manager" },
    { value: "systems administrator", label: "Systems Administrator" },
    { value: "systems analyst", label: "Systems Analyst" },
    { value: "tax accountant", label: "Tax Accountant" },
    { value: "teacher", label: "Teacher" },
    { value: "technical support engineer", label: "Technical Support Engineer" },
    { value: "technical writer", label: "Technical Writer" },
    { value: "technician", label: "Technician" },
    { value: "technology consultant", label: "Technology Consultant" },
    { value: "territory sales manager", label: "Territory Sales Manager" },
    { value: "test engineer", label: "Test Engineer" },
    { value: "trainer", label: "Trainer" },
    { value: "user experience designer", label: "User Experience Designer" },
    { value: "user interface designer", label: "User Interface Designer" },
    { value: "venture capitalist", label: "Venture Capitalist" },
    { value: "vice president", label: "Vice President" },
    { value: "video producer", label: "Video Producer" },
    { value: "web content manager", label: "Web Content Manager" },
    { value: "web designer", label: "Web Designer" },
    { value: "web developer", label: "Web Developer" },
    { value: "welder", label: "Welder" },
    { value: "writer", label: "Writer" },
    { value: "zoologist", label: "Zoologist" },
    { value: "other", label: "Other" },
  ];
  