import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { useCallback, useState } from "react";
import InputField from "@components/FormInput/InputField";
import { Button } from "flowbite-react";
import { FaPlus, FaSpinner } from "react-icons/fa";
import apiClient from "@utils/apiClient";
import { useDispatch } from "react-redux";
import { addToast } from "@store/slices/rootSlice";
import TOASTS from "@constants/toasts";

const CampaignCompletionModal = ({
  isOpen,
  onClose,
  type,
  campaign,
  onRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [link, setLink] = useState("");
  const [isQuestionBtnOnFoucs, setIsQuestionBtnOnFocus] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const addLink = () => {
    if (!link || link === "") return;

    setLinks([...links, link]);
    setLink("");
  };

  const handleFileSelect = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  const submitCompletion = async () => {
    setIsLoading(true);

    const promises = [];
    const file_keys = [];

    for (const key in files) {
      const formData = new FormData();
      formData.append("file", files[key]);

      const promise = apiClient
        .post("/api/v1/common/file/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          file_keys.push(res.data.key);
        });

      promises.push(promise);
    }

    await Promise.all(promises);

    const completionRequest = {
      links,
      file_keys,
    };

    createOrUpdateCampaign("COMPLETED", completionRequest);
  };

  const createOrUpdateCampaign = useCallback(
    (action, message = "no message", screeningResponse = []) => {
      const base_url = type === "insta" ? `/api/v1/ig` : `/api/v1/tk`;

      apiClient
        .post(`${base_url}/campaigns/${campaign.cid}`, {
          action: action,
          message: message,
          screeningResponse: screeningResponse,
        })
        .then(({ data }) => {
          dispatch(addToast(TOASTS.success("Completion Request submitted.")));

          onRefetch();
          onClose();
        })
        .catch((err) => {
          dispatch(
            addToast(TOASTS.error("Completion Request submition failed."))
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [campaign.cid, dispatch, type]
  );

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
    content: {
      zIndex: 100,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Campaign Completion"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="w-[70vw] sm:w-[50vw]  max-h-[70vh]">
        <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t mb-8">
          <h3 className="text-3xl font-semibold">Fill Completion details</h3>
          <button
            type="button"
            onClick={handleClose}
            className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-12 h-12 ml-auto inline-flex justify-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
          >
            <IoClose className="w-8 h-8" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div>
          <ol>
            {links.map((link, index) => (
              <li key={index} className="w-full flex">
                <div className="grow">
                  {index + 1}. {link}
                </div>

                <div>
                  <button
                    onClick={() =>
                      setLinks(links.filter((_, i) => i !== index))
                    }
                    className="p-1 ml-auto border-0 float-right text-4xl leading-none font-semibold outline-none focus:outline-none text-purple-700 focus:text-gray-700"
                  >
                    <span>×</span>
                  </button>
                </div>
              </li>
            ))}
          </ol>
        </div>

        <div className="w-full flex items-center justify-center gap-4">
          <InputField
            rootClass="grow"
            type="text"
            name="question"
            onChange={(e) => setLink(e.target.value)}
            value={link}
            placeholder="Enter Link"
            onKeyDown={(e) => {
              if (e.key === "Enter") addLink();
            }}
          />

          <Button
            color="purple"
            size="lg"
            outline={true}
            onClick={addLink}
            onMouseOver={() => setIsQuestionBtnOnFocus(true)}
            onMouseOut={() => setIsQuestionBtnOnFocus(false)}
          >
            <FaPlus
              color={isQuestionBtnOnFoucs ? "white" : "#5521b5"}
              className="mr-2 w-6 h-6"
            />
            Add Link
          </Button>
        </div>

        {files.length > 0 && (
          <div className="flex flex-wrap gap-8 mt-8">
            {files.map((file, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(file)}
                  key={index}
                  alt="screenshot"
                  className="h-[15vh] hover:h-[16vh]"
                />
                <button
                  type="button"
                  onClick={() => {
                    setFiles(files.filter((_, i) => i !== index));
                  }}
                  className="absolute -top-2 -right-2 text-red-500 bg-white rounded-full p-2"
                  data-modal-hide="popup-modal"
                >
                  <IoClose className="w-8 h-8" />
                  <span className="sr-only">Remove image</span>
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col items-center mt-8">
          <div className="p-6">
            <>
              <div className="flex w-full">
                <label
                  htmlFor="dropzone-file"
                  className={`block mb-2 text-sm font-medium text-gray-900 dark:text-white ${
                    false ? "border-red-500" : "border-gray-300"
                  }`}
                >
                  <input
                    className={`hidden`}
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    onChange={handleFileSelect}
                  />
                  <label
                    htmlFor="file_input"
                    className={`block p-4 w-full text-xl text-white border border-gray-300 rounded-lg cursor-pointer bg-black dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`}
                  >
                    Add Screenshot
                  </label>
                </label>
              </div>
            </>
          </div>
        </div>

        <div>
          <Button
            color="purple"
            className="ml-auto"
            size="lg"
            outline={true}
            onClick={submitCompletion}
            disabled={isLoading}
          >
            {isLoading && <FaSpinner className="w-5 h-5 mx-2 animate-spin" />}
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CampaignCompletionModal;
