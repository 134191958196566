import useAppSetting from "@hooks/useAppSetting";
import { getFlex } from "@utils/tailwind.constants";
import profileIMG from "@assets/avatar.svg";
import verifiedIMG from "@assets/verified.svg";
import { Typography } from "ui-neumorphism";

export default function MobileStaticUserInfo({ email, name, usrImg }) {
  const [dark, isMobile] = useAppSetting();
  return (
    <div className={`${getFlex(true)} gap-3`}>
      {/*Avatar*/}
      <img
        className="h-32 w-32 self-center rounded-full"
        src={usrImg || profileIMG}
        alt="User Profile"
      />

      <div id="user-details" className="flex flex-col gap-1">
        <div className="flex gap-1 justify-center flex-wrap">
          <Typography dark={dark} type={isMobile ? "h5" : "h4"}>
            {name}
          </Typography>
        </div>
        {/* Email */}
        <div className="flex gap-1 justify-center items-center" id="email">
          <Typography dark={dark} type={isMobile ? "body-1" : "h5"}>
            {email}
          </Typography>
          <img
            className="w-7 h-7 object-contain"
            src={verifiedIMG}
            alt="verified"
          />
        </div>
      </div>
    </div>
  );
}
